import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Tag
} from 'antd';
import { debounce, filter, find, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import { exportOptions, ROUTES, SKIP_RECORD } from '../../../common/constants';
import { handleExportCommon } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_PROPERTY, UPDATE_PROPERTY } from '../graphql/Mutations';
import {
  GET_PROPERTIES,
  PROPERTY_FILTER,
  PROPERTY_TYPES_FILTER
} from '../graphql/Queries';

let scrollDebounce = null;

const PropertyTable = () => {
  const {
    state: { pageSize, filterData },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialPropertyFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [propertyFilter, setPropertyFilter] = useState(initialPropertyFilter);
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [
    updatePropertyMutate,
    { loading: updatePropertyLoading }
  ] = useMutation(UPDATE_PROPERTY, {
    onError() {}
  });

  const [deleteProperty, { loading: deletePropertyLoading }] = useMutation(
    DELETE_PROPERTY,
    {
      onError() {}
    }
  );

  const [fetchPropertyData, { loading, data }] = useLazyQuery(GET_PROPERTIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.properties?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [propertyFilters] = useLazyQuery(PROPERTY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.propertyFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.propertyFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.propertyFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [propertyTypes] = useLazyQuery(PROPERTY_TYPES_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.propertyTypes?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key })
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.propertyTypes?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key })
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.propertyTypes?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    fetchPropertyData({
      variables: {
        filter: propertyFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchPropertyData({
      variables: {
        filter: { ...propertyFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setPropertyFilter({
      ...propertyFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'propertyType':
          propertyTypes({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: ['label', 'key']
              }
            }
          });
          break;
        case 'builtYear':
          // no api call for range filter;
          break;
        default:
          propertyFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'propertyType':
            propertyTypes({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: ['label', 'key']
                }
              }
            });
            break;
          default:
            propertyFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 38,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    if (dataIndex === 'builtYear') {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    fetchPropertyData({
      variables: {
        filter: {
          ...propertyFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setPropertyFilter({
      ...propertyFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditProperty = (record) => {
    if (record?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.PROPERTIES}/edit/${record?.id}`);
    } else {
      history?.push(`${ROUTES?.PROPERTIES}/add`);
    }
  };

  const handleViewProperty = (record) => {
    dispatch({ type: 'SET_FILTER_DATA', data: filters });
    history?.push(`${ROUTES?.PROPERTIES}/view/${record?.id}`);
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setPropertyFilter(initialPropertyFilter);
    setSortedInfo({});
    fetchPropertyData({
      variables: { filter: initialPropertyFilter }
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setPropertyFilter({
        ...propertyFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchPropertyData({
        variables: {
          filter: {
            ...propertyFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setPropertyFilter({
        ...propertyFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchPropertyData({
        variables: {
          filter: {
            ...propertyFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'propertyType':
        propertyTypes({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: ['label', 'key']
            }
          }
        });
        break;
      default:
        propertyFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: moment(values?.[0])?.format('YYYY'),
      to: moment(values?.[1])?.format('YYYY')
    };
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'builtYear') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              picker="year"
              format="YYYY"
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                filtersCopyState?.[dataIndex]
                  ? [
                      moment(filtersCopyState?.[dataIndex]?.from, 'YYYY'),
                      moment(filtersCopyState?.[dataIndex]?.to, 'YYYY')
                    ]
                  : []
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => {
                  return (
                    <Tag
                      key={item?.toString()}
                      closable
                      onClose={() => handleDeleteFilter(item, dataIndex)}
                      className="filter-tag"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span
                          title={
                            find(filterList, ['key', item])?.label ||
                            item?.toString()
                          }
                        >
                          {find(filterList, ['key', item])?.label ||
                            item?.toString()}
                        </span>
                      )}
                    </Tag>
                  );
                })}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => {
                  return (
                    <div
                      className="filter-checkbox-section"
                      key={item?.key || item}
                    >
                      <Checkbox
                        value={item?.key || item}
                        checked={filtersCopyState?.[dataIndex]?.includes(
                          item?.key || item
                        )}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="common-checkbox"
                      >
                        {dataIndex === 'isActive' ? (
                          <span title={item === true ? 'Active' : 'Inactive'}>
                            {item === true ? 'Active' : 'Inactive'}
                          </span>
                        ) : (
                          <span title={item?.label || item?.toString()}>
                            {item?.label || item?.toString()}
                          </span>
                        )}
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleDeleteProperty = async (editPropertyData) => {
    const response = await deleteProperty({
      variables: { where: { id: editPropertyData?.id } }
    });
    if (response?.data?.deleteProperty) {
      fetchPropertyData({
        variables: {
          filter: propertyFilter,
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handlePropertyStatus = async (editPropertyData) => {
    const editData = { isActive: !editPropertyData?.isActive };
    const response = await updatePropertyMutate({
      variables: { data: { ...editData }, where: { id: editPropertyData?.id } }
    });
    if (response?.data?.updateProperty) {
      fetchPropertyData({
        variables: {
          filter: propertyFilter,
          ...(filters && { where: filters })
        }
      });
    }
  };

  const renderActionButtons = (editPropertyData) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="property-table-edit-btn"
          className="b-0"
          onClick={() => handleAddEditProperty(editPropertyData)}
        >
          Edit
        </Button>
        <Button
          id="property-table-edit-btn"
          className="b-0"
          onClick={() => handleViewProperty(editPropertyData)}
        >
          View
        </Button>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleDeleteProperty(editPropertyData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="property-table-status-btn" className="b-0">
            Remove
          </Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to ${
            editPropertyData?.isActive ? 'Mark Inactive' : 'Mark Active'
          }?`}
          onConfirm={() => handlePropertyStatus(editPropertyData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="property-table-status-btn" className="b-0">
            {editPropertyData?.isActive ? 'Mark Inactive' : 'Mark Active'}
          </Button>
        </Popconfirm>
      </div>
    );
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: 300
    },
    {
      title: 'TYPE',
      dataIndex: 'propertyType',
      key: 'propertyType',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'propertyType' && sortedInfo?.order,
      width: 200,
      ...filterPopup('propertyType')
    },
    {
      title: 'ADDRESS',
      ellipsis: true,
      dataIndex: 'location',
      className: 'max-width-column',
      key: 'location',
      render: (location) => {
        return `${location?.addressLine1} ${location?.city}, ${location?.state} ${location?.zipCode}`;
      }
    },
    {
      title: 'BUILT YEAR',
      dataIndex: 'builtYear',
      key: 'builtYear',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'builtYear' && sortedInfo?.order,
      width: 150,
      ...filterPopup('builtYear')
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    {
      dataIndex: 'id',
      align: 'right',
      fixed: 'right',
      width: 10,
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = (value) => {
    setPropertyFilter({
      ...propertyFilter,
      skip: value ? 0 : propertyFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchPropertyData({
      variables: {
        filter: {
          ...propertyFilter,
          skip: value
            ? 0
            : propertyFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'PROPERTY'
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="properties"
        folder="PROPERTY"
      />
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<PlusOutlined />}
          size="small"
          id="property-table-add-btn"
          type="primary"
          onClick={handleAddEditProperty}
        >
          Add Property
        </Button>
      </Portal>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            className="list-search-box"
            id="search-container-id"
            placeholder="Property or other detail.."
            name="Properties"
            getData={onSearchChange}
          />
        </div>
        <div className="header-buttons">
          <Button
            size="small"
            className="common-button import-button"
            icon={<img src={ImportIcon} alt="import-icon" width={11} />}
            id="property-table-import-btn"
            type="primary"
            onClick={() => handleShowImportModal(true)}
          >
            Import
          </Button>
          <Dropdown
            overlayClassName="export-btn-dropdown"
            menu={{ items: exportOptions, onClick: handleExport }}
            placement="bottom"
          >
            <Button
              className="common-button export-button"
              size="small"
              icon={<img src={ExportIcon} alt="export-icon" width={11} />}
              id="property-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={
              loading || updatePropertyLoading || deletePropertyLoading
            }
            columns={[...columns?.filter((item) => item !== false)]}
            data={data?.properties?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowSelection={rowSelection}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};

export default PropertyTable;
