import { Autocomplete } from '@react-google-maps/api';
import { findIndex } from 'lodash';
import { Component, React } from 'react';
import InputComponent from '../../../components/InputComponent';

export default class GoogleAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ''
    };

    this.autocomplete = null;

    this.onLoad = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.initialValue !== state?.inputValue) {
      return {
        inputValue: props?.initialValue
      };
    }
    return null;
  }

  handleChangeInput(e) {
    const { setPlacesValue, setMarkerObj } = this.props;
    this.setState({ inputValue: e?.target?.value });
    if (setPlacesValue && setMarkerObj) {
      setPlacesValue(e?.target?.value);
      setMarkerObj(null);
    }
  }

  onPlaceChanged() {
    const { setMarkerObj, setPlacesValue, setZipValue } = this.props;
    if (this.autocomplete !== null) {
      if (
        this.autocomplete?.getPlace()?.formatted_address &&
        this.autocomplete?.getPlace()?.geometry
      ) {
        this.setState({
          inputValue: this.autocomplete?.getPlace()?.formatted_address
        });
        if (setMarkerObj) {
          setMarkerObj(
            this.autocomplete?.getPlace()?.geometry?.location?.toJSON()
          );
        }
        if (setPlacesValue) {
          setPlacesValue(this.autocomplete?.getPlace()?.formatted_address);
        }
        if (setZipValue) {
          const zipCodeIndex = findIndex(
            this.autocomplete?.getPlace()?.address_components,
            (item) => item?.types?.[0] === 'postal_code'
          );
          if (zipCodeIndex > -1) {
            if (setZipValue) {
              const zipValue = this.autocomplete?.getPlace()
                ?.address_components?.[zipCodeIndex]?.long_name;
              setZipValue(zipValue);
            }
          } else if (setZipValue) {
            setZipValue('');
          }
        }
      }
    }
  }

  onLoad(autocomplete) {
    this.autocomplete = autocomplete;
  }

  render() {
    const {
      onLoad,
      onPlaceChanged,
      handleChangeInput,
      state: { inputValue }
    } = this;
    return (
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        restrictions={{ country: 'us' }}
      >
        <InputComponent
          value={inputValue}
          onChange={handleChangeInput}
          className="field-width"
          placeholder="Address"
        />
      </Autocomplete>
    );
  }
}
