import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import PriceComponent from '../../../../components/PriceComponent';

const RenderNumber = ({ numberData, form, editData = null }) => {
  const { config, label = '', tooltip = '', key = '' } = numberData;
  const {
    NUMBER: {
      default: defaultNumber,
      rules: {
        hidden,
        hideLabel,
        readOnly: fieldReadOnly,
        required: fieldRequired
      } = {},
      range: { maxNumber, minNumber } = {},
      decimal
    } = {}
  } = config || {};

  useEffect(() => {
    if (editData?.[key] || editData?.[key] === null) {
      form?.setFieldsValue({
        dynamicFields: {
          [key]: editData?.[key]
        }
      });
    } else {
      form?.setFieldsValue({
        dynamicFields: {
          [key]: defaultNumber
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, defaultNumber]);
  return (
    <Form.Item
      label={hideLabel ? ' ' : label}
      name={['dynamicFields', key]}
      rules={[
        { required: fieldRequired, message: 'Please Enter Number' },
        () => ({
          validator(_, value) {
            if (value) {
              if (minNumber && maxNumber) {
                if (!(value >= minNumber && value <= maxNumber)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise?.reject(
                    `should be between ${minNumber} to ${maxNumber}`
                  );
                }
              } else if (minNumber && !maxNumber) {
                if (!(value >= minNumber)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise?.reject(`should be greater than ${minNumber}`);
                }
              } else if (maxNumber && !minNumber) {
                if (!(value <= maxNumber)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise?.reject(`should be less than ${maxNumber}`);
                }
              }
            }
            return Promise?.resolve();
          }
        })
      ]}
      hidden={hidden}
      tooltip={
        tooltip && {
          title: tooltip,
          icon: <InfoCircleOutlined />
        }
      }
      className={`${tooltip && 'label-with-tooltip'}${hidden ? 'd-none' : ''}`}
    >
      <PriceComponent
        decimalValue={decimal}
        maxValue={maxNumber}
        minValue={minNumber}
        isPrice={false}
        prefix=""
        value={defaultNumber}
        disabled={fieldReadOnly}
      />
    </Form.Item>
  );
};

export default RenderNumber;
