import {
  DownOutlined,
  LeftOutlined,
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Badge, Button, Statistic } from 'antd';
import { lowerCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import timerIcon from '../../../../../assets/timer.svg';
import { SAME_DAY_TIME } from '../../../../../common/constants';
import { formatPhoneNumber } from '../../../../../common/utils';
import LocationIconComponent from '../globalComponents/LocationIconComponent';

const { Countdown } = Statistic;

const FooterSection = ({
  pageConfiguration = null,
  previousPageObj = null,
  currentPageSequence = null,
  newPageSequenceCopy = null,
  tenantContactDetails = null
}) => {
  const {
    primaryColor = '#1890ff',
    phoneNo = tenantContactDetails?.phoneNo,
    email = tenantContactDetails?.email,
    helpPage = '',
    liveChat = ''
  } =
    currentPageSequence ||
    newPageSequenceCopy ||
    pageConfiguration ||
    tenantContactDetails ||
    {};

  const {
    sameDayInstallationTimer = false,
    showCtaButton = false,
    ctaButtonLabel = '',
    showMyCart = false,
    showBack = false,
    showFootNote = false,
    footNote = '',
    showContactMe = false,
    contactMe = '',
    showContactInfo = false,
    showLocation = false
  } = pageConfiguration || {};

  const checkForSubmitBtn =
    pageConfiguration?.widgetConfiguration?.hideContinueButton ||
    pageConfiguration?.widgetConfiguration?.config?.hideContinueButton;

  const showSubmitButton =
    showCtaButton && ctaButtonLabel?.length > 0 && !checkForSubmitBtn;

  const [showTimer, setShowTimer] = useState(false);
  const [time, setTime] = useState(null);

  useEffect(() => {
    const todayDay = lowerCase(moment()?.format('dddd'));
    const pageSequenceCopy = !currentPageSequence
      ? { ...newPageSequenceCopy }
      : { ...currentPageSequence };
    if (pageSequenceCopy?.cutOffTime?.[todayDay]?.checked) {
      if (
        moment(pageSequenceCopy?.cutOffTime?.[todayDay]?.time, [
          'h:mm A'
        ])?.isAfter(moment())
      ) {
        setShowTimer(true);
        setTime(pageSequenceCopy?.cutOffTime?.[todayDay]?.time);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSequence, newPageSequenceCopy]);

  return (
    <div className="footer-section">
      {sameDayInstallationTimer && showTimer && (
        <div className="countdown-text">
          <img src={timerIcon} alt="timer-icon" className="mr-4" />
          <Countdown
            value={moment(time, ['h:mm A'])?.unix() * 1000}
            onFinish={() => setShowTimer(false)}
          />
          <span className="ml-4">{SAME_DAY_TIME}</span>
        </div>
      )}
      {showSubmitButton && (
        <div className="mt-16">
          <Button className="common-button-cpq" id="submit-btn" type="primary">
            {ctaButtonLabel}
          </Button>
        </div>
      )}
      {showMyCart && (
        <Button type="text" icon={<DownOutlined />} className="my-cart-btn">
          <Badge
            showZero
            count={1}
            color={primaryColor}
            className="count-badge"
          />
          My Cart
        </Button>
      )}
      {showBack && (
        <div className="mt-16 d-flex justify-center">
          <Button icon={<LeftOutlined />} className="back-btn">
            {previousPageObj?.title || 'Back'}
          </Button>
        </div>
      )}
      {showFootNote && footNote?.length > 0 && (
        <div className="footnote-text">
          <span>{footNote}</span>
        </div>
      )}
      <div className="contact-section">
        {showContactMe && contactMe?.length > 0 && (
          <Button
            type="link"
            icon={<RightOutlined />}
            className="contact-me-btn"
          >
            {contactMe}
          </Button>
        )}
        {showContactInfo && (
          <div className="contact-info">
            {helpPage && (
              <span className="details-text">Do you need help?</span>
            )}
            {liveChat && (
              <span className="details-text underline-text">
                <SendOutlined />
                Live Chat
              </span>
            )}
            {phoneNo && (
              <span className="details-text underline-text">
                <PhoneOutlined rotate={90} />
                {formatPhoneNumber(phoneNo)}
              </span>
            )}
            {email && (
              <span className="details-text underline-text">
                <MailOutlined />
                {email}
              </span>
            )}
          </div>
        )}
      </div>
      {showLocation && (
        <div className="location-footer">
          <LocationIconComponent color={primaryColor} />
          <span className="location-info">Location</span>
        </div>
      )}
    </div>
  );
};

export default FooterSection;
