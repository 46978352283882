import { DownloadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Empty, Form, message, Row } from 'antd';
import { debounce, map } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { ALLOW_IMAGE_TYPES, REGEX, ROUTES } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { FETCH_STATE_CITY } from '../../Signup/graphql/Query';

let stateScrollDebounce;
let cityScrollDebounceJob;

const stateCityFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC'
};

const {
  required,
  requiredWhiteSpaceAllowed,
  zipCode,
  url: urlCheck,
  address
} = formValidatorRules;

const { Option } = SelectComponent;

const ManufacturerForm = (props) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const { manufacturerData, handleSubmit, isSubmit, isEdit = false } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [callAsync, setCallAsync] = useState(false);

  const [form] = Form?.useForm();

  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
        setCityLoading(false);
      } else {
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setStateLoading(false);
        setCallAsync(false);
      }
    },
    onError() {
      setStateLoading(false);
      setCityLoading(false);
    }
  });

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: manufacturerData?.state
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url }
    } = info;
    if (url) {
      setFileList([]);
      form?.setFieldsValue({
        logo: null
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          logo: null
        });
      } else {
        form?.setFieldsValue({
          logo: info?.file
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      form?.setFieldsValue({
        logo: null
      });
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  useEffect(() => {
    if (!manufacturerData?.state) {
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    if (manufacturerData?.country) {
      setDisableState(false);
    }
    if (manufacturerData?.state) {
      setDisableCity(false);
      setCitySearchFlag(true);
      setCallAsync(true);
      setSelectedState(manufacturerData?.state);
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    if (manufacturerData?.logo && !fileList?.length) {
      setFileList([
        {
          url: manufacturerData?.logo?.url,
          title: manufacturerData?.logo?.title
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityBlur = () => {
    setSearchValue('');
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState
        }
      }
    });
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom) {
        setStateLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: states?.length,
              search: searchValue,
              type: 'STATE'
            }
          }
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (cityScrollDebounceJob) {
      cityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    cityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom) {
        setCityLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: cities?.length,
              type: 'CITY',
              search: searchValue,
              state: selectedState
            }
          }
        });
      }
    }, 500);

    cityScrollDebounceJob();
  };

  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setStateLoading(true);
      setCities([]);
      setDisableCity(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value
          }
        }
      });
    } else {
      if (!state?.length) {
        setStateLoading(true);
        setDisableCity(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
      if (state?.length > 0) {
        setDisableCity(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        city: null
      });
      setSelectedState(value);
      setDisableCity(false);
      setCitySearchFlag(true);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value
          }
        }
      });
      setStateLoading(false);
    } else {
      setCitySearchFlag(true);
      setStateLoading(false);
      setDisableCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE'
          }
        }
      });
    }
  };

  const handleSelectCountry = (value) => {
    if (value) {
      setDisableState(false);
    } else {
      setDisableState(true);
    }
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCityLoading(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state')
          }
        }
      });
    } else {
      setCityLoading(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state')
          }
        }
      });
    }
  };

  const handleCountryClear = () => {
    form?.setFieldsValue({
      country: null,
      state: null,
      city: null
    });
    setDisableState(true);
    setDisableCity(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCityHandler = useCallback(debounce(handleCityChange, 500), []);

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null
    });
    setFetchCity(false);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter
      }
    });
    setSelectedState('');
    setCities([]);
    setDisableCity(true);
  };

  return (
    <div className="content-section">
      <AccessControl
        allowedPermissions={
          isEdit ? ['FET_MANUFACTURER_UPDATE'] : ['FET_MANUFACTURER_CREATE']
        }
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="manufacturer-table-discard-btn"
            onClick={() => history?.push(ROUTES?.MANUFACTURERS)}
          >
            {isEdit ? 'Discard Changes' : 'Cancel'}
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="manufacturer-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={manufacturerData}
        layout="vertical"
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_MANUFACTURER_UPDATE'] : ['FET_MANUFACTURER_CREATE']
            )
          }
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider" />
          <Row className="required-row" gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Title' }]}
                name="name"
                label="Title"
              >
                <InputComponent allowClear placeholder="Enter Title" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Contact Number"
                name="contactNumber"
                rules={[
                  { ...required, message: 'Please Enter Contact Number' },
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value?.split(' ')?.join('');
                        const numberPattern = REGEX?.PHONE;
                        if (!numberPattern?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <ReactInputMask
                  mask="(999) 999-9999"
                  placeholder="(___) ___-____"
                  allowClear
                >
                  {(inputProps) => <InputComponent {...inputProps} />}
                </ReactInputMask>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status'
                  }
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <span className="form-divider-text optional-divider">OPTIONAL</span>
            <Divider className="form-divider optional-divider" />
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="logo" label="Logo">
                <DraggerUploadComponent
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: checkPermissions(permissions, [
                      'FET_MANUFACTURER_UPDATE'
                    ])
                  }}
                  fileList={fileList}
                  onChange={onChangeUpload}
                >
                  <p className="icon-header">
                    <DownloadOutlined />
                  </p>
                  <p className="upload-title">
                    Drag the manufacturer's logo here or browse your files
                  </p>
                  <p className="upload-description">
                    200x200 px minimum | JPG, PNG, SVG
                  </p>
                </DraggerUploadComponent>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="certification" label="Certification">
                <EditorComponent placeholder="Enter Certification" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="addressLine1" label="Address" rules={[address]}>
                <InputComponent allowClear placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="country" label="Country">
                <SelectComponent
                  placeholder="Select Country"
                  onSelect={handleSelectCountry}
                  onClear={handleCountryClear}
                  allowClear
                >
                  <Option key="USA" value="USA">
                    USA
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="state" label="State">
                <SelectComponent
                  placeholder="Select State"
                  disabled={disableState}
                  allowClear
                  notFoundContent={
                    stateLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSearch={debouncedStateHandler}
                  onClear={handleStateClear}
                  onSelect={handleStateSelect}
                  onPopupScroll={onStateScroll}
                >
                  {map(states, (state) => (
                    <Option key={state?.id} value={state?.name}>
                      {state?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="city" label="City">
                <SelectComponent
                  placeholder="Select City"
                  disabled={disableCity}
                  notFoundContent={
                    cityLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSearch={debouncedCityHandler}
                  onPopupScroll={onCityScroll}
                  onClear={handleCityClear}
                  onBlur={handleCityBlur}
                >
                  {cities?.map((city) => (
                    <Option key={city?.id} value={city?.name}>
                      {city?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="zipCode" rules={[zipCode]} label="Postal Code">
                <NumberComponent placeholder="Enter Postal code" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Fax"
                name="fax"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value?.split(' ')?.join('');
                        const numberPattern = REGEX?.PHONE;
                        if (!numberPattern?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <ReactInputMask
                  mask="(999) 999-9999"
                  placeholder="(___) ___-____"
                  allowClear
                >
                  {(inputProps) => <InputComponent {...inputProps} />}
                </ReactInputMask>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="url" label="URL" rules={[urlCheck]}>
                <InputComponent allowClear placeholder="Enter URL" />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default ManufacturerForm;
