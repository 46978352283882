import React from 'react';
import NumberFormat from 'react-number-format';
import './styles/InputComponent.less';

const PriceComponent = (props) => {
  const {
    value,
    prefix = '$',
    className = '',
    onChange,
    isPrice = true,
    showPrefix = true,
    decimalScale = true,
    decimalValue = 2,
    maxValue,
    minValue,
    disabled = false,
    ...rest
  } = props;

  const handleChange = (changedValue, sourceInfo) => {
    if (onChange) {
      onChange(changedValue?.floatValue, sourceInfo);
    }
  };

  return (
    <NumberFormat
      onValueChange={handleChange}
      className={`ant-input-affix-wrapper input-component price-component ${className}`}
      thousandSeparator={isPrice}
      prefix={showPrefix ? prefix : ''}
      placeholder={`${showPrefix && prefix}0.00`}
      decimalScale={decimalScale && decimalValue}
      fixedDecimalScale
      value={value}
      disabled={disabled}
      {...rest}
    />
  );
};

export default PriceComponent;
