import { MenuOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  message,
  Popconfirm,
  Popover,
  Tag
} from 'antd';
import {
  debounce,
  filter,
  find,
  forEach,
  isEmpty,
  map,
  pickBy,
  set,
  startCase
} from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ShowMoreIconComponent from '../../../app/components/iconComponents/ShowMoreIconComponent';
import { AppContext } from '../../../AppContext';
import FilterIcon from '../../../assets/filter.svg';
import { ROUTES, SKIP_RECORD } from '../../../common/constants';
import { checkPermissions, formatUnits } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import {
  CHANGE_PRIORITY,
  DELETE_PRICING_MODIFIER,
  UPDATE_PRICING_MODIFIER
} from '../graphql/Mutations';
import {
  GET_PRICING_MODIFIERS,
  PRICING_MODIFIER_FILTER
} from '../graphql/Queries';

let scrollDebounce = null;
let modifierStatus;

const PricingModifierTable = () => {
  const {
    state: { filterData, permissions },
    dispatch
  } = useContext(AppContext);

  const initialPricingModifierFilter = {
    sortOn: 'order',
    sortBy: 'ASC'
  };

  const PRICING_STATUS = {
    PM_ACTIVE: 'Active',
    PM_INACTIVE: 'Inactive',
    PM_SCHEDULE: 'Scheduled',
    PM_EXPIRED: 'Expired'
  };

  const [sortedInfo, setSortedInfo] = useState({});
  const [pricingModifierFilter, setPricingModifierFilter] = useState(
    initialPricingModifierFilter
  );
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [pricingData, setPricingData] = useState([]);
  const [dragLoading, setDragLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [
    updatePricingModifierMutate,
    { loading: updatePricingModifierLoading }
  ] = useMutation(UPDATE_PRICING_MODIFIER, {
    onError() {}
  });

  const [
    deletePricingModifier,
    { loading: deletePricingModifierLoading }
  ] = useMutation(DELETE_PRICING_MODIFIER, {
    onError() {}
  });

  const [fetchPricingModifierData, { loading }] = useLazyQuery(
    GET_PRICING_MODIFIERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setPricingData([...res?.pricingModifiers?.data]);
      },
      onError() {}
    }
  );

  const [pricingModifierFilters] = useLazyQuery(PRICING_MODIFIER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.pricingModifierFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.pricingModifierFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.pricingModifierFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [changePriority] = useMutation(CHANGE_PRIORITY, {
    onCompleted() {},
    onError() {}
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_PRICING_MODIFIER_LIST'
    ]);
    if (permitted) {
      fetchPricingModifierData({
        variables: {
          filter: pricingModifierFilter,
          ...(filterData && { where: filterData })
        }
      });
      setFilters(filterData);
      dispatch({ type: 'SET_FILTER_DATA', data: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchPricingModifierData({
      variables: {
        filter: pricingModifierFilter,
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        default:
          pricingModifierFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            pricingModifierFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);

    fetchPricingModifierData({
      variables: {
        filter: {
          ...pricingModifierFilter,
          sortOn: 'order',
          sortBy: 'ASC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditPricingModifier = (record) => {
    if (record?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.PRICING_MODIFIERS}/edit/${record?.id}`);
    } else {
      history?.push(`${ROUTES?.PRICING_MODIFIERS}/add`);
    }
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.column) {
      setPricingModifierFilter({
        ...pricingModifierFilter,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchPricingModifierData({
        variables: {
          filter: {
            ...pricingModifierFilter,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setPricingModifierFilter({
        ...pricingModifierFilter,
        sortOn: 'order',
        sortBy: 'ASC'
      });
      fetchPricingModifierData({
        variables: {
          filter: {
            ...pricingModifierFilter,
            sortOn: 'order',
            sortBy: 'ASC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      default:
        pricingModifierFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'modifierOn' && dataIndex !== 'status' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'status' ? (
                      <span title={PRICING_STATUS[item]}>
                        {PRICING_STATUS[item]}
                      </span>
                    ) : (
                      <span>{item?.toString()}</span>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'status' ? (
                        <span title={PRICING_STATUS[item]}>
                          <span title={PRICING_STATUS[item]}>
                            {PRICING_STATUS[item]}
                          </span>
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownVisibleChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleDeletePricingModifier = async (editPricingModifierData) => {
    const response = await deletePricingModifier({
      variables: { where: { id: editPricingModifierData?.id } }
    });
    if (response?.data?.deletePricingModifier) {
      setPricingModifierFilter({
        ...pricingModifierFilter,
        skip: 0,
        limit: pricingData?.length
      });
      fetchPricingModifierData({
        variables: {
          filter: {
            ...pricingModifierFilter
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handlePricingModifierStatus = async (editPricingModifierData) => {
    switch (editPricingModifierData?.status) {
      case 'PM_ACTIVE':
        modifierStatus = 'PM_INACTIVE';
        break;
      case 'PM_INACTIVE':
        modifierStatus = 'PM_ACTIVE';
        break;
      case 'PM_SCHEDULE':
        modifierStatus = 'PM_ACTIVE';
        break;

      case 'PM_EXPIRED':
        modifierStatus = 'PM_ACTIVE';
        break;

      default:
        break;
    }

    const conditions = {};
    if (editPricingModifierData?.brands?.length > 0) {
      set(
        conditions,
        'brands',
        editPricingModifierData?.brands?.map((item) => item?.id)
      );
    }
    if (editPricingModifierData?.conditions?.regions?.length > 0) {
      set(
        conditions,
        'regions',
        editPricingModifierData?.conditions?.regions?.map((item) => {
          return {
            id: item?.id || null,
            all: item?.all || false,
            zipCodes: item?.all ? [] : item?.zipCodes
          };
        })
      );
    }

    // in case of LOB we are fetch that object from condition object (e.g  editPricingModifierData?.conditions?.lineOfBusiness) instead of
    // fetch directly from Line of business object (e.g  editPricingModifierData?.lineOfBusiness) because of the issue related to ID

    if (editPricingModifierData?.conditions?.industry?.length > 0) {
      set(
        conditions,
        'industry',
        editPricingModifierData?.conditions?.industry?.map((item) => {
          return {
            id: item?.id,
            lineOfBusiness: item?.lineOfBusiness,
            subArea: item?.subArea
          };
        })
      );
    }
    if (editPricingModifierData?.manufacturers?.length > 0) {
      set(
        conditions,
        'manufacturers',
        editPricingModifierData?.manufacturers?.map((item) => item?.id)
      );
    }

    const editData = {
      status: modifierStatus,
      conditions,
      modifierOn: editPricingModifierData?.modifierOn,
      pricingOperation: editPricingModifierData?.pricingOperation,
      pricingValue: editPricingModifierData?.pricingValue,
      addItemAs: editPricingModifierData?.addItemAs,
      pricingValueType: editPricingModifierData?.pricingValueType,
      billingAs: editPricingModifierData?.billingAs,
      lineItemName: editPricingModifierData?.lineItemName
    };

    const response = await updatePricingModifierMutate({
      variables: {
        data: { ...editData },
        where: { id: editPricingModifierData?.id }
      }
    });
    if (response?.data?.updatePricingModifier) {
      setPricingModifierFilter({
        ...pricingModifierFilter
      });
      fetchPricingModifierData({
        variables: {
          filter: {
            ...pricingModifierFilter
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const renderActionButtons = (editPricingModifierData) => {
    return (
      <div className="d-flex flex-vertical">
        <AccessControl
          allowedPermissions={[
            'FET_PRICING_MODIFIER_UPDATE',
            'FET_PRICING_MODIFIER_VIEW'
          ]}
        >
          <Button
            id="pricingModifier-table-edit-btn"
            className="b-0"
            onClick={() =>
              handleAddEditPricingModifier(editPricingModifierData)
            }
          >
            Edit
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_PRICING_MODIFIER_DELETE']}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() =>
              handleDeletePricingModifier(editPricingModifierData)
            }
            okText="Yes"
            cancelText="No"
          >
            <Button id="pricingModifier-table-status-btn" className="b-0">
              Remove
            </Button>
          </Popconfirm>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_PRICING_MODIFIER_UPDATE']}>
          <Popconfirm
            title={`Are you sure to ${
              editPricingModifierData?.status === 'PM_ACTIVE'
                ? 'Mark Inactive'
                : 'Mark Active'
            }?`}
            onConfirm={() =>
              handlePricingModifierStatus(editPricingModifierData)
            }
            okText="Yes"
            cancelText="No"
          >
            <Button id="pricingModifier-table-status-btn" className="b-0">
              {editPricingModifierData?.status === 'PM_ACTIVE'
                ? 'Mark Inactive'
                : 'Mark Active'}
            </Button>
          </Popconfirm>
        </AccessControl>
      </div>
    );
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined className="drag-row" />
  ));

  const conditionRenderer = (record) => {
    let showMoreButton = false;
    const conditionObj = {
      brands: record?.brands || [],
      industry: record?.industry || [],
      manufacturers: record?.manufacturers || [],
      regions: record?.regions || []
    };

    const finalConditionObj = pickBy(conditionObj, (value) => {
      return !isEmpty(value);
    });

    const total = Object?.keys(finalConditionObj)?.length;
    const firstCondition =
      finalConditionObj[Object?.keys(finalConditionObj)?.[0]] || [];

    const content = (
      <div key={record?.id} className="pricing-condition">
        {map(finalConditionObj, (value, key) => {
          return (
            <div className="condition" key={`${key}${record?.id}`}>
              <span className="condition-title">
                {key === 'brands' ? 'Retailers' : startCase(key)} :
              </span>
              {map(value, (val, index) => {
                return (
                  <div key={`${index}${record?.id}${val?.id}`}>
                    {key === 'industry' ? (
                      <>
                        <span
                          className="condition-data"
                          key={`${index}${record?.id}${val?.id}`}
                        >
                          {val?.name || val?.label}
                          {' - '}
                          {map(val?.lineOfBusinesses, (lob, lobIndex) => {
                            return (
                              <span key={`${index}${record?.id}${lobIndex}`}>
                                {lob?.label}
                                {' - '}
                                {map(lob?.subAreas, (subArea, subAreaIndex) => {
                                  return (
                                    <span
                                      key={`${index}${record?.id}${subAreaIndex}`}
                                    >
                                      {subArea?.label}
                                    </span>
                                  );
                                })?.reduce((prev, curr) => {
                                  if (prev === '') {
                                    return curr;
                                  }
                                  return [prev, ', ', curr];
                                }, '')}
                              </span>
                            );
                          })?.reduce((prev, curr) => {
                            if (prev === '') {
                              return curr;
                            }
                            return [prev, ', ', curr];
                          }, '')}
                        </span>
                      </>
                    ) : (
                      <>
                        {key === 'regions' ? (
                          <>
                            {val?.all ? (
                              <>
                                &nbsp;
                                <Tag className="region-tag">
                                  {val?.name || '-'}
                                </Tag>
                              </>
                            ) : (
                              <>
                                &nbsp;{val?.name} {'=>'}
                                {map(val?.zipCodes, (zipCode) => {
                                  return <span key={zipCode}>{zipCode}</span>;
                                })?.reduce((prev, curr) => {
                                  if (prev === '') {
                                    return curr;
                                  }
                                  return [prev, ', ', curr];
                                }, '')}
                              </>
                            )}
                          </>
                        ) : (
                          <span className="condition-data" key={val?.id}>
                            {val?.name || val?.label}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                );
              })?.reduce((prev, curr) => {
                if (prev === '') {
                  return curr;
                }
                return [prev, ', ', curr];
              }, '')}
              <br />
            </div>
          );
        })}
      </div>
    );

    if (
      total > 1 ||
      record?.brands?.length > 2 ||
      record?.manufacturers?.length > 2 ||
      Object?.keys(finalConditionObj)?.[0] === 'industry' ||
      Object?.keys(finalConditionObj)?.[0] === 'regions'
    ) {
      showMoreButton = true;
    } else {
      showMoreButton = false;
    }
    return (
      <div key={record?.id} className="pricing-condition">
        <span key={record?.id} className="condition-title">
          {Object?.keys(finalConditionObj)?.length &&
          Object?.keys(finalConditionObj)?.[0] === 'brands'
            ? 'Retailers'
            : startCase(Object?.keys(finalConditionObj)?.[0])}{' '}
          :
        </span>
        {map(firstCondition?.slice(0, 2), (firstObj, index) => {
          return (
            <span
              key={`${index}${firstObj?.id}`}
              className="condition-max-width"
            >
              {Object?.keys(finalConditionObj)?.length &&
              Object?.keys(finalConditionObj)?.[0] === 'regions' ? (
                <Tag className="condition-region" key={firstObj?.id}>
                  {firstObj?.name}
                </Tag>
              ) : (
                <span className="condition-data" key={firstObj?.id}>
                  {firstObj?.name || firstObj?.label}
                </span>
              )}
            </span>
          );
        })?.reduce((prev, curr) => {
          if (prev === '') {
            return curr;
          }
          return [prev, ', ', curr];
        }, '')}

        {showMoreButton && (
          <Popover
            overlayClassName="pricing-popover"
            placement="rightBottom"
            content={content}
          >
            <Tag className="single-item pointer more-shadow ml-5">
              <span className="content-text">
                {total - 1 > 0 ? `${total - 1} more` : 'show more'}
                <ShowMoreIconComponent className="show-more-icon" />
              </span>
            </Tag>
          </Popover>
        )}
      </div>
    );
  };

  const showDrag = () => {
    let searchLength;
    let filterLength;
    let sorterLength;

    if (!pricingModifierFilter?.search?.length) {
      searchLength = false;
    } else {
      searchLength = true;
    }
    if (!filters) {
      filterLength = false;
    } else if (filters) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filters) {
        if (!filters[key]?.length) {
          filterLength = false;
        } else {
          filterLength = true;
        }
      }
    } else {
      filterLength = true;
    }

    if (!Object?.keys(sortedInfo)?.length || !sortedInfo?.order) {
      sorterLength = false;
    } else {
      sorterLength = true;
    }

    if (
      !filterLength &&
      !searchLength &&
      !sorterLength &&
      checkPermissions(permissions, ['FET_PRICING_MODIFIER_UPDATE'])
    ) {
      return true;
    }

    return false;
  };

  const columns = [
    showDrag() && {
      dataIndex: 'sort',
      fixed: 'left',
      columnWidth: 50,
      width: 10,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'title' && sortedInfo?.order,
      width: 200,
      className: 'max-width-column'
    },
    {
      title: 'CONDITIONS',
      dataIndex: 'conditions',
      key: 'conditions',
      sorter: true,
      ellipsis: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'conditions' && sortedInfo?.order,
      render: (conditions, record) => <>{conditionRenderer(record)}</>
    },
    {
      title: 'MODIFIER ON',
      dataIndex: 'modifierOn',
      key: 'modifierOn',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'modifierOn' && sortedInfo?.order,
      width: 160,
      ...filterPopup('modifierOn'),
      render: (modifierOn) => {
        return <span>{`${modifierOn} Value `}</span>;
      }
    },
    {
      title: 'AMOUNT',
      dataIndex: 'pricingValue',
      key: 'pricingValue',
      sorter: true,
      ellipsis: true,
      onHeaderCell: () => {
        return {
          style: {
            textAlign: 'right'
          }
        };
      },
      align: 'right',
      sortOrder: sortedInfo?.columnKey === 'pricingValue' && sortedInfo?.order,
      width: 130,
      render: (amount, record) => {
        if (record?.pricingValueType === '$') {
          return (
            <span>
              {record?.pricingOperation === 'ADD' ? '' : '-'}
              {record?.pricingValueType}
              {formatUnits(amount)}
            </span>
          );
        }
        return (
          <span>
            {record?.pricingOperation === 'ADD' ? '' : '-'}
            {formatUnits(amount)}
            {record?.pricingValueType}
          </span>
        );
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      width: 120,
      ...filterPopup('status'),
      render: (status) => {
        return PRICING_STATUS[status];
      }
    },
    checkPermissions(permissions, [
      'FET_PRICING_MODIFIER_UPDATE',
      'FET_PRICING_MODIFIER_DELETE',
      'FET_PRICING_MODIFIER_VIEW'
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setDragLoading(true);
      try {
        const response = await changePriority({
          variables: {
            data: {
              oldIndex,
              newIndex
            }
          }
        });
        if (response) {
          fetchPricingModifierData({
            variables: {
              filter: {
                ...pricingModifierFilter
              }
            }
          });
          setDragLoading(false);
        }
      } catch (error) {
        message?.error('got some problem');
      }
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      axis="y"
      lockAxis="y"
      lockOffset={['0%', '100%']}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = find(
      pricingData,
      (item) => item?.id === restProps['data-row-key']
    );
    if (index) return <SortableItem index={index?.order} {...restProps} />;
    return null;
  };

  const onSearchChange = (value) => {
    setPricingModifierFilter({
      ...pricingModifierFilter,
      search: value
    });
    fetchPricingModifierData({
      variables: {
        filter: {
          ...pricingModifierFilter,
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  return (
    <div>
      <AccessControl allowedPermissions={['FET_PRICING_MODIFIER_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="pricingModifier-table-add-btn"
            type="primary"
            onClick={handleAddEditPricingModifier}
          >
            Add Pricing Modifier
          </Button>
        </Portal>
      </AccessControl>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_PRICING_MODIFIER_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Pricing or other detail.."
              name="PricingModifiers"
              getData={onSearchChange}
            />
          </AccessControl>
        </div>
      </div>
      <AccessControl
        allowedPermissions={['FET_PRICING_MODIFIER_LIST']}
        showNoAccess
      >
        <div className="common-table pricing-modifier-table">
          <TableComponent
            loadingData={
              loading ||
              updatePricingModifierLoading ||
              deletePricingModifierLoading ||
              dragLoading
            }
            columns={[...columns?.filter((item) => item !== false)]}
            data={pricingData || []}
            onChange={handleTableChange}
            setHeight={180}
            rowKey="id"
            components={
              pricingData?.length > 0
                ? {
                    body: {
                      wrapper: DraggableContainer,
                      row: DraggableBodyRow
                    }
                  }
                : {}
            }
          />
        </div>
      </AccessControl>
    </div>
  );
};

export default PricingModifierTable;
