import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Form,
  Popover,
  Radio,
  Switch,
  message
} from 'antd';
import {
  findIndex,
  flattenDeep,
  forIn,
  isArray,
  isEmpty,
  map,
  pull,
  snakeCase,
  uniq
} from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CONDITIONS,
  CONDITIONS_KEYS,
  DYNAMIC_FIELDS_DATA_KEYS,
  NUMERIC_CONDITIONS
} from '../../../common/constants';
import InputComponent from '../../../components/InputComponent';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';
import TreeComponent from '../../../components/TreeComponent';

const SkipPageModal = ({
  showModal = false,
  builderForm = null,
  setShowModal,
  pagesData = [],
  pageKey = null,
  checkedSkipPages = [],
  setCheckedSkipPages,
  setSkipPageConditions,
  setDisableBtn,
  skipPageConditions = [],
  isAlwaysSkipEnabled = false,
  setIsAlwaysSkipEnabled,
  editData = null
}) => {
  const [form] = Form?.useForm();
  const [previousPages, setPreviousPages] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [selectedListOptions, setSelectedListOptions] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const findPageIndex = findIndex(
    pagesData,
    (data) => data?.pageKey === snakeCase(pageKey)?.toUpperCase()
  );
  const booleanCondition = Form?.useWatch(
    ['skipPageConditions', 'booleanCondition'],
    form
  );

  const numberCondition = Form?.useWatch(
    ['skipPageConditions', 'numberCondition'],
    form
  );

  const listCondition = Form?.useWatch(
    ['skipPageConditions', 'listCondition'],
    form
  );

  const getPreviousPages = () => {
    const finalPages = [];
    const optionsList = {};

    map(pagesData, (item, index) => {
      const fieldType =
        item?.pageConfiguration?.widgetConfiguration?.fieldType ||
        item?.pageConfiguration?.widgetConfiguration?.selectedSystemFieldBlock
          ?.selectedSystemFieldType;

      if (
        index < findPageIndex &&
        item?.pageConfiguration?.widgetType !== 'CONTENT' &&
        fieldType !== DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD &&
        fieldType !== DYNAMIC_FIELDS_DATA_KEYS?.DATE_TIME
      ) {
        finalPages?.push(item);
      }
      if (fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST) {
        map(
          item?.pageConfiguration?.widgetConfiguration?.otherConfig ||
            item?.pageConfiguration?.widgetConfiguration?.config?.listItems,
          (option) => {
            optionsList[item?.id] = flattenDeep([
              flattenDeep(optionsList?.[item?.id]),
              {
                key: option?.blockId || option?.uuid,
                label: option?.label
              }
            ]);
          }
        );
      }
    });
    if (!isEmpty(skipPageConditions)) {
      const checkedIds = [];
      let listOptions = {};
      let selectedOptions = {};
      map(skipPageConditions, (field) => {
        if (field?.check === true) {
          checkedIds?.push(field?.identifier);
        }
        if (field?.check === true) {
          if (field?.type === 'TEXT') {
            form?.setFieldsValue({
              skipPageConditions: {
                textCondition: {
                  [field?.identifier]: field?.condition,
                  value: {
                    [field?.identifier]: field?.value
                  }
                }
              }
            });
          }
          if (field?.type === 'BOOLEAN') {
            form?.setFieldsValue({
              skipPageConditions: {
                booleanCondition: {
                  [field?.identifier]: field?.condition,
                  value: {
                    [field?.identifier]: field?.value
                  }
                }
              }
            });
          }
          if (field?.type === 'PICK_LIST') {
            form?.setFieldsValue({
              skipPageConditions: {
                listCondition: {
                  [field?.identifier]: field?.condition
                }
              }
            });
            if (!isEmpty(field?.value)) {
              selectedOptions = {
                ...selectedOptions,
                [field?.identifier]: field?.value
              };
            }
            if (
              (field?.condition === CONDITIONS_KEYS?.EQUAL_TO ||
                field?.condition === CONDITIONS_KEYS?.NOT_EQUAL_TO) &&
              !isEmpty(field?.value)
            ) {
              const newListOptions = map(
                optionsList?.[field?.identifier],
                (item) => {
                  if (item?.key !== field?.value?.[0]) {
                    return { ...item, disabled: true };
                  }
                  return item;
                }
              );

              listOptions = {
                ...listOptions,
                [field?.identifier]: newListOptions
              };
            }
          }
          if (field?.type === 'NUMBER') {
            form?.setFieldsValue({
              skipPageConditions: {
                numberCondition: {
                  [field?.identifier]: field?.condition,
                  ...(field?.condition !==
                    CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
                    value: {
                      [field?.identifier]: field?.value
                    }
                  }),
                  ...(field?.condition ===
                    CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
                    check: {
                      [field?.identifier]: {
                        lessThan: field?.lessThan?.check,
                        greaterThan: field?.greaterThan?.check
                      }
                    },
                    value: {
                      [field?.identifier]: {
                        lessThan: field?.lessThan?.value,
                        greaterThan: field?.greaterThan?.value
                      }
                    }
                  })
                }
              }
            });
          }
        }
      });
      setOptionsData({ ...optionsList, ...listOptions });
      setSelectedListOptions({
        ...selectedOptions
      });
      setCheckedSkipPages(checkedIds);
    } else {
      setOptionsData(optionsList);
    }

    setPreviousPages(finalPages);
  };

  useEffect(() => {
    if (findPageIndex !== -1) {
      getPreviousPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findPageIndex, skipPageConditions]);

  useEffect(() => {
    if (!isEmpty(selectedListOptions?.[selectedList])) {
      if (
        listCondition?.[selectedList] === CONDITIONS_KEYS?.EQUAL_TO ||
        listCondition?.[selectedList] === CONDITIONS_KEYS?.NOT_EQUAL_TO
      ) {
        const newListOptions = map(optionsData?.[selectedList], (item) => {
          if (item?.key !== selectedListOptions?.[selectedList]?.[0]) {
            return { ...item, disabled: true };
          }
          return item;
        });
        setOptionsData({ ...optionsData, [selectedList]: newListOptions });
      } else {
        const newListOptions = map(optionsData?.[selectedList], (item) => {
          if (item?.key !== selectedListOptions?.[selectedList]?.[0]) {
            return { ...item, disabled: false };
          }
          return item;
        });
        setOptionsData({ ...optionsData, [selectedList]: newListOptions });
      }
    } else if (optionsData?.[selectedList]) {
      const newListOptions = map(optionsData?.[selectedList], (item) => {
        return { ...item, disabled: false };
      });
      setOptionsData({ ...optionsData, [selectedList]: newListOptions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListOptions, listCondition]);

  const handleCancel = () => {
    setShowModal(false);
    setIsAlwaysSkipEnabled(editData?.alwaysSkip || false);
    form?.resetFields();
    setSkipPageConditions([...skipPageConditions]);
    setCheckedSkipPages([]);
  };

  const renderText = (id) => {
    return (
      <div>
        <p className="mb-8 ml-8 mt-8" hidden={!checkedSkipPages?.includes(id)}>
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['skipPageConditions', 'textCondition', id]}
            initialValue={CONDITIONS_KEYS?.EQUAL_TO}
            hidden={!checkedSkipPages?.includes(id)}
          >
            <Radio.Group className="common-radio fill-width d-flex flex-vertical">
              {map(CONDITIONS, (condition) => {
                return (
                  <Radio value={condition?.value} key={condition?.value}>
                    {condition?.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Add Filtering word or phrase"
            className="mt-8 ml-12"
            name={['skipPageConditions', 'textCondition', 'value', id]}
            hidden={!checkedSkipPages?.includes(id)}
          >
            <InputComponent name="word or phrase" />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderBoolean = (data) => {
    const trueValue =
      data?.pageConfiguration?.widgetConfiguration?.trueValue ||
      data?.pageConfiguration?.widgetConfiguration?.config?.trueValue;
    const falseValue =
      data?.pageConfiguration?.widgetConfiguration?.falseValue ||
      data?.pageConfiguration?.widgetConfiguration?.config?.falseValue;

    return (
      <div>
        <p
          className="mb-8 ml-8 mt-8"
          hidden={!checkedSkipPages?.includes(data?.id)}
        >
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['skipPageConditions', 'booleanCondition', data?.id]}
            initialValue="EQUAL_TO"
            hidden={!checkedSkipPages?.includes(data?.id)}
            valuePropName="checked"
          >
            <Radio value="EQUAL_TO" key="EQUAL_TO" className="common-radio">
              Equal to
            </Radio>
          </Form.Item>
          <Form.Item
            name={['skipPageConditions', 'booleanCondition', 'value', data?.id]}
            hidden={!checkedSkipPages?.includes(data?.id)}
          >
            <Checkbox.Group
              className="ml-12 d-flex"
              disabled={isAlwaysSkipEnabled}
            >
              <Checkbox
                className="common-checkbox"
                key={trueValue}
                value="true"
                disabled={
                  isAlwaysSkipEnabled
                    ? true
                    : booleanCondition?.value?.[data?.id]?.length > 0 &&
                      booleanCondition?.value?.[data?.id]?.[0] !== 'true'
                }
              >
                {trueValue}
              </Checkbox>
              <Checkbox
                className="common-checkbox"
                key={falseValue}
                value="false"
                disabled={
                  isAlwaysSkipEnabled
                    ? true
                    : booleanCondition?.value?.[data?.id]?.length > 0 &&
                      booleanCondition?.value?.[data?.id]?.[0] !== 'false'
                }
              >
                {falseValue}
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderNumber = (id) => {
    const isLessThanEnabled = numberCondition?.check?.[id]?.lessThan;
    const isGreaterThanEnabled = numberCondition?.check?.[id]?.greaterThan;
    return (
      <div>
        <p className="mb-8 ml-8 mt-8" hidden={!checkedSkipPages?.includes(id)}>
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['skipPageConditions', 'numberCondition', id]}
            initialValue={CONDITIONS_KEYS?.EQUAL_TO}
            hidden={!checkedSkipPages?.includes(id)}
          >
            <Radio.Group className="common-radio fill-width d-flex flex-vertical">
              {map(NUMERIC_CONDITIONS, (condition) => {
                return (
                  <Radio
                    value={condition?.value}
                    key={condition?.value}
                    onChange={() => {
                      form?.setFieldsValue({
                        skipPageConditions: {
                          numberCondition: {
                            value: {
                              [id]: null
                            },
                            check: {
                              [id]: {
                                lessThan: false,
                                greaterThan: false
                              }
                            }
                          }
                        }
                      });
                    }}
                  >
                    {condition?.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </div>
        {numberCondition?.[id] === CONDITIONS_KEYS?.GREATER_LESS_RANGE && (
          <div className="d-flex mt-8 ml-12">
            <div>
              <div className="d-flex justify-between align-center">
                <span
                  className="switch-logo"
                  hidden={!checkedSkipPages?.includes(id)}
                >
                  Less than
                </span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name={[
                    'skipPageConditions',
                    'numberCondition',
                    'check',
                    id,
                    'lessThan'
                  ]}
                  initialValue={false}
                  hidden={!checkedSkipPages?.includes(id)}
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={[
                  'skipPageConditions',
                  'numberCondition',
                  'value',
                  id,
                  'lessThan'
                ]}
                initialValue="0"
                hidden={!checkedSkipPages?.includes(id)}
                dependencies={[
                  [
                    'skipPageConditions',
                    'numberCondition',
                    'value',
                    id,
                    'greaterThan'
                  ],
                  [
                    'skipPageConditions',
                    'numberCondition',
                    'check',
                    id,
                    'lessThan'
                  ]
                ]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue([
                          'skipPageConditions',
                          'numberCondition',
                          'check',
                          id,
                          'lessThan'
                        ]) &&
                        getFieldValue([
                          'skipPageConditions',
                          'numberCondition',
                          'value',
                          id,
                          'greaterThan'
                        ]) &&
                        Number(value) <=
                          Number(
                            getFieldValue([
                              'skipPageConditions',
                              'numberCondition',
                              'value',
                              id,
                              'greaterThan'
                            ])
                          )
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Should be greater than ${getFieldValue([
                              'skipPageConditions',
                              'numberCondition',
                              'value',
                              id,
                              'greaterThan'
                            ])}`
                          )
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <NumberComponent name="number" disabled={!isLessThanEnabled} />
              </Form.Item>
            </div>
            <div className="ml-12">
              <div className="d-flex justify-between align-center">
                <span
                  className="switch-logo"
                  hidden={!checkedSkipPages?.includes(id)}
                >
                  Greater than
                </span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name={[
                    'skipPageConditions',
                    'numberCondition',
                    'check',
                    id,
                    'greaterThan'
                  ]}
                  initialValue={false}
                  hidden={!checkedSkipPages?.includes(id)}
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={[
                  'skipPageConditions',
                  'numberCondition',
                  'value',
                  id,
                  'greaterThan'
                ]}
                initialValue="0"
                hidden={!checkedSkipPages?.includes(id)}
                dependencies={[
                  [
                    'skipPageConditions',
                    'numberCondition',
                    'value',
                    id,
                    'lessThan'
                  ],
                  [
                    'skipPageConditions',
                    'numberCondition',
                    'check',
                    id,
                    'greaterThan'
                  ]
                ]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue([
                          'skipPageConditions',
                          'numberCondition',
                          'check',
                          id,
                          'greaterThan'
                        ]) &&
                        getFieldValue([
                          'skipPageConditions',
                          'numberCondition',
                          'value',
                          id,
                          'lessThan'
                        ]) &&
                        Number(value) >=
                          Number(
                            getFieldValue([
                              'skipPageConditions',
                              'numberCondition',
                              'value',
                              id,
                              'lessThan'
                            ])
                          )
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Should be less than ${getFieldValue([
                              'skipPageConditions',
                              'numberCondition',
                              'value',
                              id,
                              'lessThan'
                            ])}`
                          )
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <NumberComponent
                  name="number"
                  disabled={!isGreaterThanEnabled}
                />
              </Form.Item>
            </div>
          </div>
        )}
        {(numberCondition?.[id] === CONDITIONS_KEYS?.EQUAL_TO ||
          numberCondition?.[id] === CONDITIONS_KEYS?.NOT_EQUAL_TO) && (
          <Form.Item
            label={
              numberCondition?.[id] === CONDITIONS_KEYS?.EQUAL_TO
                ? 'Equal to'
                : 'Not Equal to'
            }
            className="mt-8 ml-12 width-percent-30"
            name={['skipPageConditions', 'numberCondition', 'value', id]}
            hidden={!checkedSkipPages?.includes(id)}
          >
            <NumberComponent name="number" />
          </Form.Item>
        )}
      </div>
    );
  };

  const handleOptionsSelectAll = (id, data) => {
    if (selectedListOptions?.[id]?.length > 0) {
      setSelectedListOptions({
        ...selectedListOptions,
        [id]: null
      });
    } else {
      const optionsIds = map(data?.[id], (item) => item?.key);
      const updatedListOptions = {
        [id]: [...optionsIds]
      };
      setSelectedListOptions({
        ...selectedListOptions,
        ...updatedListOptions
      });
    }
  };

  const renderList = (data) => {
    return (
      <div>
        <p
          className="mb-8 ml-8 mt-8"
          hidden={!checkedSkipPages?.includes(data?.id)}
        >
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['skipPageConditions', 'listCondition', data?.id]}
            initialValue={CONDITIONS_KEYS?.EQUAL_TO}
            hidden={!checkedSkipPages?.includes(data?.id)}
          >
            <Radio.Group className="common-radio fill-width d-flex flex-vertical">
              {map(CONDITIONS, (condition) => {
                return (
                  <Radio
                    value={condition?.value}
                    key={condition?.value}
                    onChange={() => {
                      setSelectedListOptions({
                        ...selectedListOptions,
                        [data?.id]: null
                      });
                      setSelectedList(data?.id);
                    }}
                  >
                    {condition?.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </div>
        {(listCondition?.[data?.id] === CONDITIONS_KEYS?.CONTAINS ||
          listCondition?.[data?.id] === CONDITIONS_KEYS?.NOT_CONTAINS) &&
          optionsData?.[data?.id]?.length > 0 && (
            <span
              className="select-all-text"
              onClick={() => handleOptionsSelectAll(data?.id, optionsData)}
              hidden={!checkedSkipPages?.includes(data?.id)}
            >
              {selectedListOptions?.[data?.id]?.length > 0
                ? 'Deselect All'
                : 'Select All'}
            </span>
          )}
        {optionsData?.[data?.id]?.length > 0 ? (
          <Form.Item
            name={['skipPageConditions', 'listCondition', 'value', data?.id]}
            hidden={!checkedSkipPages?.includes(data?.id)}
          >
            <TreeComponent
              className="panel-tree"
              data={optionsData?.[data?.id]}
              checkedKeys={selectedListOptions?.[data?.id]}
              blockNode
              disabled={isAlwaysSkipEnabled}
              titleRender={(item) => {
                return (
                  <div
                    className="d-flex justify-between"
                    key={item?.key}
                    title={item?.label}
                  >
                    <p>{item?.label}</p>
                  </div>
                );
              }}
              setCheckedKeys={setSelectedListOptions}
              onCheckParent={(value) => {
                const updatedListOptions = {
                  [data?.id]: [...value]
                };
                setSelectedListOptions({
                  ...selectedListOptions,
                  ...updatedListOptions
                });
                setSelectedList(data?.id);
              }}
            />
          </Form.Item>
        ) : (
          <Empty />
        )}
      </div>
    );
  };

  const handleFinish = (values) => {
    let fieldType;
    let hasError = false;
    const getFieldValues = {};
    const getCheckedValues = {};
    const finalSkipConditions = [];

    map(values?.skipPageConditions, (item, fieldKey) => {
      if (fieldKey === 'listCondition') {
        fieldType = 'PICK_LIST';
      } else if (fieldKey === 'numberCondition') {
        fieldType = 'NUMBER';
      } else if (fieldKey === 'booleanCondition') {
        fieldType = 'BOOLEAN';
      } else {
        fieldType = 'TEXT';
      }
      if (item?.value) {
        forIn(item?.value, (value, key) => {
          getFieldValues[key] = value;
        });
      }
      if (item?.check) {
        forIn(item?.check, (value, key) => {
          getCheckedValues[key] = value;
        });
      }
      map(item, (data, itemKey) => {
        if (
          checkedSkipPages?.includes(itemKey) &&
          fieldKey !== 'listCondition' &&
          (!getFieldValues?.[itemKey] ||
            isEmpty(getFieldValues?.[itemKey]) ||
            (data === CONDITIONS_KEYS?.GREATER_LESS_RANGE &&
              !getCheckedValues?.[itemKey]?.greaterThan &&
              !getCheckedValues?.[itemKey]?.lessThan))
        ) {
          message?.destroy();
          message?.error('Provide proper values for selected pages');
          hasError = true;
        }
        if (
          checkedSkipPages?.includes(itemKey) &&
          fieldKey === 'listCondition' &&
          (!selectedListOptions?.[itemKey] ||
            isEmpty(selectedListOptions?.[itemKey]))
        ) {
          message?.destroy();
          message?.error('Provide proper values for selected pages');
          hasError = true;
          return;
        }
        if (
          (getCheckedValues?.[itemKey]?.greaterThan &&
            !getFieldValues?.[itemKey]?.greaterThan) ||
          (getCheckedValues?.[itemKey]?.lessThan &&
            !getFieldValues?.[itemKey]?.lessThan)
        ) {
          message?.destroy();
          message?.error('Provide proper values for selected pages');
          hasError = true;
        }
        let fieldValue;
        if (getFieldValues[itemKey]) {
          if (isArray(getFieldValues?.[itemKey])) {
            fieldValue = getFieldValues?.[itemKey];
          } else {
            fieldValue = [getFieldValues?.[itemKey]];
          }
        } else if (fieldKey === 'listCondition') {
          fieldValue = selectedListOptions?.[itemKey];
        } else {
          fieldValue = [];
        }
        if (itemKey !== 'value' && itemKey !== 'check') {
          finalSkipConditions?.push({
            identifier: itemKey,
            condition: data,
            ...(!(data === CONDITIONS_KEYS?.GREATER_LESS_RANGE) && {
              value: fieldValue
            }),
            check: checkedSkipPages?.includes(itemKey),
            ...(data === CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
              greaterThan: {
                check: getCheckedValues?.[itemKey]?.greaterThan,
                value: getFieldValues?.[itemKey]?.greaterThan
              },
              lessThan: {
                check: getCheckedValues?.[itemKey]?.lessThan,
                value: getFieldValues?.[itemKey]?.lessThan
              }
            }),
            type: fieldType
          });
        }
      });
    });

    if (hasError) {
      return;
    }

    setSkipPageConditions([...finalSkipConditions]);
    setDisableBtn(false);
    setShowModal(false);
    builderForm?.submit();
  };

  return (
    <div>
      <ModalComponent
        title="SKIP PAGE CONDITIONS"
        open={showModal}
        onCancel={handleCancel}
        wrapClassName="skip-page-modal"
        footer={
          <div className="d-flex justify-between fill-width">
            <div className="d-flex align-center">
              <Checkbox
                className="common-checkbox"
                disabled={isEmpty(previousPages)}
                onChange={(e) => {
                  setIsAlwaysSkipEnabled(e?.target?.checked);
                }}
                checked={isAlwaysSkipEnabled}
              >
                Always skip page
              </Checkbox>
              <Popover
                overlayClassName="pointer"
                content="When enabled, this page will be skipped if specific criteria from previous pages are met."
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>
            <div className="d-flex">
              <Button onClick={handleCancel} className="common-button">
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="common-button"
                disabled={isEmpty(previousPages)}
                onClick={form?.submit}
              >
                Apply
              </Button>
            </div>
          </div>
        }
        width={670}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          disabled={isAlwaysSkipEnabled}
        >
          {isEmpty(previousPages) && (
            <div>
              <span>We are unable to find Fields in previous pages</span>
            </div>
          )}
          {map(previousPages, (item) => {
            const fieldType =
              item?.pageConfiguration?.widgetConfiguration?.fieldType ||
              item?.pageConfiguration?.widgetConfiguration
                ?.selectedSystemFieldBlock?.selectedSystemFieldType;
            return (
              <div key={item?.id}>
                <div className="d-flex align-center justify-between ml-8">
                  <h5 className="mb-0">{item?.title}</h5>
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={() => {
                      if (checkedSkipPages?.includes(item?.id)) {
                        pull(checkedSkipPages, item?.id);
                        setCheckedSkipPages([...checkedSkipPages]);
                      } else {
                        setCheckedSkipPages(
                          uniq([...checkedSkipPages, item?.id])
                        );
                      }
                      setDisableBtn(false);
                    }}
                    checked={checkedSkipPages?.includes(item?.id)}
                  />
                </div>
                {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.TEXT &&
                  renderText(item?.id)}
                {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN &&
                  renderBoolean(item)}
                {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.NUMBER &&
                  renderNumber(item?.id)}
                {fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST &&
                  renderList(item, optionsData)}
                <Divider className="global-divider" />
              </div>
            );
          })}
        </Form>
      </ModalComponent>
    </div>
  );
};

export default SkipPageModal;
