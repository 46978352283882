import { gql } from '@apollo/client';

export const GET_LEADS = gql`
  query Leads($filter: LeadFilter!, $where: LeadWhere) {
    leads(filter: $filter, where: $where) {
      count
      data {
        id
        createdAt
        customerName
        status
        amount
        exportStatus
        brand {
          name
          id
          description
        }
        industry {
          label
          id
        }
        lineOfBusiness {
          id
          label
        }
        questionnaire {
          id
          title
        }
        region {
          id
          name
        }
      }
    }
  }
`;
export const GET_LEAD = gql`
  query Lead($where: LeadWhereUniqueInput!) {
    lead(where: $where) {
      id
      customerName
      email
      phoneNumber
      createdAt
      brand {
        id
        name
        refData
        primaryColor
        uuid
        secondaryColors
        tertiaryColors
        colorPallete
        description
        waiverText
        priceGuarantee
        offerText
        productDetails
        isActive
        createdBy
        modifiedBy
        tenantId
        createdAt
        updatedAt
      }
      region {
        id
        name
      }
      industry {
        id
        label
      }
      scheduleTime {
        date
        startTime
        endTime
        timezone
      }
      emailMeInfo {
        urgent
        emailConfirmation
        comments
      }
      comments
      exportStatus
      tenant
      tenantLogo
      location {
        addressLine1
        city
        state
        zipCode
        country
      }
      suggestedQuotes {
        good {
          id
          title
        }
        better {
          id
          title
        }
        best {
          id
          title
        }
      }
      quotes {
        id
        sku
        amount
        package
        quality
        customFields
        systemFields
        manufacturer
        lineOfBusiness
      }
    }
  }
`;

export const GET_QUOTE_VIEWS = gql`
  query quoteViews($filter: QuoteFilter!, $where: QuoteViewWhere) {
    quoteViews(filter: $filter, where: $where) {
      count
      data {
        id
        questionnaireId
        leadId
        packageTitle
        sku
        quality
        comments
        flowType
        upgradeInfo {
          upgradeChecks
          otherDetails
        }
        emailMeInfo {
          urgent
          emailConfirmation
        }
        contactMethods
        externalId
        industry {
          id
          key
          label
        }
        lineOfBusiness {
          id
          key
          label
        }
        subArea {
          id
          key
          label
        }
        customerName
        status
        exportStatus
        externalLeadNumber
        crmLeadId
        workPhone
        homePhone
        textOptInApproval
        textOptInApprovalDate
        emailVerified
        contactPreferences {
          doNotEmail
          doNotPhone
          doNotText
        }
        campaignId
        crmCampaignId
        estimator
        brand {
          name
          logo {
            url
            key
            name
            extension
            contentType
          }
        }
        region {
          name
        }
        customerLocation {
          addressLine1
          addressLine2
          addressLine3
          city
          county
          state
          country
          zipCode
        }
        email
        mobileNumber
        amount
        appointmentTime
        startTime
        endTime
        manufacturer {
          id
          name
        }
        createdAt
      }
    }
  }
`;

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const SUB_AREA_FILTER = gql`
  query subAreaFilters($filter: SubAreaFilter!) {
    subAreaFilters(filter: $filter) {
      count
      data {
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
    }
  }
`;
