import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Tag
} from 'antd';
import { debounce, filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import { exportOptions, ROUTES, SKIP_RECORD } from '../../../common/constants';
import { formatPhoneNumber, handleExportCommon } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_CUSTOMER, UPDATE_CUSTOMER } from '../graphql/Mutations';
import {
  CUSTOMER_FILTER,
  GET_CUSTOMERS,
  USER_FILTER
} from '../graphql/Queries';

let scrollDebounce = null;

const CustomerTable = () => {
  const {
    state: { pageSize, filterData },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialCustomerFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [customerFilter, setCustomerFilter] = useState(initialCustomerFilter);
  const [filters, setFilters] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [
    updateCustomerMutate,
    { loading: updateCustomerLoading }
  ] = useMutation(UPDATE_CUSTOMER, {
    onError() {}
  });

  const [deleteCustomer, { loading: deleteCustomerLoading }] = useMutation(
    DELETE_CUSTOMER,
    {
      onError() {}
    }
  );

  const [fetchCustomerData, { loading, data }] = useLazyQuery(GET_CUSTOMERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.customers?.count
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [customerFilters] = useLazyQuery(CUSTOMER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.customerFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.customerFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.customerFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  const [userFilters] = useLazyQuery(USER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.userFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    fetchCustomerData({
      variables: {
        filter: customerFilter,
        ...(filterData && { where: filterData })
      }
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchCustomerData({
      variables: {
        filter: { ...customerFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState })
      }
    });
    setFilters(filtersCopyState);
    setCustomerFilter({
      ...customerFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'name':
          userFilters({
            variables: {
              filter: {
                sortOn: 'firstName',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: ['firstName', 'lastName'],
                showCurrentUser: true
              }
            }
          });
          break;

        default:
          customerFilters({
            variables: {
              filter: {
                sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField:
                  filterIndex === 'name'
                    ? ['firstName', 'lastName']
                    : filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'name':
            userFilters({
              variables: {
                filter: {
                  sortOn: 'firstName',
                  sortBy: 'ASC',
                  skip: 0,
                  limit: filterList?.length,
                  search: filterSearch,
                  distinct: true,
                  getDBField: ['firstName', 'lastName'],
                  showCurrentUser: true
                }
              }
            });
            break;
          default:
            customerFilters({
              variables: {
                filter: {
                  sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField:
                    filterIndex === 'name'
                      ? ['firstName', 'lastName']
                      : filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 38,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    fetchCustomerData({
      variables: {
        filter: {
          ...customerFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && { where: filtersCopy })
      }
    });
    setCustomerFilter({
      ...customerFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditCustomer = (record) => {
    if (record?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.CUSTOMERS}/edit/${record?.id}`);
    } else {
      history?.push(`${ROUTES?.CUSTOMERS}/add`);
    }
  };

  const handleViewCustomer = (record) => {
    dispatch({ type: 'SET_FILTER_DATA', data: filters });
    history?.push(`${ROUTES?.CUSTOMERS}/view/${record?.id}`);
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setCustomerFilter(initialCustomerFilter);
    setSortedInfo({});
    fetchCustomerData({
      variables: { filter: initialCustomerFilter }
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setCustomerFilter({
        ...customerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchCustomerData({
        variables: {
          filter: {
            ...customerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    } else {
      setCustomerFilter({
        ...customerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchCustomerData({
        variables: {
          filter: {
            ...customerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'name':
        userFilters({
          variables: {
            filter: {
              sortOn: 'firstName',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: filterSearch,
              distinct: true,
              getDBField: ['firstName', 'lastName'],
              showCurrentUser: true
            }
          }
        });
        break;
      default:
        customerFilters({
          variables: {
            filter: {
              sortOn: dataIndex === 'name' ? 'firstName' : dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField:
                dataIndex === 'name' ? ['firstName', 'lastName'] : dataIndex
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleDeleteCustomer = async (editCustomerData) => {
    const response = await deleteCustomer({
      variables: { where: { id: editCustomerData?.id } }
    });
    if (response?.data?.deleteCustomer) {
      fetchCustomerData({
        variables: {
          filter: customerFilter,
          ...(filters && { where: filters })
        }
      });
    }
  };

  const handleCustomerStatus = async (editCustomerData) => {
    const editData = {
      isActive: !editCustomerData?.isActive
    };

    const response = await updateCustomerMutate({
      variables: { data: { ...editData }, where: { id: editCustomerData?.id } }
    });
    if (response?.data?.updateCustomer) {
      fetchCustomerData({
        variables: {
          filter: customerFilter,
          ...(filters && { where: filters })
        }
      });
    }
  };

  const renderActionButtons = (editCustomerData) => {
    return (
      <div className="d-flex flex-vertical">
        <Button
          id="customer-table-edit-btn"
          className="b-0"
          onClick={() => handleAddEditCustomer(editCustomerData)}
        >
          Edit
        </Button>
        <Button
          id="customer-table-edit-btn"
          className="b-0"
          onClick={() => handleViewCustomer(editCustomerData)}
        >
          View
        </Button>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleDeleteCustomer(editCustomerData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="customer-table-status-btn" className="b-0">
            Remove
          </Button>
        </Popconfirm>
        <Popconfirm
          title={`Are you sure to ${
            editCustomerData?.isActive ? 'Mark Inactive' : 'Mark Active'
          }?`}
          onConfirm={() => handleCustomerStatus(editCustomerData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="customer-table-status-btn" className="b-0">
            {editCustomerData?.isActive ? 'Mark Inactive' : 'Mark Active'}
          </Button>
        </Popconfirm>
      </div>
    );
  };

  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (firstName, record) => {
        return `${record?.user?.firstName} ${record?.user?.lastName}`;
      }
    },
    {
      title: 'CONTACT',
      width: 130,
      ellipsis: true,
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      render: (phoneNo, record) => {
        return formatPhoneNumber(record?.user?.phoneNo) || '-';
      }
    },
    {
      title: 'EMAIL',
      ellipsis: true,
      width: 130,
      dataIndex: 'email',
      key: 'email',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      render: (email, record) => {
        return `${record?.user?.email}`;
      }
    },
    {
      title: 'ADDRESS',
      ellipsis: true,
      dataIndex: 'location',
      className: 'max-width-column',
      key: 'location',
      render: (location) => {
        return `${location?.addressLine1} ${location?.city}, ${location?.state} ${location?.zipCode}`;
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      width: 120,
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    {
      dataIndex: 'id',
      align: 'right',
      fixed: 'right',
      width: 10,
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = (value) => {
    setCustomerFilter({
      ...customerFilter,
      skip: value ? 0 : customerFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchCustomerData({
      variables: {
        filter: {
          ...customerFilter,
          skip: value
            ? 0
            : customerFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && { where: filters })
      }
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'CUSTOMER'
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="customers"
        folder="CUSTOMER"
      />
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<PlusOutlined />}
          size="small"
          id="customer-table-add-btn"
          type="primary"
          onClick={handleAddEditCustomer}
        >
          Add Customer
        </Button>
      </Portal>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            className="list-search-box"
            id="search-container-id"
            placeholder="Customer or other detail.."
            name="Customers"
            getData={onSearchChange}
          />
        </div>
        <div className="header-buttons">
          <Button
            size="small"
            className="common-button import-button"
            icon={<img src={ImportIcon} alt="import-icon" width={11} />}
            id="customer-table-import-btn"
            type="primary"
            onClick={() => handleShowImportModal(true)}
          >
            Import
          </Button>
          <Dropdown
            overlayClassName="export-btn-dropdown"
            menu={{ items: exportOptions, onClick: handleExport }}
            placement="bottom"
          >
            <Button
              className="common-button export-button"
              size="small"
              icon={<img src={ExportIcon} alt="export-icon" width={11} />}
              id="customer-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={
              loading || updateCustomerLoading || deleteCustomerLoading
            }
            columns={[...columns?.filter((item) => item !== false)]}
            data={data?.customers?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowSelection={rowSelection}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerTable;
