import { Card } from 'antd';
import React from 'react';
import RegionForm from '../components/RegionForm';

function RegionEdit() {
  return (
    <Card className="full-height-card card-body-padding">
      <RegionForm isEdit />
    </Card>
  );
}

export default RegionEdit;
