import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Popover,
  Row
} from 'antd';
import { debounce, get, map, omit, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import CheckInfo from '../../../assets/check-info.svg';
import SaveIcon from '../../../assets/save.svg';
import {
  ROUTES,
  SKIP_RECORD,
  UNITS_OF_MEASURE,
  WARRANTY_UOM
} from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DatePickerComponent from '../../../components/DatePickerComponent';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import PreviewModal from '../../../components/PreviewModal';
import PriceComponent from '../../../components/PriceComponent';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import RenderDateTime from '../../dynamicFields/components/rendering/RenderDateTime';
import RenderNumber from '../../dynamicFields/components/rendering/RenderNumber';
import RenderRadio from '../../dynamicFields/components/rendering/RenderRadio';
import RenderSelect from '../../dynamicFields/components/rendering/RenderSelect';
import RenderText from '../../dynamicFields/components/rendering/RenderText';
import RenderUpload from '../../dynamicFields/components/rendering/RenderUpload';
import { BRAND, REGION } from '../../products/graphql/Queries';
import {
  GET_DYNAMIC_FIELDS_WITH_LIST_ITEMS,
  GET_INDUSTRIES,
  GET_PRODUCT_ITEMS,
  LINE_OF_BUSINESS,
  MANUFACTURER,
  PRODUCT_CATEGORY,
  SUB_AREA
} from '../graphql/Queries';

let industryScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;
let manufacturerScrollDebounceJob;
let productCategoryScrollDebounceJob;
let brandScrollDebounceJob;
let regionScrollDebounceJob;
let productItemsScrollDebounceJob;

const { required, requiredWhiteSpaceAllowed, number } = formValidatorRules;

const industryFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};
const lineOfBusinessFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true
};
const subAreaFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true
};
const manufacturerFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};
const productCategoryFilter = {
  sortOn: 'title',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};

const initialProductItemsFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'createdAt',
  sortBy: 'DESC'
};

const brandFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  defaultBrand: true
};

const regionFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: ''
};

const WORD_LIMIT = 70;

const { Option } = SelectComponent;

const ProductItemForm = (props) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const {
    productItemData = {},
    isSubmit,
    isEdit = false,
    showCreateProductModal = false,
    handleCreateProduct,
    handleCreateProductCancel,
    handleCreateProductForm,
    handleProductItemForm
  } = props;

  const [validationTriggered, setValidationTriggered] = useState(false);
  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industriesData, setIndustriesData] = useState([]);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);

  const [lineOfBusinessLoading, setLineOfBusinessLoading] = useState(false);
  const [lineOfBusinessSearchFlag, setLineOfBusinessSearchFlag] = useState(
    false
  );
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessIsEnd, setLineOfBusinessIsEnd] = useState(false);
  const [lineOfBusinessDebounceCall, setLineOfBusinessDebounceCall] = useState(
    0
  );

  const [subAreaLoading, setSubAreaLoading] = useState(false);
  const [subAreaSearchFlag, setSubAreaSearchFlag] = useState(false);
  const [subAreasData, setSubAreasData] = useState([]);
  const [disableLob, setDisableLob] = useState(true);
  const [disableSubArea, setDisableSubArea] = useState(true);
  const [subAreaIsEnd, setSubAreaIsEnd] = useState(false);
  const [subAreaDebounceCall, setSubAreaDebounceCall] = useState(0);

  const [manufacturerLoading, setManufacturerLoading] = useState(false);
  const [manufacturerSearchFlag, setManufacturerSearchFlag] = useState(false);
  const [manufacturersData, setManufacturersData] = useState([]);
  const [manufacturerIsEnd, setManufacturerIsEnd] = useState(false);
  const [manufactureDebounceCall, setManufactureDebounceCall] = useState(0);

  const [productCategoriesData, setProductCategoriesData] = useState([]);
  const [productCategoryLoading, setProductCategoryLoading] = useState(false);
  const [productCategorySearchFlag, setProductCategorySearchFlag] = useState(
    false
  );
  const [productCategoryIsEnd, setProductCategoryIsEnd] = useState(false);
  const [
    productCategoryDebounceCall,
    setProductCategoryDebounceCall
  ] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  const [productItemsData, setProductItemsData] = useState([]);
  const [productItemsLoading, setProductItemsLoading] = useState(false);
  const [productItemsSearchFlag, setProductItemsSearchFlag] = useState(false);
  const [productItemsIsEnd, setProductItemsIsEnd] = useState(false);
  const [productItemsDebounceCall, setProductItemsDebounceCall] = useState(0);

  const [brandLoading, setBrandLoading] = useState(false);
  const [brandSearchFlag, setBrandSearchFlag] = useState(false);
  const [brandsData, setBrandsData] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);

  const [regionLoading, setRegionLoading] = useState(false);
  const [regionSearchFlag, setRegionSearchFlag] = useState(false);
  const [regionsData, setRegionsData] = useState([]);
  const [regionIsEnd, setRegionIsEnd] = useState(false);
  const [regionDebounceCall, setRegionDebounceCall] = useState(0);
  const [dynamicFieldLoading, setDynamicFieldsLoading] = useState(false);
  const [dynamicFieldData, setDynamicFieldData] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [initialSubAreaRender, setInitialSubAreaRender] = useState(true);
  const [initialLobRender, setInitialLobRender] = useState(true);

  const [form] = Form?.useForm();
  const location = useLocation();

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const [brands] = useLazyQuery(BRAND, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEnd(res?.brands?.data?.length < SKIP_RECORD);
      if (brandSearchFlag) {
        setBrandsData([...res?.brands?.data]);
      } else {
        setBrandsData([...brandsData, ...res?.brands?.data]);
      }
      setBrandLoading(false);
    },
    onError() {
      setBrandLoading(false);
    }
  });

  const [dynamicFieldsWithListItems] = useLazyQuery(
    GET_DYNAMIC_FIELDS_WITH_LIST_ITEMS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setDynamicFieldData(res?.dynamicFieldsWithListItems);
        setDynamicFieldsLoading(false);
      },
      onError() {}
    }
  );

  const onBrandScroll = (event) => {
    setBrandSearchFlag(false);
    if (brandScrollDebounceJob) {
      brandScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    brandScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setBrandLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        brands({
          variables: {
            filter: {
              ...brandFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    brandScrollDebounceJob();
  };

  const handleBrandChange = (value) => {
    setBrandSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setBrandLoading(true);
      brands({
        variables: {
          filter: {
            ...brandFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    } else {
      setBrandLoading(true);
      brands({
        variables: {
          filter: {
            ...brandFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedBrandHandler = useCallback(
    debounce(handleBrandChange, 500),
    []
  );

  const handleBrandClear = () => {
    form?.setFieldsValue({
      brands: null
    });
    setBrandsData([]);
    brands({ variables: { filter: brandFilter, where: { isActive: true } } });
  };

  const handleBrandBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
    setBrandSearchFlag(true);
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true }
      }
    });
  };

  const [regions] = useLazyQuery(REGION, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setRegionIsEnd(res?.regions?.data?.length < SKIP_RECORD);
      if (regionSearchFlag) {
        setRegionsData([...res?.regions?.data]);
      } else {
        setRegionsData([...regionsData, ...res?.regions?.data]);
      }
      setRegionLoading(false);
    },
    onError() {
      setRegionLoading(false);
    }
  });

  const onRegionScroll = (event) => {
    setRegionSearchFlag(false);
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !regionIsEnd) {
        setRegionLoading(true);
        setRegionDebounceCall((prevState) => prevState + 1);
        regions({
          variables: {
            filter: {
              ...regionFilter,
              skip: (regionDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const handleRegionChange = (value) => {
    setRegionSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setRegionLoading(true);
      regions({
        variables: {
          filter: {
            ...regionFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    } else {
      setRegionLoading(true);
      regions({
        variables: {
          filter: {
            ...regionFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRegionHandler = useCallback(
    debounce(handleRegionChange, 500),
    []
  );

  const handleRegionClear = () => {
    form?.setFieldsValue({
      regions: null
    });
    setRegionsData([]);
    regions({ variables: { filter: regionFilter, where: { isActive: true } } });
  };

  const handleRegionBlur = () => {
    setSearchValue('');
    setRegionDebounceCall(0);
    setRegionIsEnd(false);
    setRegionSearchFlag(true);
    regions({
      variables: {
        filter: regionFilter,
        where: { isActive: true }
      }
    });
  };

  const [fetchProductItemsData] = useLazyQuery(GET_PRODUCT_ITEMS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setProductItemsData(res?.productItems?.data?.length < SKIP_RECORD);
      if (productItemsSearchFlag) {
        setProductItemsData([...res?.productItems?.data]);
      } else {
        setProductItemsData([...productItemsData, ...res?.productItems?.data]);
      }
      setProductItemsLoading(false);
    },
    onError() {
      setProductItemsLoading(false);
    }
  });

  const onProductItemsScroll = (event) => {
    setProductItemsSearchFlag(false);
    if (productItemsScrollDebounceJob) {
      productItemsScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    productItemsScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !productItemsIsEnd) {
        setProductItemsLoading(true);
        setProductItemsDebounceCall((prevState) => prevState + 1);
        fetchProductItemsData({
          variables: {
            filter: {
              ...initialProductItemsFilter,
              skip: (productItemsDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    productItemsScrollDebounceJob();
  };

  const handleProductItemsChange = (value) => {
    setProductItemsSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setProductItemsLoading(true);
      fetchProductItemsData({
        variables: {
          filter: {
            ...initialProductItemsFilter,
            search: value
          }
        }
      });
    } else {
      setProductItemsLoading(false);
      fetchProductItemsData({
        variables: {
          filter: {
            ...initialProductItemsFilter,
            search: value
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceProductItemsHandler = useCallback(
    debounce(handleProductItemsChange, 500),
    []
  );

  const handleProductItemsBlur = () => {
    setSearchValue('');
    setProductItemsIsEnd(false);
    setProductItemsDebounceCall(0);
  };

  const handleProductItemsClear = () => {
    form?.setFieldsValue({
      substituteProducts: null
    });
    setProductItemsData([]);
    fetchProductItemsData({
      variables: { filter: initialProductItemsFilter }
    });
  };

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustryIsEnd(res?.industries?.data?.length < SKIP_RECORD);
      if (industrySearchFlag) {
        setIndustriesData([...res?.industries?.data]);
      } else {
        if (isEdit) {
          const tempIndustry = [
            productItemData?.industry,
            ...res?.industries?.data
          ];
          setIndustriesData(uniqBy([...industriesData, ...tempIndustry], 'id'));
          return;
        }
        setIndustriesData([...industriesData, ...res?.industries?.data]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    }
  });

  const onIndustryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setIndustryLoading(true);
      industries({
        variables: {
          filter: {
            ...industryFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setIndustryLoading(true);
      industries({
        variables: {
          filter: {
            ...industryFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedIndustryHandler = useCallback(
    debounce(handleIndustryChange, 500),
    []
  );

  const handleIndustryClear = () => {
    form?.setFieldsValue({
      industryId: null,
      lineOfBusinessId: null,
      subAreaId: null
    });
    setDisableLob(true);
    setDisableSubArea(true);
    setSubAreaSearchFlag(true);
    setIndustriesData([]);
    setLineOfBusinessesData([]);
    setSubAreasData([]);
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true
        }
      }
    });
  };

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryDebounceCall(0);
    setIndustryIsEnd(false);
  };

  const [lineOfBusinesses] = useLazyQuery(LINE_OF_BUSINESS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLineOfBusinessIsEnd(res?.lineOfBusinesses?.data?.length < SKIP_RECORD);
      if (lineOfBusinessSearchFlag) {
        setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
      } else {
        if (isEdit) {
          if (initialLobRender) {
            const tempLineOfBusiness = [
              productItemData?.lineOfBusiness,
              ...res?.lineOfBusinesses?.data
            ];
            setLineOfBusinessesData(
              uniqBy([...lineOfBusinessesData, ...tempLineOfBusiness], 'id')
            );
          } else {
            setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
          }
          setInitialLobRender(false);
          return;
        }
        setLineOfBusinessesData([
          ...lineOfBusinessesData,
          ...res?.lineOfBusinesses?.data
        ]);
      }
      setLineOfBusinessLoading(false);
    },
    onError() {
      setLineOfBusinessLoading(false);
    }
  });

  const onLineOfBusinessScroll = (event) => {
    setLineOfBusinessSearchFlag(false);
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessIsEnd) {
        setLineOfBusinessLoading(true);
        setLineOfBusinessDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
              industryId: form?.getFieldValue('industryId')
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const handleLineOfBusinessChange = (value) => {
    setLineOfBusinessSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setLineOfBusinessLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            industryId: form?.getFieldValue('industryId'),
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setLineOfBusinessLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            industryId: form?.getFieldValue('industryId'),
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLineOfBusinessHandler = useCallback(
    debounce(handleLineOfBusinessChange, 500),
    []
  );

  const handleLineOfBusinessClear = () => {
    form?.setFieldsValue({
      lineOfBusinessId: null,
      subAreaId: null
    });
    setDisableSubArea(true);
    setSubAreaSearchFlag(true);
    setLineOfBusinessesData([]);
    setDynamicFieldData([]);
    setSubAreasData([]);
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: form?.getFieldValue('industryId')
        },
        where: {
          isActive: true
        }
      }
    });
  };

  const handleLineOfBusinessBlur = () => {
    setSearchValue('');
    setLineOfBusinessDebounceCall(0);
    setLineOfBusinessIsEnd(false);
  };

  const [subAreas] = useLazyQuery(SUB_AREA, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSubAreaIsEnd(res?.subAreas?.data?.length < SKIP_RECORD);
      if (subAreaSearchFlag) {
        setSubAreasData([...res?.subAreas?.data]);
      } else {
        if (isEdit) {
          if (initialSubAreaRender) {
            const tempSubAreas = [
              productItemData?.subArea,
              ...res?.subAreas?.data
            ];
            setSubAreasData(uniqBy([...subAreasData, ...tempSubAreas], 'id'));
          } else {
            setSubAreasData([...res?.subAreas?.data]);
          }
          setInitialSubAreaRender(false);
          return;
        }
        setSubAreasData(
          uniqBy([...subAreasData, ...res?.subAreas?.data], 'id')
        );
      }
      setSubAreaLoading(false);
    },
    onError() {
      setSubAreaLoading(false);
    }
  });

  const handleIndustrySelect = (value) => {
    setDisableLob(false);
    setLineOfBusinessesData([]);
    setDisableSubArea(true);
    if (form?.getFieldValue('lineOfBusinessId')) {
      form?.setFieldsValue({
        lineOfBusinessId: null
      });
    }
    if (form?.getFieldValue('subAreaId')) {
      form?.setFieldsValue({
        subAreaId: null
      });
    }
    lineOfBusinesses({
      variables: {
        filter: { ...lineOfBusinessFilter, industryId: value },
        where: {
          isActive: true
        }
      }
    });
  };

  const handleLobSelect = (value) => {
    setDisableSubArea(false);
    setSubAreasData([]);
    if (form?.getFieldValue('subAreaId')) {
      form?.setFieldsValue({
        subAreaId: null
      });
    }

    if (form?.getFieldValue('industryId') && value) {
      setDynamicFieldsLoading(true);
      dynamicFieldsWithListItems({
        variables: {
          where: {
            entityType: ['PRODUCT'],
            industry: [form?.getFieldValue('industryId')],
            lineOfBusiness: [value]
          }
        }
      });
    } else {
      setDynamicFieldData([]);
    }
    subAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          lobId: value,
          industryId: form?.getFieldValue('industryId')
        },
        where: {
          isActive: true
        }
      }
    });
  };

  const onSubAreaScroll = (event) => {
    setSubAreaSearchFlag(false);
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreaIsEnd) {
        setSubAreaLoading(true);
        setSubAreaDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              skip: (subAreaDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
              lobId: form?.getFieldValue('lineOfBusinessId'),
              industryId: form?.getFieldValue('industryId')
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    subAreaScrollDebounceJob();
  };

  const handleSubAreaChange = (value) => {
    setSubAreaSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setSubAreaLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            search: value,
            lobId: form?.getFieldValue('lineOfBusinessId'),
            industryId: form?.getFieldValue('industryId')
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setSubAreaLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            search: value,
            lobId: form?.getFieldValue('lineOfBusinessId'),
            industryId: form?.getFieldValue('industryId')
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubAreaHandler = useCallback(
    debounce(handleSubAreaChange, 500),
    []
  );

  const handleSubAreaClear = () => {
    form?.setFieldsValue({
      subAreaId: null
    });
    setSubAreasData([]);
    subAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          lobId: form?.getFieldValue('lineOfBusinessId'),
          industryId: form?.getFieldValue('industryId')
        },
        where: { isActive: true }
      }
    });
  };

  const handleSubAreaBlur = () => {
    setSearchValue('');
    setSubAreaDebounceCall(0);
    setSubAreaIsEnd(false);
  };

  const [manufacturers] = useLazyQuery(MANUFACTURER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setManufacturerIsEnd(res?.manufacturers?.data?.length < SKIP_RECORD);
      if (manufacturerSearchFlag) {
        setManufacturersData([...res?.manufacturers?.data]);
      } else {
        if (isEdit) {
          const tempManufacturer = [
            productItemData?.manufacturer,
            ...res?.manufacturers?.data
          ];
          setManufacturersData(
            uniqBy([...manufacturersData, ...tempManufacturer], 'id')
          );
          return;
        }
        setManufacturersData([
          ...manufacturersData,
          ...res?.manufacturers?.data
        ]);
      }
      setManufacturerLoading(false);
    },
    onError() {
      setManufacturerLoading(false);
    }
  });

  const onManufacturerScroll = (event) => {
    setManufacturerSearchFlag(false);
    if (manufacturerScrollDebounceJob) {
      manufacturerScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    manufacturerScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !manufacturerIsEnd) {
        setManufacturerLoading(true);
        setManufactureDebounceCall((prevState) => prevState + 1);
        manufacturers({
          variables: {
            filter: {
              ...manufacturerFilter,
              skip: (manufactureDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    manufacturerScrollDebounceJob();
  };

  const handleManufacturerChange = (value) => {
    setManufacturerSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setManufacturerLoading(true);
      manufacturers({
        variables: {
          filter: {
            ...manufacturerFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setManufacturerLoading(true);
      manufacturers({
        variables: {
          filter: {
            ...manufacturerFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedManufacturerHandler = useCallback(
    debounce(handleManufacturerChange, 500),
    []
  );

  const handleManufacturerClear = () => {
    form?.setFieldsValue({
      manufacturers: null
    });
    setManufacturersData([]);
    manufacturers({
      variables: {
        filter: manufacturerFilter,
        where: {
          isActive: true
        }
      }
    });
  };

  const handleManufacturerBlur = () => {
    setSearchValue('');
    setManufacturerIsEnd(false);
    setManufactureDebounceCall(0);
  };

  const [productCategories] = useLazyQuery(PRODUCT_CATEGORY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setProductCategoryIsEnd(
        res?.productCategories?.data?.length < SKIP_RECORD
      );
      if (productCategorySearchFlag) {
        setProductCategoriesData([...res?.productCategories?.data]);
      } else {
        if (isEdit && productItemData?.productCategory?.id) {
          const tempProductCategories = [
            productItemData?.productCategory,
            ...res?.productCategories?.data
          ];
          setProductCategoriesData(
            uniqBy([...productCategoriesData, ...tempProductCategories], 'id')
          );
          return;
        }
        setProductCategoriesData([
          ...productCategoriesData,
          ...res?.productCategories?.data
        ]);
      }
      setProductCategoryLoading(false);
    },
    onError() {
      setProductCategoryLoading(false);
    }
  });

  const onProductCategoryScroll = (event) => {
    setProductCategorySearchFlag(false);
    if (productCategoryScrollDebounceJob) {
      productCategoryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    productCategoryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !productCategoryIsEnd) {
        setProductCategoryLoading(true);
        setProductCategoryDebounceCall((prevState) => prevState + 1);
        productCategories({
          variables: {
            filter: {
              ...productCategoryFilter,
              skip: (productCategoryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    productCategoryScrollDebounceJob();
  };

  const handleProductCategoryChange = (value) => {
    setProductCategorySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setProductCategoryLoading(true);
      productCategories({
        variables: {
          filter: {
            ...productCategoryFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    } else {
      setProductCategoryLoading(true);
      productCategories({
        variables: {
          filter: {
            ...productCategoryFilter,
            search: value
          },
          where: {
            isActive: true
          }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProductCategoryHandler = useCallback(
    debounce(handleProductCategoryChange, 500),
    []
  );

  const handleProductCategoryClear = () => {
    form?.setFieldsValue({
      productCategories: null
    });
    setProductCategoriesData([]);
    productCategories({
      variables: {
        filter: productCategoryFilter,
        where: {
          isActive: true
        }
      }
    });
  };

  const handleProductCategoryBlur = () => {
    setSearchValue('');
    setProductCategoryDebounceCall(0);
    setProductCategoryIsEnd(false);
  };

  useEffect(() => {
    const industryId = form?.getFieldValue('industryId');
    const lineOfBusinessId = form?.getFieldValue('lineOfBusinessId');
    if (industryId && lineOfBusinessId) {
      setDynamicFieldsLoading(true);
      dynamicFieldsWithListItems({
        variables: {
          where: {
            entityType: ['PRODUCT'],
            industry: [industryId],
            lineOfBusiness: [lineOfBusinessId]
          }
        }
      });
    } else {
      setDynamicFieldData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableLob, disableSubArea]);

  useEffect(() => {
    if (location?.pathname?.includes('/edit')) {
      setDisableSubArea(false);
      setDisableLob(false);
    }

    if (productItemData?.industry?.id) {
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            industryId: productItemData?.industry?.id
          },
          where: {
            isActive: true
          }
        }
      });
    }
    if (productItemData?.lineOfBusiness?.id) {
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            lobId: productItemData?.lineOfBusiness?.id,
            industryId: productItemData?.industry?.id
          },
          where: {
            isActive: true
          }
        }
      });
    }
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true
        }
      }
    });
    manufacturers({
      variables: {
        filter: manufacturerFilter,
        where: {
          isActive: true
        }
      }
    });
    productCategories({
      variables: {
        filter: productCategoryFilter,
        where: {
          isActive: true
        }
      }
    });
    fetchProductItemsData({
      variables: {
        filter: initialProductItemsFilter
      }
    });
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true }
      }
    });
    regions({
      variables: {
        filter: regionFilter,
        where: { isActive: true }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productModalHeader = (
    <>
      <div className="create-product-modal-info">
        <span>ADD PACKAGE</span>
        <Popover
          content="Please select Region(s) & Retailers(s) from the available list. The respective product will be available within these region(s) for selected retailers(s)."
          placement="top"
          trigger="hover"
        >
          <InfoCircleOutlined />
        </Popover>
      </div>
    </>
  );

  const renderDynamicFields = (type, item) => {
    switch (type) {
      case 'TEXT':
        return (
          <RenderText
            textData={item}
            form={form}
            editData={productItemData?.dynamicFields}
          />
        );
      case 'PICK_LIST':
        return (
          <RenderSelect
            selectData={item}
            form={form}
            editData={productItemData?.dynamicFields}
          />
        );
      case 'NUMBER':
        return (
          <RenderNumber
            numberData={item}
            form={form}
            editData={productItemData?.dynamicFields}
          />
        );
      case 'BOOLEAN':
        return (
          <RenderRadio
            radioData={item}
            form={form}
            editData={productItemData?.dynamicFields}
          />
        );
      case 'UPLOAD':
        return (
          <RenderUpload
            uploadData={item}
            setPreviewVisible={setPreviewVisible}
            setPreviewTitle={setPreviewTitle}
            setPreviewImage={setPreviewImage}
            form={form}
            editData={productItemData?.dynamicFields}
          />
        );
      case 'DATE_TIME':
        return (
          <RenderDateTime
            dateTimeData={item}
            form={form}
            editData={productItemData?.dynamicFields}
          />
        );
      default:
        break;
    }
  };

  const renderSpan = (item) => {
    if (item?.fieldType === 'TEXT') {
      if (
        item?.config?.TEXT?.textType === 'MULTI_LINE' ||
        item?.config?.TEXT?.textType === 'RICH_TEXT'
      ) {
        return 24;
      }
    }
    return 12;
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => {
        dispatch({ type: 'SET_SHOW_PROMPT', data: false });
        if (name === 'productItemForm') {
          handleProductItemForm(values);
        }
        if (name === 'createProductForm') {
          handleCreateProductForm(values);
        }
      }}
    >
      <div>
        <PreviewModal
          previewImage={previewImage}
          previewTitle={previewTitle}
          previewVisible={previewVisible}
          setPreviewVisible={setPreviewVisible}
        />
        <AccessControl
          allowedPermissions={
            isEdit ? ['FET_PRODUCT_ITEM_UPDATE'] : ['FET_PRODUCT_ITEM_CREATE']
          }
        >
          <Portal portalId="header-right-content">
            <Button
              className="common-button discard-button"
              icon={<DeleteIconComponent />}
              size="small"
              id="product-item-table-discard-btn"
              disabled={isSubmit}
              onClick={() =>
                history?.push(ROUTES?.PRODUCTS, {
                  ...location?.state
                })
              }
            >
              {isEdit ? 'Discard Changes' : 'Cancel'}
            </Button>
            <Button
              className="common-button"
              icon={<img src={SaveIcon} alt="save-icon" width={12} />}
              size="small"
              htmlType="submit"
              id="product-item-table-save-btn"
              loading={isSubmit}
              type="primary"
              onClick={form?.submit}
            >
              Save
            </Button>
          </Portal>
        </AccessControl>
        <Form
          form={form}
          name="productItemForm"
          initialValues={{
            ...omit(productItemData, ['dynamicFields']),
            labourWarrantyUnitOfMeasure:
              productItemData?.labourWarrantyUnitOfMeasure || 'YEARS',
            productItemWarrantyUnitOfMeasure:
              productItemData?.productItemWarrantyUnitOfMeasure || 'YEARS',
            substituteProductItemIds:
              productItemData?.substituteProductItemIds || []
          }}
          layout="vertical"
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
          onValuesChange={() =>
            dispatch({ type: 'SET_SHOW_PROMPT', data: true })
          }
          onFinishFailed={onFinishFailed}
          scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
        >
          <fieldset
            disabled={
              !checkPermissions(
                permissions,
                isEdit
                  ? ['FET_PRODUCT_ITEM_UPDATE']
                  : ['FET_PRODUCT_ITEM_CREATE']
              )
            }
          >
            <span className="form-divider-text">MANDATORY</span>
            <Divider className="form-divider" />
            <Row gutter={16} className="required-row">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  rules={[{ ...required, message: 'Please Enter Name' }]}
                  name="name"
                  label="Name"
                >
                  <InputComponent allowClear placeholder="Enter Name" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="manufacturerId"
                  label="Manufacturer"
                  rules={[
                    {
                      required,
                      message: 'Please Select Manufacturer'
                    }
                  ]}
                >
                  <SelectComponent
                    placeholder="Select Manufacturer"
                    allowClear
                    notFoundContent={
                      manufacturerLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleManufacturerBlur}
                    onSearch={debouncedManufacturerHandler}
                    onClear={handleManufacturerClear}
                    onPopupScroll={onManufacturerScroll}
                  >
                    {map(manufacturersData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  rules={[{ ...required, message: 'Please Select SKU' }]}
                  name="sku"
                  label="SKU"
                >
                  <InputComponent allowClear placeholder="Enter SKU" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="industryId"
                  label="Industry"
                  rules={[
                    {
                      required,
                      message: 'Please Select Industry'
                    }
                  ]}
                >
                  <SelectComponent
                    placeholder="Select Industry"
                    allowClear
                    notFoundContent={
                      industryLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleIndustryBlur}
                    onSearch={debouncedIndustryHandler}
                    onClear={handleIndustryClear}
                    onPopupScroll={onIndustryScroll}
                    onSelect={handleIndustrySelect}
                  >
                    {map(industriesData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="lineOfBusinessId"
                  label="Line of Business"
                  rules={[
                    {
                      required,
                      message: 'Please Select Line Of Business'
                    }
                  ]}
                >
                  <SelectComponent
                    placeholder="Select Line Of Business"
                    allowClear
                    disabled={disableLob}
                    notFoundContent={
                      lineOfBusinessLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleLineOfBusinessBlur}
                    onSearch={debouncedLineOfBusinessHandler}
                    onClear={handleLineOfBusinessClear}
                    onPopupScroll={onLineOfBusinessScroll}
                    onSelect={handleLobSelect}
                  >
                    {map(lineOfBusinessesData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="subAreaId"
                  label="Service Type"
                  rules={[
                    {
                      required,
                      message: 'Please Select Service Type'
                    }
                  ]}
                >
                  <SelectComponent
                    placeholder="Select Service Type"
                    allowClear
                    disabled={disableSubArea}
                    notFoundContent={
                      subAreaLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleSubAreaBlur}
                    onSearch={debouncedSubAreaHandler}
                    onClear={handleSubAreaClear}
                    onPopupScroll={onSubAreaScroll}
                  >
                    {map(subAreasData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="isActive"
                  label="Status"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Status'
                    }
                  ]}
                >
                  <SelectComponent placeholder="Select Status" allowClear>
                    <Option key="active" value>
                      Active
                    </Option>
                    <Option key="inactive" value={false}>
                      InActive
                    </Option>
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Enter Cost Price'
                    }
                  ]}
                  name="costPrice"
                  label="Cost"
                >
                  <PriceComponent isPrice placeholder="Enter Cost Price" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Enter Default Price'
                    }
                  ]}
                  name="defaultPrice"
                  label="Default Price"
                >
                  <PriceComponent isPrice placeholder="Enter Default Price" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="stock"
                  label="Stock"
                  rules={[
                    number,
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Enter Stock'
                    }
                  ]}
                >
                  <NumberComponent placeholder="Enter Stock" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="score"
                  label="Score"
                  className="label-with-tooltip"
                  tooltip={{
                    title:
                      'Enter a value (0-100) in this field, where higher numbers indicate better rankings.',
                    icon: <InfoCircleOutlined />
                  }}
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Enter Score'
                    }
                  ]}
                >
                  <PriceComponent
                    prefix=""
                    decimalValue={2}
                    isAllowed={({ value }) => {
                      if (Number(value) < 0 || Number(value) > 100) {
                        return false;
                      }
                      return true;
                    }}
                    allowNegative={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  name="quoteDescription"
                  className="label-with-tooltip"
                  rules={[
                    { ...required, message: 'Please Enter Quote Description' },
                    {
                      max: WORD_LIMIT,
                      message: `Please Enter Max ${WORD_LIMIT} Characters`
                    }
                  ]}
                  label="Quote Description"
                  tooltip="This is the description visible to customers within a package as a line item."
                >
                  <InputComponent
                    showCount
                    maxLength={WORD_LIMIT}
                    placeholder="Enter Quote Description"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  className="label-with-tooltip"
                  name="unitForMeasure"
                  label="Unit Of Measure"
                  rules={[
                    { ...required, message: 'Please Enter Unit of Measure' }
                  ]}
                >
                  <SelectComponent placeholder="Select Unit of Measure">
                    {map(UNITS_OF_MEASURE, (item) => {
                      return (
                        <SelectComponent.Option
                          key={item?.value}
                          value={item?.value}
                        >
                          {item?.label}
                        </SelectComponent.Option>
                      );
                    })}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex create-product">
                  <Form.Item name="workOrderActivity" valuePropName="checked">
                    <Checkbox className="common-checkbox">
                      Create Work Order Activity
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <span className="form-divider-text">OPTIONAL</span>
            <Divider className="form-divider" />
            <Row gutter={16} className="optional-fields">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item name="description" label="Product Description">
                  <EditorComponent placeholder="Product Description goes here..." />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="productCategories" label="Product Category">
                  <SelectComponent
                    placeholder="Select Product Category"
                    allowClear
                    notFoundContent={
                      productCategoryLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleProductCategoryBlur}
                    onSearch={debouncedProductCategoryHandler}
                    onClear={handleProductCategoryClear}
                    onPopupScroll={onProductCategoryScroll}
                  >
                    {map(productCategoriesData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.title}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="productItemWarranty" label="Product Warranty">
                  <NumberComponent placeholder="Enter Warranty" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="productItemWarrantyUnitOfMeasure"
                  label="Product Warranty Unit of Measure"
                >
                  <SelectComponent
                    placeholder="Select Product Warranty UOM"
                    allowClear
                  >
                    {map(WARRANTY_UOM, (warranty) => (
                      <Option key={warranty?.value} value={warranty?.value}>
                        {warranty?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="labourWarranty" label="Labor Warranty">
                  <NumberComponent placeholder="Enter Labor Warranty" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="labourWarrantyUnitOfMeasure"
                  label="Labor Warranty Unit of Measure"
                >
                  <SelectComponent
                    placeholder="Select Labor Warranty UOM"
                    allowClear
                  >
                    {map(WARRANTY_UOM, (warranty) => (
                      <Option key={warranty?.value} value={warranty?.value}>
                        {warranty?.label}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex  create-product">
                  <Form.Item name="allowDiscount" valuePropName="checked">
                    <Checkbox className="common-checkbox">
                      Allow Discounts
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex  create-product">
                  <Form.Item name="allowCommission" valuePropName="checked">
                    <Checkbox className="common-checkbox">
                      Allow Commissions
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex  create-product">
                  <Form.Item
                    name="createMaintenanceContract"
                    valuePropName="checked"
                  >
                    <Checkbox className="common-checkbox">
                      Create Maintenance Contract
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="maintenanceContract"
                  label="Maintenance Contract"
                >
                  <InputComponent
                    allowClear
                    placeholder="Enter Maintenance Contract"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="substituteProductItemIds"
                  label="Substitute Products"
                >
                  <SelectComponent
                    placeholder="Select Substitute Products"
                    allowClear
                    mode="multiple"
                    notFoundContent={
                      productItemsLoading ? (
                        <LoaderComponent size="small" setHeight={10} />
                      ) : (
                        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onBlur={handleProductItemsBlur}
                    onSearch={debounceProductItemsHandler}
                    onClear={handleProductItemsClear}
                    onPopupScroll={onProductItemsScroll}
                  >
                    {map(productItemsData, (productItems) => (
                      <Option key={productItems?.id} value={productItems?.id}>
                        {productItems?.name}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="supplier" label="Supplier">
                  <InputComponent allowClear placeholder="Enter Supplier" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex  create-product">
                  <Form.Item name="taxable" valuePropName="checked">
                    <Checkbox className="common-checkbox">Taxable</Checkbox>
                  </Form.Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="taxCode" label="Tax Code">
                  <InputComponent allowClear placeholder="Enter Tax Code" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="vendor" label="Vendor">
                  <InputComponent allowClear placeholder="Enter Vendor" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="crmUniqueId" label="CRM Unique Id">
                  <InputComponent
                    allowClear
                    placeholder="Enter CRM Unique Id"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="crmLastRefresh" label="CRM Last Refresh">
                  <DatePickerComponent showTime use12Hours />
                </Form.Item>
              </Col>
            </Row>
            {dynamicFieldLoading ? (
              <LoaderComponent setHeight={5} />
            ) : (
              dynamicFieldData?.filter(
                (item) => !item?.config?.[item?.fieldType]?.rules?.hidden
              )?.length > 0 && (
                <>
                  <span className="form-divider-text">DYNAMIC FIELDS</span>
                  <Divider className="form-divider" />
                  <Row gutter={16}>
                    {map(dynamicFieldData, (item) => {
                      const isHidden = get(
                        item,
                        `config.[${item?.fieldType}].rules.hidden`
                      );
                      const span = renderSpan(item);
                      return (
                        <Col
                          xs={span}
                          sm={span}
                          md={span}
                          lg={span}
                          xl={span}
                          xxl={span}
                          key={item?.id}
                          hidden={isHidden}
                        >
                          {renderDynamicFields(item?.fieldType, item)}
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )
            )}
            <span className="form-divider-text">ADDITIONAL OPTIONS</span>
            <Divider className="form-divider" />
            <Row gutter={16}>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="isSellable" valuePropName="checked">
                    <Checkbox className="common-checkbox">Sellable</Checkbox>
                  </Form.Item>
                  <Popover
                    className="pointer"
                    content="This product will be available for sell if this option is selected."
                  >
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="allowPriceChange" valuePropName="checked">
                    <Checkbox className="common-checkbox">
                      Allow Price Change
                    </Checkbox>
                  </Form.Item>
                  <Popover
                    className="pointer"
                    content="if this option is selected, technicians will be able to adjust the price when they arrive at customer's location?."
                  >
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="manageInventory" valuePropName="checked">
                    <Checkbox className="common-checkbox">
                      Manage Inventory
                    </Checkbox>
                  </Form.Item>
                  <Popover
                    className="pointer"
                    content="If you select this option, inventory for this product  will be managed."
                  >
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <div className="d-flex">
                  <Form.Item name="asset" valuePropName="checked">
                    <Checkbox className="common-checkbox">Asset</Checkbox>
                  </Form.Item>
                  <Popover
                    className="pointer"
                    content="This item will be considered as an Asset for the property where it is installed if this option is selected ."
                  >
                    <img
                      src={CheckInfo}
                      alt="check-info"
                      className="optional-fields-popover"
                    />
                  </Popover>
                </div>
              </Col>
            </Row>
          </fieldset>
        </Form>
        <ModalComponent
          title={productModalHeader}
          footer={null}
          wrapClassName="create-product-modal"
          open={showCreateProductModal}
          onOk={handleCreateProduct}
          destroyOnClose
          onCancel={handleCreateProductCancel}
          width={600}
        >
          <div>
            <Form
              onValuesChange={() =>
                dispatch({ type: 'SET_SHOW_PROMPT', data: true })
              }
              layout="vertical"
              name="createProductForm"
              preserve={false}
            >
              <Row gutter={16} className="create-product-required-row">
                <Col md={24} lg={24} xl={24}>
                  <Form.Item
                    rules={[
                      {
                        required,
                        message: 'Please Select Retailer',
                        type: 'array'
                      }
                    ]}
                    name="brands"
                    label="Retailer"
                  >
                    <SelectComponent
                      placeholder="Select Retailers"
                      allowClear
                      mode="multiple"
                      notFoundContent={
                        brandLoading ? (
                          <LoaderComponent size="small" setHeight={10} />
                        ) : (
                          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                        )
                      }
                      onBlur={handleBrandBlur}
                      onSearch={debouncedBrandHandler}
                      onClear={handleBrandClear}
                      onPopupScroll={onBrandScroll}
                    >
                      {map(brandsData, (item) => (
                        <Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Option>
                      ))}
                    </SelectComponent>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required,
                        message: 'Please Select Region',
                        type: 'array'
                      }
                    ]}
                    name="regions"
                    label="Region"
                  >
                    <SelectComponent
                      placeholder="Select Regions"
                      allowClear
                      mode="multiple"
                      dropdownRender={(menu) => (
                        <>
                          <div className="create-product-select">{menu}</div>
                        </>
                      )}
                      notFoundContent={
                        regionLoading ? (
                          <LoaderComponent size="small" setHeight={10} />
                        ) : (
                          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                        )
                      }
                      onBlur={handleRegionBlur}
                      onSearch={debouncedRegionHandler}
                      onClear={handleRegionClear}
                      onPopupScroll={onRegionScroll}
                    >
                      {map(regionsData, (item) => (
                        <Option key={item?.id} value={item?.id}>
                          {item?.name}
                        </Option>
                      ))}
                    </SelectComponent>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        ...requiredWhiteSpaceAllowed,
                        message: 'Please Select Quality'
                      }
                    ]}
                    name="productQuality"
                    label="Package Quality"
                  >
                    <SelectComponent placeholder="Select Quality" allowClear>
                      <Option key="GOOD" value="GOOD">
                        Good
                      </Option>
                      <Option key="BETTER" value="BETTER">
                        Better
                      </Option>
                      <Option key="BEST" value="BEST">
                        Best
                      </Option>
                    </SelectComponent>
                  </Form.Item>
                  <div className="d-flex justify-end">
                    <Form.Item className="btn-bottom-margin">
                      <Button
                        className="common-button"
                        size="small"
                        htmlType="submit"
                        id="product-create-save-btn"
                        type="primary"
                      >
                        Add Package
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </ModalComponent>
      </div>
    </Form.Provider>
  );
};

export default ProductItemForm;
