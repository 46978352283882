import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Radio,
  Row,
  Switch
} from 'antd';
import { forEach, isEmpty, map, pull, trim, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CONDITIONS,
  CONDITIONS_KEYS,
  NUMERIC_CONDITIONS
} from '../../../common/constants';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import SearchComponent from '../../../components/SearchComponent';
import TreeComponent from '../../../components/TreeComponent';

const GlobalConditionsForm = ({
  form,
  manufacturersData,
  setIsFormValuesChanged,
  selectedManufacturers,
  setSelectedManufacturers,
  setManufacturersData,
  categoriesData,
  setCategoriesData,
  selectedCategories,
  setSelectedCategories,
  dynamicFieldsData,
  selectedListOptions,
  setSelectedListOptions,
  isManufacturerChecked,
  setIsManufacturerChecked,
  isCategoryChecked,
  setIsCategoryChecked,
  checkedFields,
  setCheckedFields,
  optionsData,
  setOptionsData,
  searchValue,
  setSearchValue,
  fetchDynamicFields,
  industryLobSaData,
  dynamicFieldDataClone,
  setDynamicFieldDataClone,
  dynamicFieldsLoading,
  setDynamicFieldsLoading,
  disabledFields,
  handleManufacturerSearch,
  handleCategorySearch
}) => {
  const manufacturerCondition = Form?.useWatch('manufacturerCondition', form);
  const categoryCondition = Form?.useWatch('categoryCondition', form);
  const booleanCondition = Form?.useWatch(
    ['dynamicFields', 'booleanCondition'],
    form
  );
  const numberCondition = Form?.useWatch(
    ['dynamicFields', 'numberCondition'],
    form
  );
  const listCondition = Form?.useWatch(
    ['dynamicFields', 'listCondition'],
    form
  );
  const [selectedList, setSelectedList] = useState(null);

  useEffect(() => {
    if (!isEmpty(selectedManufacturers)) {
      if (
        manufacturerCondition === CONDITIONS_KEYS?.EQUAL_TO ||
        manufacturerCondition === CONDITIONS_KEYS?.NOT_EQUAL_TO
      ) {
        const newManufacturers = map(manufacturersData, (item) => {
          if (item?.key !== selectedManufacturers?.[0]) {
            return { ...item, disabled: true };
          }
          return item;
        });
        setManufacturersData(newManufacturers);
      } else {
        const newManufacturers = map(manufacturersData, (item) => {
          if (item?.key !== selectedManufacturers?.[0]) {
            return { ...item, disabled: false };
          }
          return item;
        });
        setManufacturersData(newManufacturers);
      }
    } else {
      const newManufacturers = map(manufacturersData, (item) => {
        return { ...item, disabled: false };
      });
      setManufacturersData(newManufacturers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedManufacturers, manufacturerCondition]);

  useEffect(() => {
    if (!isEmpty(selectedCategories)) {
      if (
        categoryCondition === CONDITIONS_KEYS?.EQUAL_TO ||
        categoryCondition === CONDITIONS_KEYS?.NOT_EQUAL_TO
      ) {
        const newCategories = map(categoriesData, (item) => {
          if (item?.key !== selectedCategories?.[0]) {
            return { ...item, disabled: true };
          }
          return item;
        });
        setCategoriesData(newCategories);
      } else {
        const newCategories = map(categoriesData, (item) => {
          if (item?.key !== selectedCategories?.[0]) {
            return { ...item, disabled: false };
          }
          return item;
        });
        setCategoriesData(newCategories);
      }
    } else {
      const newCategories = map(categoriesData, (item) => {
        return { ...item, disabled: false };
      });
      setCategoriesData(newCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, categoryCondition]);

  useEffect(() => {
    if (!isEmpty(selectedListOptions?.[selectedList])) {
      if (
        listCondition?.[selectedList] === CONDITIONS_KEYS?.EQUAL_TO ||
        listCondition?.[selectedList] === CONDITIONS_KEYS?.NOT_EQUAL_TO
      ) {
        const newListOptions = map(optionsData?.[selectedList], (item) => {
          if (item?.key !== selectedListOptions?.[selectedList]?.[0]) {
            return { ...item, disabled: true };
          }
          return item;
        });
        setOptionsData({ ...optionsData, [selectedList]: newListOptions });
      } else {
        const newListOptions = map(optionsData?.[selectedList], (item) => {
          if (item?.key !== selectedListOptions?.[selectedList]?.[0]) {
            return { ...item, disabled: false };
          }
          return item;
        });
        setOptionsData({ ...optionsData, [selectedList]: newListOptions });
      }
    } else if (optionsData?.[selectedList]) {
      const newListOptions = map(optionsData?.[selectedList], (item) => {
        return { ...item, disabled: false };
      });
      setOptionsData({ ...optionsData, [selectedList]: newListOptions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListOptions, listCondition]);

  const handleManufacturerSelectAll = () => {
    if (selectedManufacturers?.length > 0) {
      setSelectedManufacturers([]);
    } else {
      const checkedKeysConfig = [];
      forEach(manufacturersData, (item) => {
        checkedKeysConfig?.push(item?.key);
      });
      setSelectedManufacturers(checkedKeysConfig);
    }
    setIsFormValuesChanged(true);
  };

  const handleCategorySelectAll = () => {
    if (selectedCategories?.length > 0) {
      setSelectedCategories([]);
    } else {
      const checkedKeysConfig = [];
      forEach(categoriesData, (item) => {
        checkedKeysConfig?.push(item?.key);
      });
      setSelectedCategories(checkedKeysConfig);
    }
    setIsFormValuesChanged(true);
  };

  const renderTextCondition = (id) => {
    return (
      <div>
        <p className="mb-8 ml-8 mt-8" hidden={!checkedFields?.includes(id)}>
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['dynamicFields', 'textCondition', id]}
            initialValue={CONDITIONS_KEYS?.EQUAL_TO}
            hidden={!checkedFields?.includes(id)}
          >
            <Radio.Group className="common-radio fill-width d-flex flex-vertical">
              <Row gutter={[5, 5]} className="fill-width">
                {map(CONDITIONS, (condition) => {
                  return (
                    <Col
                      xs={10}
                      sm={10}
                      md={10}
                      lg={5}
                      xl={4}
                      xxl={4}
                      key={condition?.value}
                    >
                      <Radio value={condition?.value} key={condition?.value}>
                        {condition?.label}
                      </Radio>
                    </Col>
                  );
                })}
              </Row>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Add Filtering word or phrase"
            className="mt-8 ml-12"
            name={['dynamicFields', 'textCondition', 'value', id]}
            hidden={!checkedFields?.includes(id)}
          >
            <InputComponent name="word or phrase" />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderBooleanCondition = (data) => {
    const trueValue = data?.config?.BOOLEAN?.trueValue;
    const falseValue = data?.config?.BOOLEAN?.falseValue;

    return (
      <div>
        <p
          className="mb-8 ml-8 mt-8"
          hidden={!checkedFields?.includes(data?.id)}
        >
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['dynamicFields', 'booleanCondition', data?.id]}
            initialValue="EQUAL_TO"
            hidden={!checkedFields?.includes(data?.id)}
          >
            <Radio value="EQUAL_TO" key="EQUAL_TO" checked>
              Equal to
            </Radio>
          </Form.Item>
          <Form.Item
            name={['dynamicFields', 'booleanCondition', 'value', data?.id]}
            hidden={!checkedFields?.includes(data?.id)}
          >
            <Checkbox.Group className="ml-12 d-flex">
              <Checkbox
                className="common-checkbox"
                key={trueValue}
                value={'true'}
                disabled={
                  booleanCondition?.value?.[data?.id]?.length > 0 &&
                  booleanCondition?.value?.[data?.id]?.[0] !== 'true'
                }
              >
                {trueValue}
              </Checkbox>
              <Checkbox
                className="common-checkbox"
                key={falseValue}
                value={'false'}
                disabled={
                  booleanCondition?.value?.[data?.id]?.length > 0 &&
                  booleanCondition?.value?.[data?.id]?.[0] !== 'false'
                }
              >
                {falseValue}
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderNumberCondition = (id) => {
    const isLessThanEnabled = numberCondition?.check?.[id]?.lessThan;
    const isGreaterThanEnabled = numberCondition?.check?.[id]?.greaterThan;
    return (
      <div>
        <p className="mb-8 ml-8 mt-8" hidden={!checkedFields?.includes(id)}>
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['dynamicFields', 'numberCondition', id]}
            initialValue={CONDITIONS_KEYS?.EQUAL_TO}
            hidden={!checkedFields?.includes(id)}
          >
            <Radio.Group className="common-radio fill-width d-flex flex-vertical">
              <Row gutter={[5, 5]} className="fill-width">
                {map(NUMERIC_CONDITIONS, (condition) => {
                  return (
                    <Col
                      xs={10}
                      sm={10}
                      md={10}
                      lg={5}
                      xl={4}
                      xxl={4}
                      key={condition?.value}
                    >
                      <Radio
                        value={condition?.value}
                        key={condition?.value}
                        onChange={() => {
                          form?.setFieldsValue({
                            dynamicFields: {
                              numberCondition: {
                                value: {
                                  [id]: null
                                },
                                check: {
                                  [id]: {
                                    lessThan: false,
                                    greaterThan: false
                                  }
                                }
                              }
                            }
                          });
                        }}
                      >
                        {condition?.label}
                      </Radio>
                    </Col>
                  );
                })}
              </Row>
            </Radio.Group>
          </Form.Item>
        </div>
        {numberCondition?.[id] === CONDITIONS_KEYS?.GREATER_LESS_RANGE && (
          <div className="d-flex mt-16 ml-12">
            <div>
              <div className="d-flex justify-between align-center">
                <span
                  className="switch-logo"
                  hidden={!checkedFields?.includes(id)}
                >
                  Less than
                </span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name={[
                    'dynamicFields',
                    'numberCondition',
                    'check',
                    id,
                    'lessThan'
                  ]}
                  initialValue={false}
                  hidden={!checkedFields?.includes(id)}
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={[
                  'dynamicFields',
                  'numberCondition',
                  'value',
                  id,
                  'lessThan'
                ]}
                initialValue="0"
                hidden={!checkedFields?.includes(id)}
                dependencies={[
                  ['dynamicFields', 'numberCondition', 'check', id, 'lessThan'],
                  [
                    'dynamicFields',
                    'numberCondition',
                    'value',
                    id,
                    'greaterThan'
                  ]
                ]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue([
                          'dynamicFields',
                          'numberCondition',
                          'check',
                          id,
                          'lessThan'
                        ]) &&
                        getFieldValue([
                          'dynamicFields',
                          'numberCondition',
                          'value',
                          id,
                          'greaterThan'
                        ]) &&
                        Number(value) <=
                          Number(
                            getFieldValue([
                              'dynamicFields',
                              'numberCondition',
                              'value',
                              id,
                              'greaterThan'
                            ])
                          )
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Should be greater than ${getFieldValue([
                              'dynamicFields',
                              'numberCondition',
                              'value',
                              id,
                              'greaterThan'
                            ])}`
                          )
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <NumberComponent name="number" disabled={!isLessThanEnabled} />
              </Form.Item>
            </div>
            <div className="ml-12">
              <div className="d-flex justify-between align-center">
                <span
                  className="switch-logo"
                  hidden={!checkedFields?.includes(id)}
                >
                  Greater than
                </span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name={[
                    'dynamicFields',
                    'numberCondition',
                    'check',
                    id,
                    'greaterThan'
                  ]}
                  initialValue={false}
                  hidden={!checkedFields?.includes(id)}
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={[
                  'dynamicFields',
                  'numberCondition',
                  'value',
                  id,
                  'greaterThan'
                ]}
                initialValue="0"
                hidden={!checkedFields?.includes(id)}
                dependencies={[
                  ['dynamicFields', 'numberCondition', 'value', id, 'lessThan'],
                  [
                    'dynamicFields',
                    'numberCondition',
                    'check',
                    id,
                    'greaterThan'
                  ]
                ]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue([
                          'dynamicFields',
                          'numberCondition',
                          'check',
                          id,
                          'greaterThan'
                        ]) &&
                        getFieldValue([
                          'dynamicFields',
                          'numberCondition',
                          'value',
                          id,
                          'lessThan'
                        ]) &&
                        Number(value) >=
                          Number(
                            getFieldValue([
                              'dynamicFields',
                              'numberCondition',
                              'value',
                              id,
                              'lessThan'
                            ])
                          )
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Should be less than ${getFieldValue([
                              'dynamicFields',
                              'numberCondition',
                              'value',
                              id,
                              'lessThan'
                            ])}`
                          )
                        );
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <NumberComponent
                  name="number"
                  disabled={!isGreaterThanEnabled}
                />
              </Form.Item>
            </div>
          </div>
        )}
        {(numberCondition?.[id] === CONDITIONS_KEYS?.EQUAL_TO ||
          numberCondition?.[id] === CONDITIONS_KEYS?.NOT_EQUAL_TO) && (
          <Form.Item
            label={
              numberCondition?.[id] === CONDITIONS_KEYS?.EQUAL_TO
                ? 'Equal to'
                : 'Not Equal to'
            }
            className="mt-8 ml-12 width-percent-30"
            name={['dynamicFields', 'numberCondition', 'value', id]}
            hidden={!checkedFields?.includes(id)}
          >
            <NumberComponent name="number" />
          </Form.Item>
        )}
      </div>
    );
  };

  const handleOptionsSelectAll = (id, data) => {
    if (selectedListOptions?.[id]?.length > 0) {
      setSelectedListOptions({
        ...selectedListOptions,
        [id]: null
      });
    } else {
      const optionsIds = map(data?.[id], (item) => item?.key);
      const updatedListOptions = {
        [id]: [...optionsIds]
      };
      setSelectedListOptions({
        ...selectedListOptions,
        ...updatedListOptions
      });
    }
    setIsFormValuesChanged(true);
  };

  const renderListCondition = (data) => {
    return (
      <div>
        <p
          className="mb-8 ml-8 mt-8"
          hidden={!checkedFields?.includes(data?.id)}
        >
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['dynamicFields', 'listCondition', data?.id]}
            initialValue={CONDITIONS_KEYS?.EQUAL_TO}
            hidden={!checkedFields?.includes(data?.id)}
          >
            <Radio.Group className="common-radio fill-width d-flex flex-vertical">
              <Row gutter={[5, 5]} className="fill-width">
                {map(CONDITIONS, (condition) => {
                  return (
                    <Col
                      xs={10}
                      sm={10}
                      md={10}
                      lg={5}
                      xl={4}
                      xxl={4}
                      key={condition?.value}
                    >
                      <Radio
                        value={condition?.value}
                        key={condition?.value}
                        onChange={() => {
                          setSelectedListOptions({
                            ...selectedListOptions,
                            [data?.id]: null
                          });
                          setSelectedList(data?.id);
                        }}
                      >
                        {condition?.label}
                      </Radio>
                    </Col>
                  );
                })}
              </Row>
            </Radio.Group>
          </Form.Item>
        </div>
        {(listCondition?.[data?.id] === CONDITIONS_KEYS?.CONTAINS ||
          listCondition?.[data?.id] === CONDITIONS_KEYS?.NOT_CONTAINS) &&
          optionsData?.[data?.id]?.length > 0 && (
            <span
              className="select-all-text"
              onClick={() => handleOptionsSelectAll(data?.id, optionsData)}
              hidden={!checkedFields?.includes(data?.id)}
            >
              {selectedListOptions?.[data?.id]?.length > 0
                ? 'Deselect All'
                : 'Select All'}
            </span>
          )}
        {optionsData?.[data?.id]?.length > 0 ? (
          <Form.Item
            name={['dynamicFields', 'listCondition', 'value', data?.id]}
            hidden={!checkedFields?.includes(data?.id)}
          >
            <TreeComponent
              className="panel-tree mt-8"
              data={optionsData?.[data?.id]}
              checkedKeys={selectedListOptions?.[data?.id]}
              blockNode
              titleRender={(item) => {
                return (
                  <div
                    className="d-flex justify-between"
                    key={item?.key}
                    title={item?.label}
                  >
                    <p>{item?.label}</p>
                  </div>
                );
              }}
              setCheckedKeys={setSelectedListOptions}
              onCheckParent={(value) => {
                const updatedListOptions = {
                  [data?.id]: [...value]
                };
                setSelectedListOptions({
                  ...selectedListOptions,
                  ...updatedListOptions
                });

                setIsFormValuesChanged(true);
                setSelectedList(data?.id);
              }}
            />
          </Form.Item>
        ) : (
          <Empty />
        )}
      </div>
    );
  };

  const handleSearchChange = (value) => {
    if (value) {
      setSearchValue(value);
      const filteredFields = [];
      map(dynamicFieldsData, (data) => {
        if (data?.label?.toLowerCase()?.includes(trim(value)?.toLowerCase())) {
          filteredFields?.push(data);
        }
      });
      setDynamicFieldDataClone(filteredFields);
    } else {
      setSearchValue('');
      setDynamicFieldDataClone([]);
      if (!isEmpty(dynamicFieldsData)) {
        setDynamicFieldsLoading(true);
        fetchDynamicFields({
          variables: {
            where: {
              fieldType: ['PICK_LIST', 'TEXT', 'BOOLEAN', 'NUMBER'],
              industry: [industryLobSaData?.industryId],
              lineOfBusiness: [industryLobSaData?.lineOfBusinessId]
            }
          }
        });
      }
    }
  };

  // Search function for system options
  const handleSearch = (key) => {
    if (
      searchValue &&
      key?.toLowerCase()?.includes(trim(searchValue)?.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  return (
    <Card>
      <div className="setting-content-heading">
        <h5>GLOBAL CONDITIONS</h5>
        <span className="panel-description">Select a Global conditions</span>
        <SearchComponent
          className="list-search-box filter-search mt-16"
          getData={handleSearchChange}
        />
      </div>
      <div className="setting-content">
        <div>
          <p>System Options</p>
          {(!searchValue || handleSearch('Manufacturer')) && (
            <>
              <div className="d-flex align-center justify-between ml-8 h-34">
                <h5 className="mb-0">Manufacturer</h5>
                <div className="d-flex align-center">
                  <SearchComponent
                    className="list-search-box filter-search mr-16"
                    getData={handleManufacturerSearch}
                    name="Manufacturers"
                    hidden={
                      !isManufacturerChecked && manufacturersData?.length > 0
                    }
                  />
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(e) => {
                      setIsManufacturerChecked(e);
                      setIsFormValuesChanged(true);
                    }}
                    checked={isManufacturerChecked}
                  />
                </div>
              </div>
              <p className="mb-8 ml-8 mt-8" hidden={!isManufacturerChecked}>
                Conditions
              </p>
              <div className="ml-12 mt-8" hidden={!isManufacturerChecked}>
                <Form.Item
                  name="manufacturerCondition"
                  hidden={!isManufacturerChecked}
                >
                  <Radio.Group className="common-radio fill-width d-flex">
                    <Row gutter={[5, 5]} className=" fill-width">
                      {map(CONDITIONS, (condition) => {
                        return (
                          <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={5}
                            xl={4}
                            xxl={4}
                            key={condition?.value}
                          >
                            <Radio
                              value={condition?.value}
                              key={condition?.value}
                              onChange={() => {
                                setSelectedManufacturers([]);
                              }}
                            >
                              {condition?.label}
                            </Radio>
                          </Col>
                        );
                      })}
                    </Row>
                  </Radio.Group>
                </Form.Item>
                {isManufacturerChecked && (
                  <>
                    {(manufacturerCondition === CONDITIONS_KEYS?.CONTAINS ||
                      manufacturerCondition ===
                        CONDITIONS_KEYS?.NOT_CONTAINS) &&
                      manufacturersData?.length > 0 && (
                        <span
                          className="select-all-text"
                          onClick={handleManufacturerSelectAll}
                        >
                          {selectedManufacturers?.length > 0
                            ? 'Deselect All'
                            : 'Select All'}
                        </span>
                      )}
                    {manufacturersData?.length > 0 ? (
                      <TreeComponent
                        className="panel-tree mt-10"
                        data={manufacturersData}
                        checkedKeys={selectedManufacturers}
                        blockNode
                        titleRender={(item) => {
                          return (
                            <div
                              className="d-flex justify-between"
                              key={item?.key}
                              title={item?.name}
                            >
                              <p>{item?.name}</p>
                            </div>
                          );
                        }}
                        onCheckParent={(value) => {
                          if (setSelectedManufacturers) {
                            setSelectedManufacturers(value);
                          }

                          setIsFormValuesChanged(true);
                        }}
                        setCheckedKeys={setSelectedManufacturers}
                      />
                    ) : (
                      <Empty />
                    )}
                  </>
                )}
              </div>
              <Divider className="system-options-divider" />
            </>
          )}
          {(!searchValue || handleSearch('Category')) && (
            <>
              <div className="d-flex align-center justify-between ml-8 h-34">
                <h5 className="mb-0">Category</h5>
                <div className="d-flex align-center">
                  <SearchComponent
                    className="list-search-box filter-search mr-16"
                    getData={handleCategorySearch}
                    name="Categories"
                    hidden={!isCategoryChecked && categoriesData?.length > 0}
                  />
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(e) => {
                      setIsCategoryChecked(e);
                      setIsFormValuesChanged(true);
                    }}
                    checked={isCategoryChecked}
                  />
                </div>
              </div>
              <p className="mb-8 ml-8 mt-8" hidden={!isCategoryChecked}>
                Conditions
              </p>
              <div className="ml-12 mt-8" hidden={!isCategoryChecked}>
                <Form.Item name="categoryCondition" hidden={!isCategoryChecked}>
                  <Radio.Group className="common-radio fill-width d-flex flex-vertical">
                    <Row gutter={[5, 5]} className="fill-width">
                      {map(CONDITIONS, (condition) => {
                        return (
                          <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={5}
                            xl={4}
                            xxl={4}
                            key={condition?.value}
                          >
                            <Radio
                              value={condition?.value}
                              key={condition?.value}
                              onChange={() => {
                                setSelectedCategories([]);
                              }}
                            >
                              {condition?.label}
                            </Radio>
                          </Col>
                        );
                      })}
                    </Row>
                  </Radio.Group>
                </Form.Item>
                {isCategoryChecked && (
                  <>
                    {(categoryCondition === CONDITIONS_KEYS?.CONTAINS ||
                      categoryCondition === CONDITIONS_KEYS?.NOT_CONTAINS) &&
                      categoriesData?.length > 0 && (
                        <span
                          className="select-all-text"
                          onClick={handleCategorySelectAll}
                        >
                          {selectedCategories?.length > 0
                            ? 'Deselect All'
                            : 'Select All'}
                        </span>
                      )}
                    {categoriesData?.length > 0 ? (
                      <TreeComponent
                        className="panel-tree mt-10"
                        data={categoriesData}
                        checkedKeys={selectedCategories}
                        blockNode
                        titleRender={(item) => {
                          return (
                            <div
                              className="d-flex justify-between"
                              key={item?.key}
                              title={item?.name}
                            >
                              <p>{item?.name}</p>
                            </div>
                          );
                        }}
                        onCheckParent={(value) => {
                          if (setSelectedCategories) {
                            setSelectedCategories(value);
                          }

                          setIsFormValuesChanged(true);
                        }}
                        setCheckedKeys={setSelectedCategories}
                      />
                    ) : (
                      <Empty />
                    )}
                  </>
                )}
              </div>
              <Divider className="system-options-divider" />
            </>
          )}
          {searchValue &&
            // in future if label of system option changes, change here
            !handleSearch('Category') &&
            !handleSearch('Manufacturer') && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="m-0" />
            )}
        </div>
        <div className="mt-24 d-flex align-center justify-between mb-16">
          <p className=" mb-0">
            Dynamic Fields
            {!isEmpty(disabledFields) &&
              ' (Some Fields are disabled as pages have been created for the respective fields)'}
          </p>
        </div>
        {!dynamicFieldsLoading && isEmpty(dynamicFieldDataClone) && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="m-0" />
        )}
        {dynamicFieldsLoading && <LoaderComponent setHeight={10} />}
        {map(dynamicFieldDataClone, (item) => {
          return (
            <div key={item?.id}>
              <div className="d-flex align-center justify-between ml-8">
                <h5 className="mb-0">{item?.label}</h5>
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={() => {
                    if (checkedFields?.includes(item?.id)) {
                      pull(checkedFields, item?.id);
                      setCheckedFields([...checkedFields]);
                    } else {
                      setCheckedFields(uniq([...checkedFields, item?.id]));
                    }
                    setIsFormValuesChanged(true);
                  }}
                  checked={checkedFields?.includes(item?.id)}
                  disabled={disabledFields?.includes(item?.id)}
                />
              </div>
              {item?.fieldType === 'TEXT' && renderTextCondition(item?.id)}
              {item?.fieldType === 'NUMBER' && renderNumberCondition(item?.id)}
              {item?.fieldType === 'BOOLEAN' && renderBooleanCondition(item)}
              {item?.fieldType === 'PICK_LIST' && renderListCondition(item)}
              <Divider className="global-divider" />
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default GlobalConditionsForm;
