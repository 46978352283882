import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Layout, Menu, message } from 'antd';
import {
  flattenDeep,
  forIn,
  isArray,
  isEmpty,
  kebabCase,
  map,
  omit,
  trim
} from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import {
  CONDITIONS_KEYS,
  MODAL_WIDTH,
  QUESTIONNAIRE_TYPE,
  ROUTES
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import history from '../../../historyData';
import {
  CREATE_QUESTIONNAIRES,
  UPDATE_QUESTIONNAIRES
} from '../graphql/Mutations';
import {
  CPQ_DYNAMIC_FIELDS,
  CPQ_INDUSTRIES,
  CPQ_MANUFACTURERS,
  FETCH_CPQ_CATEGORIES,
  GET_QUESTIONNAIRE,
  GET_USED_SYSTEM_FIELDS
} from '../graphql/Queries';
import CompareQuoteVariants from './CompareQuoteVariants';
import GlobalConditionsForm from './GlobalConditionsForm';
import SecondaryGeneralForm from './SecondaryGeneralForm';

const { Sider, Content, Footer } = Layout;
const SecondarySettingModal = ({
  showModal,
  setShowModal,
  match: { params: { id } = {} } = {},
  refetchData,
  refetchQuestionnaire,
  setRefetchSystemFields
}) => {
  const [form] = Form?.useForm();
  const compareConfig = Form?.useWatch(['compareConfig'], form);
  const { dispatch } = useContext(AppContext);
  const [activeKey, setActiveKey] = useState('general');
  const [industries, setIndustries] = useState([]);
  const [industriesLoading, setIndustriesLoading] = useState(true);
  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [manufacturerLoading, setManufacturerLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [dynamicFieldsData, setDynamicFieldsData] = useState([]);
  const [dynamicFieldDataClone, setDynamicFieldDataClone] = useState(
    dynamicFieldsData
  );
  const [selectedListOptions, setSelectedListOptions] = useState(null);
  const [optionsData, setOptionsData] = useState({});
  const [isManufacturerChecked, setIsManufacturerChecked] = useState(false);
  const [isCategoryChecked, setIsCategoryChecked] = useState(false);
  const [checkedFields, setCheckedFields] = useState([]);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dynamicFieldEditData, setDynamicFieldEditData] = useState({});
  const [initialValueSet, setInitialValueSet] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [dynamicFieldsLoading, setDynamicFieldsLoading] = useState(true);
  const [disabledFields, setDisabledFields] = useState([]);
  const [initialValue, setInitialValue] = useState({
    isActive: true,
    categoryCondition: CONDITIONS_KEYS?.EQUAL_TO,
    manufacturerCondition: CONDITIONS_KEYS?.EQUAL_TO
  });
  const [industryLobSaData, setIndustryLobSaData] = useState({
    industryId: null,
    lineOfBusinessId: null,
    subAreaId: null
  });
  const [compareConfigObj, setCompareConfig] = useState(null);

  const onMenuSelect = (e) => {
    setActiveKey(e?.key);
  };

  const onCancel = () => {
    setShowModal(false);
    setActiveKey('general');
  };

  const handleSubmit = () => {
    form?.submit();
  };

  const [fetchUsedSystemFields] = useLazyQuery(GET_USED_SYSTEM_FIELDS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDisabledFields(res?.usedSystemField?.systemField);
      setLoading(false);
    },
    onError() {}
  });

  const [getQuestionnaire] = useLazyQuery(GET_QUESTIONNAIRE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const initialValueObj = {
        title: res?.questionnaire?.title,
        description: res?.questionnaire?.description,
        isActive: res?.questionnaire?.isActive,
        manufacturerCondition:
          res?.questionnaire?.globalCondition?.manufacturer?.condition,
        categoryCondition:
          res?.questionnaire?.globalCondition?.category?.condition
      };
      const questionObj = {
        industry: res?.questionnaire?.industry?.label,
        lineOfBusinesses: res?.questionnaire?.lineOfBusinesses?.label
      };

      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT', data: questionObj });

      setInitialValue(initialValueObj);
      setCompareConfig(res?.questionnaire?.compareConfig);
      setIndustryLobSaData({
        industryId: res?.questionnaire?.industryId,
        lineOfBusinessId: res?.questionnaire?.lineOfBusinessId,
        subAreaId: res?.questionnaire?.subAreaId
      });
      setIsManufacturerChecked(
        res?.questionnaire?.globalCondition?.manufacturer?.check
      );
      setSelectedManufacturers(
        res?.questionnaire?.globalCondition?.manufacturer?.value
      );
      setIsCategoryChecked(
        res?.questionnaire?.globalCondition?.category?.check
      );
      setSelectedCategories(
        res?.questionnaire?.globalCondition?.category?.value
      );
      const checkedIds = [];
      let listOptions = {};
      map(res?.questionnaire?.globalCondition?.dynamicFields, (field) => {
        if (field?.check === true) {
          checkedIds?.push(field?.identifier);
        }
        if (field?.type === 'TEXT') {
          form?.setFieldsValue({
            dynamicFields: {
              textCondition: {
                [field?.identifier]: field?.condition,
                value: {
                  [field?.identifier]: field?.value
                }
              }
            }
          });
        }
        if (field?.type === 'BOOLEAN') {
          form?.setFieldsValue({
            dynamicFields: {
              booleanCondition: {
                [field?.identifier]: field?.condition,
                value: {
                  [field?.identifier]: field?.value
                }
              }
            }
          });
        }
        if (field?.type === 'PICK_LIST') {
          form?.setFieldsValue({
            dynamicFields: {
              listCondition: {
                [field?.identifier]: field?.condition
              }
            }
          });
          if (!isEmpty(field?.value)) {
            setSelectedListOptions({
              ...selectedListOptions,
              [field?.identifier]: field?.value
            });
          }
          if (
            (field?.condition === CONDITIONS_KEYS?.EQUAL_TO ||
              field?.condition === CONDITIONS_KEYS?.NOT_EQUAL_TO) &&
            !isEmpty(field?.value)
          ) {
            listOptions = {
              ...listOptions,
              [field?.identifier]: field?.value
            };
          }
        }
        if (field?.type === 'NUMBER') {
          form?.setFieldsValue({
            dynamicFields: {
              numberCondition: {
                [field?.identifier]: field?.condition,
                ...(field?.condition !==
                  CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
                  value: {
                    [field?.identifier]: field?.value
                  }
                }),
                ...(field?.condition ===
                  CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
                  check: {
                    [field?.identifier]: {
                      lessThan: field?.lessThan?.check,
                      greaterThan: field?.greaterThan?.check
                    }
                  },
                  value: {
                    [field?.identifier]: {
                      lessThan: field?.lessThan?.value,
                      greaterThan: field?.greaterThan?.value
                    }
                  }
                })
              }
            }
          });
        }
      });
      setDynamicFieldEditData(listOptions);
      setCheckedFields(checkedIds);
      fetchUsedSystemFields({
        variables: {
          where: {
            questionnaireId: id
          }
        }
      });
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (!isEmpty(optionsData) && id && initialValueSet) {
      map(dynamicFieldEditData, (value, key) => {
        const newListOptions = map(optionsData?.[key], (item) => {
          if (item?.key !== value?.[0]) {
            return { ...item, disabled: true };
          }
          return item;
        });
        setOptionsData({
          ...optionsData,
          [key]: newListOptions
        });
        setInitialValueSet(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsData, dynamicFieldEditData]);

  useEffect(() => {
    setDynamicFieldDataClone(dynamicFieldsData);
  }, [dynamicFieldsData]);

  useEffect(() => {
    if (industryLobSaData?.industryId && !industryLobSaData?.lineOfBusinessId) {
      setDynamicFieldsData([]);
    }
  }, [industryLobSaData]);

  const [fetchCpqDynamicFields] = useLazyQuery(CPQ_DYNAMIC_FIELDS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDynamicFieldsData(res?.dynamicFieldsWithListItems);
      setDynamicFieldDataClone(res?.dynamicFieldsWithListItems);
      const optionsList = {};
      const labels = [];
      map(res?.dynamicFieldsWithListItems, (item) => {
        labels?.push(item?.label);
        if (item?.fieldType === 'PICK_LIST') {
          map(item?.dynamicFieldListItems, (option) => {
            optionsList[item?.id] = flattenDeep([
              flattenDeep(optionsList[item?.id]),
              {
                key: option?.id,
                label: option?.label,
                fieldId: option?.fieldId
              }
            ]);
          });
        }
      });
      setOptionsData(optionsList);
      setDynamicFieldsLoading(false);
    },
    onError() {
      setDynamicFieldsLoading(false);
    }
  });

  const [fetchCpqIndustries] = useLazyQuery(CPQ_INDUSTRIES, {
    variables: {
      filter: {
        skip: 0
      },
      where: {
        isActive: [true]
      }
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const industry = res?.industries?.data?.[0];
      const lob = industry?.lobSaData?.[0];
      const sa = lob?.subAreas?.[0];
      setIndustries(res?.industries?.data);
      setIndustryLobSaData({
        industryId: industry?.id,
        lineOfBusinessId: lob?.id,
        subAreaId: sa?.id
      });
      if (id) {
        setLoading(true);
        getQuestionnaire({
          variables: {
            id
          }
        });
      }
      setIndustriesLoading(false);
    },
    onError() {
      setIndustriesLoading(false);
    }
  });

  const [fetchCpqManufacturers] = useLazyQuery(CPQ_MANUFACTURERS, {
    variables: {
      filter: {
        skip: 0
      },
      where: {
        isActive: [true]
      }
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const newManufacturerObj = map(
        res?.manufacturers?.data,
        (manufacturer) => {
          return {
            key: manufacturer?.id,
            name: manufacturer?.name
          };
        }
      );
      setManufacturers(newManufacturerObj);
      setManufacturerLoading(false);
    },
    onError() {
      setManufacturerLoading(false);
    }
  });

  const [fetchCpqCategories] = useLazyQuery(FETCH_CPQ_CATEGORIES, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        skip: 0
      },
      where: {
        isActive: [true]
      }
    },
    onCompleted(res) {
      const newCategoriesObj = map(res?.productCategories?.data, (category) => {
        return {
          key: category?.id,
          name: category?.title
        };
      });
      setCategoriesData(newCategoriesObj);
      setCategoriesLoading(false);
    },
    onError() {
      setCategoriesLoading(false);
    }
  });

  const [createQuestionnaire] = useMutation(CREATE_QUESTIONNAIRES, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const [updateQuestionnaire] = useMutation(UPDATE_QUESTIONNAIRES, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  useEffect(() => {
    fetchCpqIndustries();
    fetchCpqManufacturers();
    fetchCpqCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (industryLobSaData?.industryId && industryLobSaData?.lineOfBusinessId) {
      fetchCpqDynamicFields({
        variables: {
          where: {
            fieldType: ['PICK_LIST', 'TEXT', 'BOOLEAN', 'NUMBER'],
            industry: [industryLobSaData?.industryId],
            lineOfBusiness: [industryLobSaData?.lineOfBusinessId]
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryLobSaData]);

  const onFinish = async (formValues) => {
    let hasError = false;
    if (
      (isManufacturerChecked && isEmpty(selectedManufacturers)) ||
      (isCategoryChecked && isEmpty(selectedCategories))
    ) {
      message?.error('Provide proper values for global conditions');
      return;
    }
    if (industryLobSaData?.industryId && !industryLobSaData?.lineOfBusinessId) {
      message?.error('Please provide Line of Business');
      return;
    }
    setSubmitLoading(true);
    const uuid = formValues?.uuid || uuidv4();
    const getFieldValues = {};
    const finalDynamicFields = [];
    const getCheckedValues = {};
    let fieldType;

    map(formValues?.dynamicFields, (item, fieldKey) => {
      if (fieldKey === 'listCondition') {
        fieldType = 'PICK_LIST';
      } else if (fieldKey === 'numberCondition') {
        fieldType = 'NUMBER';
      } else if (fieldKey === 'booleanCondition') {
        fieldType = 'BOOLEAN';
      } else {
        fieldType = 'TEXT';
      }
      if (item?.value) {
        forIn(item?.value, (value, key) => {
          getFieldValues[key] = value;
        });
      }
      if (item?.check) {
        forIn(item?.check, (value, key) => {
          getCheckedValues[key] = value;
        });
      }
      map(item, (data, itemKey) => {
        if (
          checkedFields?.includes(itemKey) &&
          fieldKey !== 'listCondition' &&
          (!getFieldValues?.[itemKey] ||
            isEmpty(getFieldValues?.[itemKey]) ||
            (data === CONDITIONS_KEYS?.GREATER_LESS_RANGE &&
              !getCheckedValues?.[itemKey]?.greaterThan &&
              !getCheckedValues?.[itemKey]?.lessThan))
        ) {
          message?.destroy();
          message?.error('Provide proper values for global conditions');
          hasError = true;
          return;
        }
        if (
          checkedFields?.includes(itemKey) &&
          fieldKey === 'listCondition' &&
          (!selectedListOptions?.[itemKey] ||
            isEmpty(selectedListOptions?.[itemKey]))
        ) {
          message?.destroy();
          message?.error('Provide proper values for global conditions');
          hasError = true;
          return;
        }
        if (
          (getCheckedValues?.[itemKey]?.greaterThan &&
            !getFieldValues?.[itemKey]?.greaterThan) ||
          (getCheckedValues?.[itemKey]?.lessThan &&
            !getFieldValues?.[itemKey]?.lessThan)
        ) {
          message?.destroy();
          message?.error('Provide proper values for selected pages');
          hasError = true;
        }
        let fieldValue;
        if (getFieldValues[itemKey]) {
          if (isArray(getFieldValues[itemKey])) {
            fieldValue = getFieldValues?.[itemKey];
          } else {
            fieldValue = [getFieldValues?.[itemKey]];
          }
        } else if (fieldKey === 'listCondition') {
          fieldValue = selectedListOptions?.[itemKey];
        } else {
          fieldValue = [];
        }
        if (itemKey !== 'value' && itemKey !== 'check') {
          finalDynamicFields?.push({
            identifier: itemKey,
            condition: data,
            ...(!(data === CONDITIONS_KEYS?.GREATER_LESS_RANGE) && {
              value: fieldValue
            }),
            check: checkedFields?.includes(itemKey),
            ...(data === CONDITIONS_KEYS?.GREATER_LESS_RANGE && {
              greaterThan: {
                check: getCheckedValues?.[itemKey]?.greaterThan,
                value: getFieldValues?.[itemKey]?.greaterThan
              },
              lessThan: {
                check: getCheckedValues?.[itemKey]?.lessThan,
                value: getFieldValues?.[itemKey]?.lessThan
              }
            }),
            type: fieldType
          });
        }
      });
    });
    if (hasError) {
      setSubmitLoading(false);
      return;
    }

    let variables = {
      ...formValues,
      industryId: industryLobSaData?.industryId,
      lineOfBusinessId: industryLobSaData?.lineOfBusinessId,
      subAreaId: industryLobSaData?.subAreaId,
      uuid,
      type: QUESTIONNAIRE_TYPE?.ROQ_PATH,
      globalCondition: {
        manufacturer: {
          condition: formValues?.manufacturerCondition,
          value: selectedManufacturers,
          check: isManufacturerChecked
        },
        category: {
          condition: formValues?.categoryCondition,
          value: selectedCategories,
          check: isCategoryChecked
        },
        dynamicFields: finalDynamicFields
      }
    };
    variables = omit(variables, [
      'categoryCondition',
      'manufacturerCondition',
      'numberCondition',
      'dynamicFields'
    ]);
    try {
      if (id) {
        const response = await updateQuestionnaire({
          variables: {
            data: variables,
            where: {
              id
            }
          }
        });
        if (response?.data?.updateQuestionnaire) {
          setRefetchSystemFields(true);
          setShowModal(false);
          setActiveKey('general');
          setSubmitLoading(false);
          if (refetchData) {
            refetchData();
          }
          if (refetchQuestionnaire && id) {
            refetchQuestionnaire();
          }
        }
      } else {
        const response = await createQuestionnaire({
          variables: {
            data: variables
          }
        });
        if (response?.data?.createQuestionnaire) {
          setSubmitLoading(false);
          history?.push(
            `${ROUTES?.QUESTIONNAIRES_SECONDARY}/${
              response?.data?.createQuestionnaire?.data?.id
            }${ROUTES?.PAGE}/${
              response?.data?.createQuestionnaire?.data?.pageKey
                ? kebabCase(response?.data?.createQuestionnaire?.data?.pageKey)
                : 'create'
            }?sp=false`
          );
        }
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const onFinishFailed = (values) => {
    setActiveKey(
      values?.errorFields?.[0].name?.[0] !== 'compareConfig'
        ? 'general'
        : 'compare-quote-variants'
    );
    setValidationTriggered(true);
  };

  const handleManufacturerSearch = (value) => {
    fetchCpqManufacturers({
      variables: {
        filter: {
          skip: 0,
          search: trim(value)
        },
        where: {
          isActive: [true]
        }
      }
    });
  };

  const handleCategorySearch = (value) => {
    fetchCpqCategories({
      variables: {
        filter: {
          skip: 0,
          search: trim(value)
        },
        where: {
          isActive: [true]
        }
      }
    });
  };

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      open={showModal}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      wrapClassName="setting-modal"
    >
      <Layout>
        <Sider className="setting-layout">
          <div className="sider-header">
            <span>SETTINGS</span>
          </div>
          <Menu
            items={[
              { key: 'general', label: 'General' },
              { key: 'global-conditions', label: 'Global Conditions' },
              { key: 'compare-quote-variants', label: 'Compare Quote Variants' }
            ]}
            theme="light"
            selectedKeys={activeKey}
            onSelect={onMenuSelect}
          />
        </Sider>
        <Layout className="setting-modal-content">
          <Content>
            <div className="content-wrapper">
              {industriesLoading ||
              manufacturerLoading ||
              categoriesLoading ||
              loading ? (
                <LoaderComponent setHeight={75} />
              ) : (
                <Form
                  layout="vertical"
                  onFinish={onFinish}
                  form={form}
                  onValuesChange={() => {
                    setIsFormValuesChanged(true);
                  }}
                  validateTrigger={
                    validationTriggered ? 'onChange' : 'onSubmit'
                  }
                  onFinishFailed={onFinishFailed}
                  initialValues={initialValue}
                >
                  <div className={activeKey === 'general' ? '' : 'hide-form'}>
                    <SecondaryGeneralForm
                      industriesData={industries}
                      industryLobSaData={industryLobSaData}
                      setIndustryLobSaData={setIndustryLobSaData}
                      setIsFormValuesChanged={setIsFormValuesChanged}
                      setCheckedFields={setCheckedFields}
                      id={id}
                    />
                  </div>
                  <div
                    className={
                      activeKey === 'global-conditions' ? '' : 'hide-form'
                    }
                  >
                    <GlobalConditionsForm
                      manufacturersData={manufacturers}
                      setIsFormValuesChanged={setIsFormValuesChanged}
                      form={form}
                      selectedManufacturers={selectedManufacturers}
                      setSelectedManufacturers={setSelectedManufacturers}
                      setManufacturersData={setManufacturers}
                      categoriesData={categoriesData}
                      setCategoriesData={setCategoriesData}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      dynamicFieldsData={dynamicFieldsData}
                      selectedListOptions={selectedListOptions}
                      setSelectedListOptions={setSelectedListOptions}
                      isManufacturerChecked={isManufacturerChecked}
                      setIsManufacturerChecked={setIsManufacturerChecked}
                      isCategoryChecked={isCategoryChecked}
                      setIsCategoryChecked={setIsCategoryChecked}
                      checkedFields={checkedFields}
                      setCheckedFields={setCheckedFields}
                      optionsData={optionsData}
                      setOptionsData={setOptionsData}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      fetchDynamicFields={fetchCpqDynamicFields}
                      industryLobSaData={industryLobSaData}
                      dynamicFieldDataClone={dynamicFieldDataClone}
                      setDynamicFieldDataClone={setDynamicFieldDataClone}
                      dynamicFieldsLoading={dynamicFieldsLoading}
                      setDynamicFieldsLoading={setDynamicFieldsLoading}
                      disabledFields={disabledFields}
                      handleManufacturerSearch={handleManufacturerSearch}
                      handleCategorySearch={handleCategorySearch}
                    />
                  </div>
                  <div
                    className={
                      activeKey === 'compare-quote-variants' ? '' : 'hide-form'
                    }
                  >
                    <CompareQuoteVariants
                      fetchDynamicFields={fetchCpqDynamicFields}
                      industryLobSaData={industryLobSaData}
                      dynamicFieldsData={dynamicFieldsData}
                      compareConfig={compareConfig}
                      setValidationTriggered={setValidationTriggered}
                      form={form}
                      compareConfigObj={compareConfigObj}
                    />
                  </div>
                </Form>
              )}
            </div>
          </Content>
          <Footer>
            <Button
              className="setting-btn fill-width"
              size="small"
              htmlType="submit"
              id="setting-save-btn"
              loading={submitLoading}
              type="primary"
              onClick={handleSubmit}
              disabled={!isFormValuesChanged}
            >
              {id ? 'Update Questionnaire' : 'Create Questionnaire'}
            </Button>
          </Footer>
        </Layout>
      </Layout>
    </ModalComponent>
  );
};

export default withRouter(SecondarySettingModal);
