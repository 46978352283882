import { gql } from '@apollo/client';

export const GET_PRODUCT_ITEMS = gql`
  query productItems(
    $filter: ProductItemFilter!
    $where: ProductItemsWhereFilter
  ) {
    productItems(filter: $filter, where: $where) {
      count
      data {
        id
        sku
        uuid
        name
        isActive
        stock
        manufacturer {
          id
          name
        }
        manufacturerId
        industry {
          id
          label
        }
        industryId
        lineOfBusiness {
          id
          label
        }
        lineOfBusinessId
        subArea {
          id
          label
        }
        subAreaId
        costPrice
        defaultPrice
        quoteDescription
        workOrderActivity
      }
    }
  }
`;

export const GET_PRODUCT_SUB_ITEMS = gql`
  query productSubItems($filter: ProductSubItemFilter!) {
    productSubItems(filter: $filter) {
      count
      data {
        id
        productId
        productItemId
        order
        productItems {
          id
          name
          sku
        }
        manufacturer {
          id
          name
        }
        lineOfBusiness {
          label
        }
        subArea {
          label
        }
        industry {
          label
        }
        quantity
        costPrice
        defaultPrice
        isPrimary
      }
    }
  }
`;

export const GET_PRODUCT_ITEM = gql`
  query productItem($id: ID!) {
    productItem(where: { id: $id }) {
      id
      name
      productItemCode
      refData
      description
      quoteDescription
      sku
      uuid
      lineOfBusinessId
      subAreaId
      industryId
      manufacturer {
        id
        name
      }
      lineOfBusiness {
        id
        label
      }
      subArea {
        id
        label
      }
      industry {
        id
        label
      }
      productCategory {
        id
        title
      }
      defaultPrice
      manufacturerId
      productCategoryId
      costPrice
      isActive
      isSellable
      unitForMeasure
      manageInventory
      productItemWarranty
      labourWarranty
      options
      allowPriceChange
      asset
      stock
      tenantId
      crmUniqueId
      crmLastRefresh
      labourWarrantyUnitOfMeasure
      productItemWarrantyUnitOfMeasure
      substituteProductItemIds
      taxable
      taxCode
      createMaintenanceContract
      allowDiscount
      allowCommission
      maintenanceContract
      supplier
      vendor
      workOrderActivity
      dynamicFields
      score
    }
  }
`;

export const PRODUCT_ITEM_FILTER = gql`
  query productItemFilters($filter: ProductItemFilter!) {
    productItemFilters(filter: $filter) {
      count
      data {
        name
        productItemCode
        refData
        description
        quoteDescription
        sku
        lineOfBusinessId
        manufacturer {
          id
          name
        }
        lineOfBusiness {
          id
          label
        }
        subArea {
          id
          label
        }
        subAreaId
        industry {
          id
          label
        }
        industryId
        defaultPrice
        manufacturerId
        productCategoryId
        costPrice
        isActive
        isSellable
        unitForMeasure
        manageInventory
        productItemWarranty
        labourWarranty
        options
        allowPriceChange
        asset
        tenantId
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        label
        alias
        industryCode
        refData
        key
        tenantId
        description
        isActive
        referenceId
        id
      }
    }
  }
`;

export const LINE_OF_BUSINESS = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const SUB_AREA = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
    }
  }
`;

export const MANUFACTURER = gql`
  query manufacturers(
    $filter: ManufacturerFilter!
    $where: ManufacturersWhereFilter
  ) {
    manufacturers(filter: $filter, where: $where) {
      count
      data {
        id
        name
        manufacturerCode
        refData
        contactNumber
        isActive
        uuid
        certification
        fax
        url
        tenantId

        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const PRODUCT_CATEGORY = gql`
  query productCategories(
    $filter: ProductCategoryFilter!
    $where: ProductCategoriesWhereFilter
  ) {
    productCategories(filter: $filter, where: $where) {
      count
      data {
        id
        title
        productCategoryCode
        refData
        description
        isActive
        tenantId
      }
    }
  }
`;

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const SUB_AREA_FILTER = gql`
  query subAreaFilters($filter: SubAreaFilter!) {
    subAreaFilters(filter: $filter) {
      count
      data {
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
    }
  }
`;

export const MANUFACTURER_FILTER = gql`
  query manufacturerFilters($filter: ManufacturerFilter!) {
    manufacturerFilters(filter: $filter) {
      count
      data {
        name
        manufacturerCode
        refData
        contactNumber
        isActive
        uuid
        certification
        fax
        url
        tenantId
        logo {
          url
          key
          name
          extension
          contentType
        }
      }
    }
  }
`;

export const PRODUCT_CATEGORY_FILTER = gql`
  query productCategoryFilters($filter: ProductCategoryFilter!) {
    productCategoryFilters(filter: $filter) {
      count
      data {
        title
        productCategoryCode
        refData
        description
        isActive
        tenantId
      }
    }
  }
`;

export const FETCH_HEIGHTS = gql`
  query heights($filter: HeightFilter!, $where: HeightWhereFilter) {
    heights(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        description
        order
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const GET_DYNAMIC_FIELDS_WITH_LIST_ITEMS = gql`
  query dynamicFieldsWithListItems($where: DynamicFieldWhereFilterData!) {
    dynamicFieldsWithListItems(where: $where) {
      id
      tenantId
      industryId
      fieldType
      key
      sortOrder
      lineOfBusinessIds
      label
      tooltip
      status
      image {
        url
        key
        name
        extension
        contentType
      }
      uuid
      config
      placeholder
      description
      lineOfBusinesses {
        id
        label
      }
      industry {
        id
        label
      }
      dynamicFieldListItems {
        id
        fieldId
        image {
          url
          key
          name
          extension
          contentType
        }
        uuid
        label
        value
        default
        isActive
        description
      }
    }
  }
`;
