import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Alert, Collapse, Empty, Form, Radio, Switch } from 'antd';
import { isEmpty, map, omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import {
  DYNAMIC_FIELDS_DATA_KEYS,
  PICK_LIST_STYLE
} from '../../../../../../common/constants';
import { formValidatorRules } from '../../../../../../common/utils';
import InputComponent from '../../../../../../components/InputComponent';
import LoaderComponent from '../../../../../../components/LoaderComponent';
import SelectComponent from '../../../../../../components/SelectComponent';
import { GET_DYNAMIC_FIELDS } from '../../../../../dynamicFields/graphql/Queries';
import { GET_SYSTEM_FIELD_CONFIG } from '../../../../graphql/Queries';
import SystemBooleanForm from '../component/boolean/form';
import SystemNumberFieldForm from '../component/number/form';
import SystemPickListForm from '../component/pickList/form';
import SystemTextFieldForm from '../component/text/form';

const { Option } = SelectComponent;
const { Panel } = Collapse;
const { requiredWhiteSpaceAllowed } = formValidatorRules;

const SystemWidgetForm = ({
  form,
  fieldObject,
  setFieldObject,
  systemFieldData,
  setSystemFieldData,
  refetchSystemFields = false,
  setRefetchSystemFields,
  setDisableBtn
}) => {
  const { id } = useParams();
  const location = useLocation();
  const isCreate = location?.pathname?.includes('create');
  const { state, dispatch } = useContext(AppContext);

  const [activeKey, setActiveKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(false);
  const [dynamicFieldsData, setDynamicFieldsData] = useState(null);
  const selectedSystemFieldWidget = Form?.useWatch(
    [
      'widgetConfiguration',
      'selectedSystemFieldBlock',
      'selectedSystemFieldId'
    ],
    form
  );

  const showCtaButton = Form?.useWatch('showCtaButton', form);

  useEffect(() => {
    if (!showCtaButton) {
      form?.setFieldsValue({
        widgetConfiguration: {
          multiSelection: false,
          hideContinueButton: false
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCtaButton]);

  const [dynamicFields] = useLazyQuery(GET_DYNAMIC_FIELDS, {
    fetchPolicy: 'network-only',
    onError() {
      setLoading(false);
      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT_LOADING', data: false });
    },
    onCompleted(res) {
      setDynamicFieldsData(res?.dynamicFields?.data);
      setRefetchSystemFields(false);
      setLoading(false);
      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT_LOADING', data: false });
    }
  });

  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'description'],
    form
  );

  const isMultiSelectionEnabled = Form?.useWatch(
    ['widgetConfiguration', 'multiSelection'],
    form
  );

  const hideContinueButton = Form?.useWatch(
    ['widgetConfiguration', 'hideContinueButton'],
    form
  );

  const [fetchSystemFieldConfig] = useLazyQuery(GET_SYSTEM_FIELD_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      form?.setFieldsValue({
        widgetConfiguration: {
          displayStyle:
            res?.getSystemFieldConfiguration?.data?.config?.displayStyle,
          images: res?.getSystemFieldConfiguration?.data?.config?.images,
          imagePreview:
            res?.getSystemFieldConfiguration?.data?.config?.imagePreview,
          optionDescriptions:
            res?.getSystemFieldConfiguration?.data?.config?.optionDescriptions,
          multiSelection:
            res?.getSystemFieldConfiguration?.data?.config?.multiSelection,
          description:
            res?.getSystemFieldConfiguration?.data?.config?.description,
          descriptionText:
            res?.getSystemFieldConfiguration?.data?.config?.descriptionText,
          otherConfig: map(
            res?.getSystemFieldConfiguration?.data?.config?.otherConfig,
            (item) => {
              const newItem = {
                ...item,
                blockDescription: item?.description
              };
              if (!newItem?.image?.url) {
                return omit(newItem, ['image']);
              }
              return {
                ...newItem,
                image: [newItem?.image]
              };
            }
          ),
          label: res?.getSystemFieldConfiguration?.data?.label,
          required: res?.getSystemFieldConfiguration?.data?.config?.required,
          toolTipCheck:
            res?.getSystemFieldConfiguration?.data?.config?.toolTipCheck,
          toolTipText:
            res?.getSystemFieldConfiguration?.data?.config?.toolTipText,
          trueValue: res?.getSystemFieldConfiguration?.data?.config?.trueValue,
          falseValue:
            res?.getSystemFieldConfiguration?.data?.config?.falseValue,
          booleanDefaultValue:
            res?.getSystemFieldConfiguration?.data?.config?.booleanDefaultValue
        }
      });
      setSystemFieldData({ ...res?.getSystemFieldConfiguration?.data });
      setConfigLoading(false);
    },
    onError() {
      setConfigLoading(false);
    }
  });

  useEffect(() => {
    if (state?.cpqQuestionLoading) {
      dynamicFields({
        variables: {
          filter: {
            skip: 0
          },
          where: {
            fieldType: ['PICK_LIST', 'TEXT', 'BOOLEAN', 'NUMBER'],
            status: ['PUBLISH'],
            lineOfBusiness: [state?.cpqQuestionObject?.lineOfBusinesses],
            industry: [state?.cpqQuestionObject?.industry],
            questionnaireId: id,
            ...(!isCreate &&
              fieldObject?.selectedSystemFieldId && {
                selectedSystemFieldId: fieldObject?.selectedSystemFieldId
              })
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (refetchSystemFields) {
      dynamicFields({
        variables: {
          filter: {
            skip: 0
          },
          where: {
            fieldType: ['PICK_LIST', 'TEXT', 'BOOLEAN', 'NUMBER'],
            status: ['PUBLISH'],
            lineOfBusiness: [state?.cpqQuestionObject?.lineOfBusinesses],
            industry: [state?.cpqQuestionObject?.industry],
            questionnaireId: id,
            ...(!isCreate &&
              selectedSystemFieldWidget && {
                selectedSystemFieldId: selectedSystemFieldWidget
              })
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchSystemFields]);

  useEffect(() => {
    if (fieldObject?.selectedSystemFieldWidget) {
      fetchSystemFieldConfig({
        variables: {
          where: {
            systemFieldId: fieldObject?.selectedSystemFieldWidget,
            systemFieldType: fieldObject?.selectedSystemFieldType
          }
        }
      });
    }
    setActiveKey(selectedSystemFieldWidget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSystemFieldWidget]);

  const handleCategoryChange = (value, ...rest) => {
    if (value) {
      setConfigLoading(true);
      const selectedType = rest?.[0]?.['data-type'];

      const fieldObjectClone = {
        selectedSystemFieldType: rest?.[0]?.['data-type'],
        selectedSystemFieldLabel: rest?.[0]?.['data-label']
      };

      setFieldObject(fieldObjectClone);
      fetchSystemFieldConfig({
        variables: {
          where: {
            systemFieldId: value,
            systemFieldType: selectedType
          }
        }
      });
    } else {
      setFieldObject(null);
    }
  };

  return (
    <LoaderComponent spinning={state?.cpqQuestionLoading}>
      <div className="section-header mt-16">
        <span className="section-title">System Field Widget</span>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'selectedSystemFieldBlock',
          'selectedSystemFieldId'
        ]}
        rules={[
          {
            ...requiredWhiteSpaceAllowed,
            message: 'Please Select Category'
          }
        ]}
      >
        <SelectComponent
          allowClear
          onChange={handleCategoryChange}
          className="system-widget-select"
          placeholder="Select Category"
          notFoundContent={
            loading ? (
              <LoaderComponent size="small" setHeight={10} />
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )
          }
        >
          {dynamicFieldsData?.map((field) => (
            <Option
              key={field?.id}
              value={field?.id}
              data-type={field?.fieldType}
              data-label={field?.label}
            >
              {field?.label}
            </Option>
          ))}
        </SelectComponent>
      </Form.Item>

      {!loading && isEmpty(dynamicFieldsData) && (
        <>
          <span>No System Fields Available.</span>
          <br />
          <span className="mt-8 d-block">Reason could be:</span>
          <span>
            1. Either you have used all fields in <b>Global Conditions</b>
          </span>
          <br />
          <span>
            2. <b>Created Pages</b> with all fields
          </span>
          <br />
          <span>
            3. You need to <b>create </b> Dynamic Field
          </span>
        </>
      )}
      <LoaderComponent spinning={configLoading} setHeight={75}>
        {fieldObject?.selectedSystemFieldType ===
          DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && (
          <div id="pick-list-form-items">
            <Form.Item
              label="Display Style"
              name={['widgetConfiguration', 'displayStyle']}
              className="mb-0"
            >
              <Radio.Group className="common-radio">
                {map(PICK_LIST_STYLE, (item) => {
                  return (
                    <Radio key={item?.value} value={item?.value}>
                      {item?.label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Required</span>
              <Form.Item
                name={['widgetConfiguration', 'required']}
                className="mb-0"
                valuePropName="checked"
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Images</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'images']}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Images Preview</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'imagePreview']}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Option Descriptions</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'optionDescriptions']}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Descriptions</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'description']}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={['widgetConfiguration', 'descriptionText']}
              hidden={!showDescription}
              rules={[
                {
                  required: showDescription,
                  message: 'Please Enter Description'
                }
              ]}
            >
              <InputComponent placeholder="Enter Description" />
            </Form.Item>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Multiselection</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'multiSelection']}
              >
                <Switch
                  disabled={hideContinueButton || !showCtaButton}
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <div className="d-flex justify-between align-center">
              <span className="switch-logo">Hide Continue Button</span>
              <Form.Item
                valuePropName="checked"
                className="mb-0"
                name={['widgetConfiguration', 'hideContinueButton']}
              >
                <Switch
                  disabled={!!isMultiSelectionEnabled || !showCtaButton}
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            {!showCtaButton && (
              <Alert message="please enable continue button" type="warning" />
            )}
          </div>
        )}
        {fieldObject?.selectedSystemFieldType && (
          <Collapse
            collapsible
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            expandIconPosition="end"
            className="common-collapse questionnaire-collapse custom-collapse"
            activeKey={activeKey}
            onChange={(keys) => setActiveKey(keys)}
            ghost
          >
            <Panel
              header="Settings"
              key={selectedSystemFieldWidget}
              forceRender
            >
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && (
                <SystemPickListForm
                  form={form}
                  setDisableBtn={setDisableBtn}
                  systemFieldData={systemFieldData}
                  setSystemFieldData={setSystemFieldData}
                />
              )}
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.TEXT && (
                <SystemTextFieldForm form={form} />
              )}
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN && (
                <SystemBooleanForm form={form} />
              )}
              {fieldObject?.selectedSystemFieldType ===
                DYNAMIC_FIELDS_DATA_KEYS?.NUMBER && (
                <SystemNumberFieldForm form={form} />
              )}
            </Panel>
          </Collapse>
        )}
      </LoaderComponent>
    </LoaderComponent>
  );
};

export default SystemWidgetForm;
