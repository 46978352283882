import Icon from '@ant-design/icons';
import React from 'react';

const galleryIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33366 0.166992H10.667C11.3113 0.166992 11.8337 0.689327 11.8337 1.33366V10.667C11.8337 11.3113 11.3113 11.8337 10.667 11.8337H1.33366C0.689327 11.8337 0.166992 11.3113 0.166992 10.667V1.33366C0.166992 0.689327 0.689327 0.166992 1.33366 0.166992ZM1.33366 1.33366V8.09194L3.66696 5.75864L5.70863 7.8003L9.50029 4.00864L10.667 5.17533V1.33366H1.33366ZM1.33366 10.667V9.74185L3.66696 7.40855L6.9254 10.667H1.33366ZM10.667 10.667H8.57531L6.53358 8.62526L9.50029 5.65855L10.667 6.82525V10.667ZM7.16699 3.66699C7.16699 2.70049 6.38349 1.91699 5.41699 1.91699C4.45049 1.91699 3.66699 2.70049 3.66699 3.66699C3.66699 4.63349 4.45049 5.41699 5.41699 5.41699C6.38349 5.41699 7.16699 4.63349 7.16699 3.66699ZM4.83366 3.66699C4.83366 3.34483 5.09483 3.08366 5.41699 3.08366C5.73916 3.08366 6.00033 3.34483 6.00033 3.66699C6.00033 3.98916 5.73916 4.25033 5.41699 4.25033C5.09483 4.25033 4.83366 3.98916 4.83366 3.66699Z"
        fill="currentColor"
      />
    </svg>
  );
};

const GalleryIconComponent = (props) => (
  <Icon component={galleryIcon} {...props} />
);

export default GalleryIconComponent;
