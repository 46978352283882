import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Tag
} from 'antd';
import { debounce, filter, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import ImportIcon from '../../../assets/import.svg';
import { exportOptions, ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  checkPermissions,
  formatPhoneNumber,
  handleExportCommon
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import ImportModal from '../../imports/components/ImportModal';
import { FETCH_STATE_CITY } from '../../Signup/graphql/Query';
import { DELETE_MANUFACTURER, UPDATE_MANUFACTURER } from '../graphql/Mutations';
import { GET_MANUFACTURERS, MANUFACTURER_FILTER } from '../graphql/Queries';

let scrollDebounce = null;

const ManufacturerTable = () => {
  const {
    state: { pageSize, filterData, permissions },
    dispatch
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const initialManufacturerFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC'
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [manufacturerFilter, setManufacturerFilter] = useState(
    initialManufacturerFilter
  );
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [
    updateManufacturerMutate,
    { loading: updateManufacturerLoading }
  ] = useMutation(UPDATE_MANUFACTURER, {
    onError() {}
  });

  const [
    deleteManufacturer,
    { loading: deleteManufacturerLoading }
  ] = useMutation(DELETE_MANUFACTURER, {
    onError() {}
  });

  const [fetchManufacturerData, { loading, data }] = useLazyQuery(
    GET_MANUFACTURERS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.manufacturers?.count
        };
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.getLocationType?.data, (item) => {
          if (item?.state) {
            optionsCopy?.push(`${item?.name} (${item?.state})`);
          } else {
            optionsCopy?.push(item?.name);
          }
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.getLocationType?.data, (item) => {
          if (item?.state) {
            optionsCopy?.push(`${item?.name} (${item?.state})`);
          } else {
            optionsCopy?.push(item?.name);
          }
        });
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.getLocationType?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError() {
      setFilterLoading(false);
    }
  });

  const [manufacturerFilters] = useLazyQuery(MANUFACTURER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.manufacturerFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.manufacturerFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex])
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.manufacturerFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    }
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_MANUFACTURER_LIST']);
    if (permitted) {
      fetchManufacturerData({
        variables: {
          filter: manufacturerFilter,
          ...(filterData && {
            where: {
              ...filterData,
              ...(filterData?.city && {
                city: map(filterData?.city, (item) => {
                  return {
                    city: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[0],
                    state: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[1]
                  };
                })
              })
            }
          })
        }
      });
      setFilters(filterData);
      dispatch({ type: 'SET_FILTER_DATA', data: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchManufacturerData({
      variables: {
        filter: { ...manufacturerFilter, skip: 0 },
        ...(filtersCopyState && {
          where: {
            ...filtersCopyState,
            ...(filtersCopyState?.city && {
              city: map(filtersCopyState?.city, (item) => {
                return {
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1]
                };
              })
            })
          }
        })
      }
    });
    setFilters(filtersCopyState);
    setManufacturerFilter({
      ...manufacturerFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'country':
          setFilterList(['USA']);
          setFilterLoading(false);
          break;
        case 'state':
          fetchStateAndCity({
            variables: {
              filter: {
                skip: 0,
                limit: 20,
                type: 'STATE',
                search: '',
                sortOn: 'name',
                sortBy: 'ASC'
              }
            }
          });
          break;
        case 'city':
          fetchStateAndCity({
            variables: {
              filter: {
                skip: 0,
                limit: 20,
                type: 'CITY',
                search: '',
                sortOn: 'name',
                sortBy: 'ASC'
              }
            }
          });
          break;
        default:
          manufacturerFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex
              }
            }
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'country':
            setFilterList(['USA']);
            setFilterLoading(false);
            break;
          case 'state':
            fetchStateAndCity({
              variables: {
                filter: {
                  skip: filterList?.length,
                  limit: 20,
                  type: 'STATE',
                  search: filterSearch,
                  sortOn: 'name',
                  sortBy: 'ASC'
                }
              }
            });
            break;
          case 'city':
            fetchStateAndCity({
              variables: {
                filter: {
                  skip: filterList?.length,
                  limit: 20,
                  type: 'CITY',
                  search: filterSearch,
                  sortOn: 'name',
                  sortBy: 'ASC'
                }
              }
            });
            break;
          default:
            manufacturerFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex
                }
              }
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    }
  };

  const handleResetRole = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: []
    };
    setFilters(filtersCopy);
    fetchManufacturerData({
      variables: {
        filter: {
          ...manufacturerFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC'
        },
        ...(filtersCopy && {
          where: {
            ...filtersCopy,
            ...(filtersCopy?.city && {
              city: map(filtersCopy?.city, (item) => {
                return {
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1]
                };
              })
            })
          }
        })
      }
    });
    setManufacturerFilter({
      ...manufacturerFilter,
      skip: 0
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditManufacturer = (record) => {
    if (record?.id) {
      dispatch({ type: 'SET_FILTER_DATA', data: filters });
      history?.push(`${ROUTES?.MANUFACTURERS}/edit/${record?.id}`);
    } else {
      history?.push(`${ROUTES?.MANUFACTURERS}/add`);
    }
  };

  const handleViewManufacturer = (record) => {
    dispatch({ type: 'SET_FILTER_DATA', data: filters });
    history?.push(`${ROUTES?.MANUFACTURERS}/view/${record?.id}`);
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setManufacturerFilter(initialManufacturerFilter);
    setSortedInfo({});
    fetchManufacturerData({
      variables: { filter: initialManufacturerFilter }
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' }
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value
        )
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value]
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setManufacturerFilter({
        ...manufacturerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchManufacturerData({
        variables: {
          filter: {
            ...manufacturerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          },
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => {
                  return {
                    city: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[0],
                    state: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[1]
                  };
                })
              })
            }
          })
        }
      });
    } else {
      setManufacturerFilter({
        ...manufacturerFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchManufacturerData({
        variables: {
          filter: {
            ...manufacturerFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          },
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => {
                  return {
                    city: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[0],
                    state: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[1]
                  };
                })
              })
            }
          })
        }
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'country':
        setFilterList(['USA']);
        setFilterLoading(false);
        break;
      case 'state':
        fetchStateAndCity({
          variables: {
            filter: {
              skip: 0,
              limit: 20,
              type: 'STATE',
              search: value,
              sortOn: 'name',
              sortBy: 'ASC'
            }
          }
        });
        break;
      case 'city':
        fetchStateAndCity({
          variables: {
            filter: {
              skip: 0,
              limit: 20,
              type: 'CITY',
              search: value,
              sortOn: 'name',
              sortBy: 'ASC'
            }
          }
        });
        break;
      default:
        manufacturerFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex
            }
          }
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value
      )
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => {
                return (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    )}
                  </Tag>
                );
              })}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => {
                return (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleResetRole(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    }
  });

  const handleDeleteManufacturer = async (editManufacturerData) => {
    const response = await deleteManufacturer({
      variables: { where: { id: editManufacturerData?.id } }
    });
    if (response?.data?.deleteManufacturer) {
      fetchManufacturerData({
        variables: {
          filter: manufacturerFilter,
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => {
                  return {
                    city: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[0],
                    state: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[1]
                  };
                })
              })
            }
          })
        }
      });
    }
  };

  const handleManufacturerStatus = async (editManufacturerData) => {
    const editData = {
      name: editManufacturerData.name,
      logo: {
        ...editManufacturerData?.logo
      },
      contactNumber: editManufacturerData.contactNumber,
      isActive: !editManufacturerData.isActive,
      uuid: editManufacturerData?.uuid
    };
    // eslint-disable-next-line no-underscore-dangle
    delete editData?.logo.__typename;
    const response = await updateManufacturerMutate({
      variables: {
        data: { ...editData },
        where: { id: editManufacturerData?.id }
      }
    });
    if (response?.data?.updateManufacturer) {
      fetchManufacturerData({
        variables: {
          filter: manufacturerFilter,
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => {
                  return {
                    city: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[0],
                    state: item
                      ?.replace(' ', ',')
                      ?.replace('(', '')
                      ?.replace(')', '')
                      ?.split(',')?.[1]
                  };
                })
              })
            }
          })
        }
      });
    }
  };

  const renderActionButtons = (editManufacturerData) => {
    return (
      <div className="d-flex flex-vertical">
        <AccessControl
          allowedPermissions={[
            'FET_MANUFACTURER_UPDATE',
            'FET_MANUFACTURER_VIEW'
          ]}
        >
          <Button
            id="manufacturer-table-edit-btn"
            className="b-0"
            onClick={() => handleAddEditManufacturer(editManufacturerData)}
          >
            Edit
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_MANUFACTURER_VIEW']}>
          <Button
            id="manufacturer-table-edit-btn"
            className="b-0"
            onClick={() => handleViewManufacturer(editManufacturerData)}
          >
            View
          </Button>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_MANUFACTURER_DELETE']}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDeleteManufacturer(editManufacturerData)}
            okText="Yes"
            cancelText="No"
          >
            <Button id="manufacturer-table-status-btn" className="b-0">
              Remove
            </Button>
          </Popconfirm>
        </AccessControl>
        <AccessControl allowedPermissions={['FET_MANUFACTURER_UPDATE']}>
          <Popconfirm
            title={`Are you sure to ${
              editManufacturerData?.isActive ? 'Mark Inactive' : 'Mark Active'
            }?`}
            onConfirm={() => handleManufacturerStatus(editManufacturerData)}
            okText="Yes"
            cancelText="No"
          >
            <Button id="manufacturer-table-status-btn" className="b-0">
              {editManufacturerData?.isActive ? 'Mark Inactive' : 'Mark Active'}
            </Button>
          </Popconfirm>
        </AccessControl>
      </div>
    );
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: 200,
      className: 'max-width-column',
      render: (name, record) => {
        return (
          <>
            <img
              src={record?.logo?.url || placeholderImage}
              alt="brand-img"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
              className="list-img image-contain"
              width={24}
              height={24}
            />
            <span title={name}>{name}</span>
          </>
        );
      }
    },
    {
      title: 'ADDRESS',
      ellipsis: true,
      dataIndex: 'location',
      className: 'max-width-column',
      key: 'location',
      render: (location = {}) => {
        if (location?.addressLine1) {
          return (
            <span title={location?.addressLine1}>{location?.addressLine1}</span>
          );
        }
        return <span>-</span>;
      }
    },
    {
      title: 'CITY',
      width: 120,
      ellipsis: true,
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'city' && sortedInfo?.order,
      ...filterPopup('city'),
      render: (city, record = {}) => {
        if (record?.location?.city) {
          return (
            <span title={record?.location?.city}>{record?.location?.city}</span>
          );
        }
        return <span>-</span>;
      }
    },
    {
      title: 'STATE',
      width: 120,
      ellipsis: true,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'state' && sortedInfo?.order,
      ...filterPopup('state'),
      render: (state, record = {}) => {
        if (record?.location?.state) {
          return (
            <span title={record?.location?.state}>
              {record?.location?.state}
            </span>
          );
        }
        return <span>-</span>;
      }
    },
    {
      title: 'COUNTRY',
      width: 130,
      ellipsis: true,
      dataIndex: 'country',
      key: 'country',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'country' && sortedInfo?.order,
      ...filterPopup('country'),
      render: (country, record = {}) => {
        if (record?.location?.country) {
          return (
            <span title={record?.location?.country}>
              {record?.location?.country}
            </span>
          );
        }
        return <span>-</span>;
      }
    },
    {
      title: 'CONTACT NUMBER',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'contactNumber' && sortedInfo?.order,
      width: 180,
      render: (contactNumber) => {
        return formatPhoneNumber(contactNumber) || '-';
      }
    },
    {
      title: 'STATUS',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      width: 120,
      ...filterPopup('isActive'),
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      }
    },
    checkPermissions(permissions, [
      'FET_MANUFACTURER_UPDATE',
      'FET_MANUFACTURER_DELETE',
      'FET_MANUFACTURER_VIEW'
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        return (
          <Popover
            placement="bottom"
            overlayClassName="action-button"
            content={renderActionButtons(record)}
          >
            <MoreOutlined />
          </Popover>
        );
      }
    }
  ];

  const onSearchChange = (value) => {
    setManufacturerFilter({
      ...manufacturerFilter,
      skip: value
        ? 0
        : manufacturerFilter?.limit * (paginationProp?.current - 1),
      search: value
    });
    fetchManufacturerData({
      variables: {
        filter: {
          ...manufacturerFilter,
          skip: value
            ? 0
            : manufacturerFilter?.limit * (paginationProp?.current - 1),
          search: value
        },
        ...(filters && {
          where: {
            ...filters,
            ...(filters?.city && {
              city: map(filters?.city, (item) => {
                return {
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1]
                };
              })
            })
          }
        })
      }
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'MANUFACTURER'
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="manufacturers"
        folder="MANUFACTURER"
      />
      <AccessControl allowedPermissions={['FET_MANUFACTURER_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="manufacturer-table-add-btn"
            type="primary"
            onClick={handleAddEditManufacturer}
          >
            Add Manufacturer
          </Button>
        </Portal>
      </AccessControl>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_MANUFACTURER_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Manufacturer name or other detail.."
              name="Manufacturers"
              getData={onSearchChange}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_MANUFACTURER_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="manufacturer-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_MANUFACTURER_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{ items: exportOptions, onClick: handleExport }}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="manufacturer-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl
        allowedPermissions={['FET_MANUFACTURER_LIST']}
        showNoAccess
      >
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={
                loading ||
                updateManufacturerLoading ||
                deleteManufacturerLoading
              }
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.manufacturers?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default ManufacturerTable;
