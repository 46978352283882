import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { InputNumber, Popconfirm, Table } from 'antd';
import { find } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { AppContext } from '../../../AppContext';
import deleteIcon from '../../../assets/delete-red.svg';
import { checkPermissions, formatPrice } from '../../../common/utils';
import TableComponent from '../../../components/TableComponent';
import {
  DELETE_PRODUCT_SUB_ITEM,
  UPDATE_PRODUCT_SUB_ITEM
} from '../../productItems/graphql/Mutations';
import { CHANGE_ITEM_RANKING } from '../graphql/Mutations';

const ProductItemTable = ({
  match: { params: { id } = {} } = {},
  selectedKeys,
  loading,
  productItemData,
  setProductItemData,
  fetchProductItemData
}) => {
  const location = useLocation();
  const {
    state: { pageSize, permissions }
  } = useContext(AppContext);

  const initialProductItemFilter = {
    sortOn: 'order',
    sortBy: 'ASC',
    productId: id
  };

  const [sortedInfo, setSortedInfo] = useState({});
  const [productItemFilter, setProductItemFilter] = useState(
    initialProductItemFilter
  );
  const [showPopConfirmCheckBtn, setShowPopConfirmCheckBtn] = useState(false);
  const [dragLoading, setDragLoading] = useState(false);

  const [deleteProductSubItem] = useMutation(DELETE_PRODUCT_SUB_ITEM, {
    onError() {}
  });

  const [updateProductSubItem] = useMutation(UPDATE_PRODUCT_SUB_ITEM, {
    onCompleted: () => {
      fetchProductItemData({
        variables: { filter: productItemFilter }
      });
    },
    onError: () => {}
  });

  const [changeItemRanking] = useMutation(CHANGE_ITEM_RANKING, {
    onError: () => {}
  });

  useEffect(() => {
    fetchProductItemData({
      variables: { filter: productItemFilter }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowSelection = {
    columnTitle: 'PRIMARY',
    type: 'radio',
    getCheckboxProps: () => ({
      disabled: location?.pathname?.includes('/view')
    }),
    fixed: 'left',
    columnWidth: 90,
    selectedRowKeys: selectedKeys,
    onChange: async (selectedRowKeys) => {
      await updateProductSubItem({
        variables: {
          data: {
            isPrimary: true
          },
          where: {
            id: selectedRowKeys?.[0]
          }
        }
      });
    }
  };

  const handleDeleteProductSubItem = async (editData) => {
    const response = await deleteProductSubItem({
      variables: { where: { id: editData?.id } }
    });
    if (response?.data?.deleteProductSubItem) {
      fetchProductItemData({
        variables: { filter: productItemFilter }
      });
    }
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProductItemFilter({
        ...productItemFilter,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
      });
      fetchProductItemData({
        variables: {
          filter: {
            ...productItemFilter,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          }
        }
      });
    } else {
      setProductItemFilter({
        ...productItemFilter,
        sortOn: 'createdAt',
        sortBy: 'DESC'
      });
      fetchProductItemData({
        variables: {
          filter: {
            ...productItemFilter,
            sortOn: 'createdAt',
            sortBy: 'DESC'
          }
        }
      });
    }
  };

  const handleEdit = (index) => {
    const dataCopy = [...productItemData];
    dataCopy[index].editable = true;
    setProductItemData(dataCopy);
  };

  const handleChangeQuantity = async (record) => {
    await updateProductSubItem({
      variables: {
        data: {
          quantity: record?.tempQuantity
        },
        where: {
          id: record?.id
        }
      }
    });
    setShowPopConfirmCheckBtn(false);
  };

  const handleChangeQuantityValue = (value, index) => {
    setShowPopConfirmCheckBtn(true);
    if (!value) {
      return;
    }
    const dataCopy = [...productItemData];
    dataCopy[index].tempQuantity = value;
    setProductItemData(dataCopy);
  };

  const handleCloseEdit = (record, index) => {
    setShowPopConfirmCheckBtn(false);
    const dataCopy = [...productItemData];
    dataCopy[index].editable = false;
    dataCopy[index].tempQuantity = record?.quantity;
    setProductItemData(dataCopy);
  };

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setDragLoading(true);
      try {
        const response = await changeItemRanking({
          variables: {
            data: {
              oldIndex,
              newIndex
            },
            where: {
              id
            }
          }
        });
        if (response) {
          fetchProductItemData({
            variables: { filter: productItemFilter }
          });
          setDragLoading(false);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined className="drag-row" />
  ));

  const showDrag = () => {
    let sorterLength;

    if (!Object?.keys(sortedInfo)?.length || !sortedInfo?.order) {
      sorterLength = false;
    } else {
      sorterLength = true;
    }

    if (
      !sorterLength &&
      !location?.pathname?.includes('/view') &&
      checkPermissions(permissions, ['FET_PRODUCT_UPDATE'])
    ) {
      return true;
    }

    return false;
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      axis="y"
      lockAxis="y"
      lockOffset={['0%', '100%']}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = find(
      productItemData,
      (item) => item?.id === restProps['data-row-key']
    );
    if (index) return <SortableItem index={index?.order} {...restProps} />;
    return null;
  };

  const columns = [
    showDrag() && {
      dataIndex: 'sort',
      fixed: 'left',
      columnWidth: 50,
      width: 10,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      ellipsis: true,
      className: 'max-width-column',
      width: 120,
      render: (sku, record) => <span>{record?.productItems?.sku}</span>
    },
    {
      title: 'PRODUCT',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      className: 'max-width-column',
      render: (name, record) => <span>{record?.productItems?.name}</span>
    },
    {
      title: 'MANUFACTURER',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      ellipsis: true,
      width: 180,
      render: (manufacturer) => {
        const { name } = manufacturer;
        return <span title={name}>{name}</span>;
      }
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry',
      key: 'industry',
      ellipsis: true,
      width: 190,
      render: (industry) => {
        const { label } = industry;
        return <span title={label}>{label}</span>;
      }
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusiness',
      key: 'lineOfBusiness',
      ellipsis: true,
      width: 190,
      render: (lineOfBusiness) => {
        const { label } = lineOfBusiness;
        return <span title={label}>{label}</span>;
      }
    },
    {
      title: 'SERVICE TYPE',
      dataIndex: 'subArea',
      key: 'subArea',
      ellipsis: true,
      width: 140,
      render: (subArea) => {
        const { label } = subArea;
        return <span title={label}>{label}</span>;
      }
    },
    {
      title: 'QTY',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'quantity' && sortedInfo?.order,
      width: 130,
      render: (quantity = 1, record, index) => {
        if (!record?.editable) {
          return (
            <div>
              <span className="quantity-text">{quantity}</span>
              {!location?.pathname?.includes('/view') && (
                <EditOutlined
                  className="edit-icon"
                  onClick={() => handleEdit(index)}
                />
              )}
            </div>
          );
        }
        return (
          <div>
            <InputNumber
              type="number"
              className="quantity-component"
              value={record?.tempQuantity}
              onChange={(value) => handleChangeQuantityValue(value, index)}
              min={1}
              precision={0}
            />
            {!showPopConfirmCheckBtn ? (
              <CheckOutlined
                disabled={record?.tempQuantity < 1}
                className="check-icon"
                onClick={() => handleChangeQuantity(record, index)}
              />
            ) : (
              <Popconfirm
                title=" This change will affect the selling price; do you like to save these changes?"
                onConfirm={() => handleChangeQuantity(record, index)}
                onCancel={() => handleCloseEdit(record, index)}
                okText="Yes"
                cancelText="No"
              >
                <CheckOutlined
                  disabled={record?.tempQuantity < 1}
                  className="check-icon"
                />
              </Popconfirm>
            )}
            <CloseOutlined
              className="close-icon"
              onClick={() => handleCloseEdit(record, index)}
            />
          </div>
        );
      }
    },
    {
      title: 'PRICE',
      dataIndex: 'defaultPrice',
      key: 'defaultPrice',
      sorter: true,
      ellipsis: true,
      align: 'right',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'defaultPrice' && sortedInfo?.order,
      width: 130,
      render: (defaultPrice = 0, record) => (
        <span title={formatPrice(defaultPrice / record?.quantity)}>
          {formatPrice(defaultPrice / record?.quantity)}
        </span>
      )
    },
    {
      title: 'TOTAL PRICE',
      dataIndex: 'defaultPrice',
      key: 'defaultPrice',
      ellipsis: true,
      align: 'right',
      width: 140,
      render: (defaultPrice = null) => (
        <span title={formatPrice(defaultPrice)}>
          {formatPrice(defaultPrice)}
        </span>
      )
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (action, record = {}) => {
        return (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDeleteProductSubItem(record)}
            okText="Yes"
            cancelText="No"
          >
            <img
              src={deleteIcon}
              alt="delete-icon"
              className={
                !location?.pathname?.includes('/view')
                  ? 'product-item-delete'
                  : 'display-none'
              }
            />
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <div className="common-table product-sub-item-table">
      {pageSize && (
        <TableComponent
          isSearch={false}
          fullHeight={false}
          scroll={{ x: 'max-content' }}
          loadingData={loading || dragLoading}
          columns={[...columns?.filter((item) => item !== false)]}
          data={productItemData || []}
          onChange={handleTableChange}
          rowSelection={
            checkPermissions(permissions, ['FET_PRODUCT_UPDATE']) &&
            rowSelection
          }
          components={
            productItemData?.length > 0
              ? {
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow
                  }
                }
              : {}
          }
          rowKey="id"
          summary={(pageData) => {
            let totalPrice = 0;
            let price = 0;
            let qty = 0;
            pageData?.forEach(({ defaultPrice, quantity }) => {
              totalPrice += defaultPrice;
              price += defaultPrice / quantity;
              qty += quantity;
            });
            if (productItemData?.length > 0) {
              return (
                <Table.Summary fixed="bottom">
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={showDrag() ? 7 : 6} />
                    <Table.Summary.Cell>
                      <span>Total</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <span>{qty}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <span>{formatPrice(price)}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <span>{formatPrice(totalPrice)}</span>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }
          }}
        />
      )}
    </div>
  );
};

export default withRouter(ProductItemTable);
