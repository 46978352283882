import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { objectWithoutKey, uploadFile } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SubAreaForm from '../components/SubAreaForm';
import { UPDATE_SUB_AREA } from '../graphql/Mutations';
import { GET_SUB_AREA } from '../graphql/Queries';

function SubAreaEdit(props) {
  const {
    match: { params: { lobId, subId, id: industryId } = {} } = {},
    history
  } = props;
  const {
    state: { currentUser }
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);

  const { data: { subArea } = {} } = useQuery(GET_SUB_AREA, {
    variables: { id: subId },
    onCompleted: (res) => {
      if (res?.subArea?.logo?.url) {
        setLogoUrl({
          ...res?.subArea?.logo
        });
      }
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    }
  });

  const [updateSubAreaMutate] = useMutation(UPDATE_SUB_AREA, {
    onError: () => {
      setSubmitLoading(false);
    }
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = subArea?.uuid || uuidv4();

    const logo = await uploadFile({
      logoObject: formValues?.logo,
      currentUser,
      uuid,
      folder: 'sub-area'
    });

    const newFormValues = {
      ...formValues,
      logo: formValues?.logo?.url
        ? { ...subArea?.logo, __typename: undefined }
        : logo
    };

    delete newFormValues?.saCode;
    delete newFormValues?.lineOfBusinessId;
    delete newFormValues?.referenceId;
    delete newFormValues?.industry;

    const variables = { data: newFormValues, where: { id: subArea?.id } };
    try {
      const response = await updateSubAreaMutate({
        variables: { ...variables }
      });
      if (response?.data?.updateSubArea) {
        setSubmitLoading(false);
        history?.push(`${ROUTES?.INDUSTRIES}/edit/${industryId}/edit/${lobId}`);
      }
    } catch (error) {
      return error;
    }
  };

  if (!subId) {
    <Redirect to={`${ROUTES?.INDUSTRIES}/edit/${industryId}/edit/${lobId}`} />;
  }

  const initialValues = {
    ...(subArea && { ...objectWithoutKey(subArea, 'logo') }),
    lineOfBusinessId: subArea?.lineOfBusinessId,
    description: subArea?.description || '',
    isActive: subArea?.isActive,
    ...(subArea?.logo?.url && {
      logo: { ...subArea?.logo, ...logoUrl }
    })
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={`${ROUTES?.INDUSTRIES}/edit/${industryId}/edit/${lobId}`}
          />
          {subArea && (
            <span className="portal-header">
              {subArea?.lineOfBusiness?.label}
            </span>
          )}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <SubAreaForm
          isEdit
          lobOption={[subArea?.lineOfBusiness]}
          subAreaData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      )}
    </Card>
  );
}

export default withRouter(SubAreaEdit);
