import React from 'react';
import { withRouter } from 'react-router-dom';
import AppointmentWidgetComponent from '../../widgetComponents/appointmentWidget/preview';
import MyCartWidgetComponent from '../../widgetComponents/cartWidget/preview';
import ContactWidgetComponent from '../../widgetComponents/contactWidget/preview';
import ContentWidgetComponent from '../../widgetComponents/contentWidget/preview';
import CustomWidgetPreview from '../../widgetComponents/customWidget/preview';
import DimensionWidgetComponent from '../../widgetComponents/dimensionWidget/preview';
import PostalWidgetComponent from '../../widgetComponents/postalWidget/preview';
import MyProjectWidgetComponent from '../../widgetComponents/projectWidget/preview';
import QuoteWidgetComponent from '../../widgetComponents/quoteWidget/preview';
import SystemWidgetComponent from '../../widgetComponents/systemFieldWidget/preview';

const WidgetSection = ({
  pageConfiguration = null,
  selectedTab = '',
  selectedLob = null,
  selectedAppointmentKey = '',
  currentPageSequence = null,
  fieldObject = null,
  systemFieldData = null,
  setSelectedAppointmentKey,
  checkedList = [],
  selectedDimensionTab = null,
  selectedDimensionProject = null
}) => {
  return (
    <div className="widget-section">
      {pageConfiguration?.widgetType === 'POSTAL' && (
        <PostalWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
        />
      )}
      {pageConfiguration?.widgetType === 'PROJECT' && (
        <MyProjectWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          selectedLob={selectedLob}
        />
      )}
      {pageConfiguration?.widgetType === 'QUOTES' && (
        <QuoteWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          selectedTab={selectedTab}
        />
      )}
      {pageConfiguration?.widgetType === 'APPOINTMENT' && (
        <AppointmentWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          selectedAppointmentKey={selectedAppointmentKey}
          setSelectedAppointmentKey={setSelectedAppointmentKey}
          currentPageSequence={currentPageSequence}
        />
      )}
      {pageConfiguration?.widgetType === 'CONTENT' && (
        <ContentWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
        />
      )}
      {pageConfiguration?.widgetType === 'CART' && (
        <MyCartWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
        />
      )}
      {pageConfiguration?.widgetType === 'CONTACT' && (
        <ContactWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
        />
      )}
      {pageConfiguration?.widgetType === 'DIMENSION' && (
        <DimensionWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          selectedDimensionTab={selectedDimensionTab}
          selectedDimensionProject={selectedDimensionProject}
          currentPageSequence={currentPageSequence}
        />
      )}
      {pageConfiguration?.widgetType === 'SYSTEM' && (
        <SystemWidgetComponent
          widgetConfig={pageConfiguration?.widgetConfiguration}
          fieldObject={fieldObject}
          systemFieldData={systemFieldData}
        />
      )}
      {/* {pageConfiguration?.widgetType === 'SERVICE' && (
        <ServiceTypeWidget
          widgetConfig={pageConfiguration?.widgetConfiguration}
          handleSubmitCta={handleSubmitCta}
          setSavedData={setSavedData}
          // projectId={getData({ slug, key: 'submitData' })?.projectId}
        />
      )} */}
      {pageConfiguration?.widgetType === 'CUSTOM' && (
        <CustomWidgetPreview
          widgetConfig={pageConfiguration?.widgetConfiguration}
          systemFieldData={systemFieldData}
          checkedList={checkedList}
        />
      )}
    </div>
  );
};

export default withRouter(WidgetSection);
