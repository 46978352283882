import { UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Popconfirm,
  Row,
  TimePicker
} from 'antd';
import { findIndex, forEach, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import DeleteIcon from '../../../assets/delete-red.svg';
import SaveIcon from '../../../assets/save.svg';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import Portal from '../../../components/Portal';
import {
  REMOVE_HOLIDAY,
  UPDATE_HOURS_OF_OPERATION
} from '../graphql/Mutations';
import { FETCH_HOLIDAYS } from '../graphql/Queries';
import CommonHolidayPanel from './CommonHolidayPanel';
import HolidayModal from './HolidayModal';

const initialHolidayFilter = {
  skip: 0,
  limit: 10,
  sortBy: 'ASC',
  sortOn: 'date'
};

const { Panel } = Collapse;

const { requiredWhiteSpaceAllowed } = formValidatorRules;

const HoursOfOperationForm = ({
  holidaysData = [],
  businessHoursData = []
}) => {
  const {
    state: { permissions },
    dispatch
  } = useContext(AppContext);
  const holidayObjClone = holidaysData;
  const businessHoursObjClone = businessHoursData;
  const [form] = Form?.useForm();
  const [disableBtn, setDisableBtn] = useState(true);

  const [loading, setLoading] = useState(false);
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'businessHours',
    'holiDays'
  ]);

  const [removeHoliday] = useMutation(REMOVE_HOLIDAY, {
    refetchQueries: [
      {
        query: FETCH_HOLIDAYS,
        variables: { filter: { ...initialHolidayFilter } }
      }
    ]
  });

  const [updateHoursOfOperation] = useMutation(UPDATE_HOURS_OF_OPERATION, {
    onCompleted() {},
    onError() {}
  });

  const onValuesChange = (changedValues) => {
    const objId = Object?.keys(changedValues)?.[0];
    const newValue = Object?.values(changedValues)?.[0];
    const index = findIndex(holidaysData, (item) => item?.id === objId);
    if (index > -1) {
      holidayObjClone[index] = {
        ...holidayObjClone?.[index],
        ...newValue
      };
    }
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };

  const handleFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setLoading(true);
    const finalHolidayObj = [];
    const finalBusinessHoursObj = [];

    forEach(holidayObjClone, (item) => {
      const formattedObj = {
        ...item,
        open: item?.open && moment(item?.open)?.format('HH:mm:ss'),
        close: item?.close && moment(item?.close)?.format('HH:mm:ss'),
        cutOfTime:
          item?.cutOfTime && moment(item?.cutOfTime)?.format('HH:mm:ss'),
        date: item?.date && moment(item?.date)?.format('YYYY-MM-DD')
      };
      finalHolidayObj?.push(formattedObj);
    });

    forEach(businessHoursObjClone, (item) => {
      if (Object?.getOwnPropertyNames(values)?.includes(item?.title)) {
        const formattedObj = {
          id: item?.id,
          title: item?.title,
          checked: values[item?.title]?.checked,
          startTime:
            values[item?.title]?.from &&
            moment(values[item?.title]?.from)?.format('HH:mm:ss'),
          endTime:
            values[item?.title]?.to &&
            moment(values[item?.title]?.to)?.format('HH:mm:ss')
        };
        finalBusinessHoursObj?.push(formattedObj);
      }
    });

    try {
      updateHoursOfOperation({
        variables: {
          data: {
            updatedData: finalHolidayObj,
            businessHoursData: finalBusinessHoursObj
          }
        }
      });
      setDisableBtn(true);
      setLoading(false);
    } catch (error) {
      setDisableBtn(true);
      setLoading(false);
    }
  };

  const genExtra = (id) => (
    <AccessControl
      allowedPermissions={['FET_HOURS_OF_OPERATION_SETTINGS_UPDATE']}
    >
      <Popconfirm
        title="Are you sure to delete?"
        onConfirm={() =>
          removeHoliday({
            variables: {
              where: {
                id
              }
            }
          })
        }
        okText="Yes"
        cancelText="No"
      >
        <img
          className="panel-delete-icon"
          src={DeleteIcon}
          alt="delete-icon"
          onClick={(event) => {
            event?.stopPropagation();
          }}
        />
      </Popconfirm>
    </AccessControl>
  );

  return (
    <div className="content-section">
      <AccessControl
        allowedPermissions={['FET_HOURS_OF_OPERATION_SETTINGS_UPDATE']}
      >
        {showHolidayModal && (
          <HolidayModal
            holidaysData={holidaysData}
            showModal={showHolidayModal}
            setShowModal={setShowHolidayModal}
          />
        )}
        <Portal portalId="header-right-content">
          <Button
            className="common-button save-btn"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="hours-of-operation-save-btn"
            loading={loading}
            disabled={disableBtn}
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        name="businessHoursForm"
        onValuesChange={onValuesChange}
        onFinish={handleFinish}
        validateTrigger="onChange"
        layout="vertical"
      >
        <Collapse
          bordered={false}
          activeKey={collapseActiveKeys}
          onChange={(keys) => setCollapseActiveKeys(keys)}
          defaultActiveKey={['general1']}
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 0 : 180} />
          )}
          className="common-collapse"
        >
          <Panel forceRender header="BUSINESS HOURS *" key="businessHours">
            <fieldset
              disabled={
                !checkPermissions(permissions, [
                  'FET_HOURS_OF_OPERATION_SETTINGS_UPDATE'
                ])
              }
            >
              <span className="panel-description">
                Define business hours for the selected days of the week
              </span>
              <Row className="panel-content fill-width">
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  {map(businessHoursData, (businessHourObj) => {
                    return (
                      <Row
                        gutter={8}
                        key={businessHourObj?.id}
                        className="fill-width"
                      >
                        <Col
                          xs={8}
                          sm={6}
                          md={5}
                          lg={4}
                          xl={6}
                          xxl={4}
                          className="fill-width d-flex align-center"
                        >
                          <Form.Item
                            name={[businessHourObj?.title, 'checked']}
                            valuePropName="checked"
                            initialValue={businessHourObj?.checked}
                            className="common-checkbox"
                          >
                            <Checkbox
                              value={businessHourObj?.id}
                              className="common-checkbox"
                            >
                              {businessHourObj?.title}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col
                          className="from-time"
                          xs={8}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={6}
                          xxl={5}
                        >
                          <Form.Item
                            name={[businessHourObj?.title, 'from']}
                            label="From"
                            initialValue={moment(businessHourObj?.startTime)}
                            rules={[
                              {
                                ...requiredWhiteSpaceAllowed,
                                message: 'Please Select From time'
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value) {
                                    return Promise?.resolve();
                                  }
                                  if (
                                    getFieldValue([
                                      businessHourObj?.title,
                                      'to'
                                    ]) &&
                                    value >
                                      getFieldValue([
                                        businessHourObj?.title,
                                        'to'
                                      ])
                                  ) {
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise?.reject(
                                      'From time cannot be greater than end time!'
                                    );
                                  }
                                  return Promise?.resolve();
                                }
                              })
                            ]}
                          >
                            <TimePicker
                              className="common-time-picker"
                              format="h:mm a"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={4} xl={6} xxl={5}>
                          <Form.Item
                            name={[businessHourObj?.title, 'to']}
                            rules={[
                              {
                                ...requiredWhiteSpaceAllowed,
                                message: 'Please Select To time'
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    !getFieldValue([
                                      businessHourObj?.title,
                                      'from'
                                    ])
                                  ) {
                                    return Promise?.resolve();
                                  }
                                  if (
                                    getFieldValue([
                                      businessHourObj?.title,
                                      'from'
                                    ]) &&
                                    value <
                                      getFieldValue([
                                        businessHourObj?.title,
                                        'from'
                                      ])
                                  ) {
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise?.reject(
                                      'To time cannot be less than from time!'
                                    );
                                  }
                                  return Promise?.resolve();
                                }
                              })
                            ]}
                            label="To"
                            className="to-time fill-width"
                            initialValue={moment(businessHourObj?.endTime)}
                          >
                            <TimePicker
                              className="common-time-picker"
                              format="h:mm a"
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </fieldset>
          </Panel>
          <Panel forceRender header="HOLIDAYS *" key="holiDays">
            <span className="panel-description">Add your holidays</span>
            <br />
            <AccessControl
              allowedPermissions={['FET_HOURS_OF_OPERATION_SETTINGS_UPDATE']}
            >
              <Button
                type="primary"
                onClick={() => setShowHolidayModal(true)}
                className="common-button add-holiday-btn"
              >
                Add Holiday
              </Button>
            </AccessControl>

            <div className="panel-content">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <UpOutlined rotate={isActive ? 0 : 180} />
                )}
                className="holiday-collapse"
              >
                {map(holidaysData, (holidayObj) => {
                  return (
                    <Panel
                      forceRender
                      header={holidayObj?.name}
                      key={holidayObj?.id}
                      extra={genExtra(holidayObj?.id)}
                    >
                      <fieldset
                        disabled={
                          !checkPermissions(permissions, [
                            'FET_HOURS_OF_OPERATION_SETTINGS_UPDATE'
                          ])
                        }
                      >
                        <CommonHolidayPanel holidayObj={holidayObj} />
                      </fieldset>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </Panel>
        </Collapse>
      </Form>
    </div>
  );
};

export default HoursOfOperationForm;
