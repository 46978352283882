import Icon from '@ant-design/icons';
import React from 'react';

const settingIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 11.4665V4.53832L8 1.07422L2 4.53832V11.4665L8 14.9306L14 11.4665ZM3.33333 5.30815L8 2.61385L12.6667 5.30815V10.6968L8 13.3911L3.33333 10.6968V5.30815ZM8 11.3342C6.15905 11.3342 4.66667 9.84184 4.66667 8.00089C4.66667 6.15995 6.15905 4.66756 8 4.66756C9.84095 4.66756 11.3333 6.15995 11.3333 8.00089C11.3333 9.84184 9.84095 11.3342 8 11.3342ZM10 8.0009C10 9.10546 9.10457 10.0009 8 10.0009C6.89543 10.0009 6 9.10546 6 8.0009C6 6.89633 6.89543 6.0009 8 6.0009C9.10457 6.0009 10 6.89633 10 8.0009Z"
        fill="currentColor"
      />
    </svg>
  );
};

const SettingIconComponent = (props) => (
  <Icon component={settingIcon} {...props} />
);

export default SettingIconComponent;
