import Icon from '@ant-design/icons';
import React from 'react';

const brandIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33301 6.33301C1.67615 6.33301 0.333008 4.98986 0.333008 3.33301C0.333008 1.67615 1.67615 0.333008 3.33301 0.333008C4.98986 0.333008 6.33301 1.67615 6.33301 3.33301C6.33301 4.98986 4.98986 6.33301 3.33301 6.33301ZM0.333008 10.6663C0.333008 12.3232 1.67615 13.6663 3.33301 13.6663C4.98986 13.6663 6.33301 12.3232 6.33301 10.6663C6.33301 9.00949 4.98986 7.66634 3.33301 7.66634C1.67615 7.66634 0.333008 9.00949 0.333008 10.6663ZM7.66634 10.6663C7.66634 12.3232 9.00949 13.6663 10.6663 13.6663C12.3232 13.6663 13.6663 12.3232 13.6663 10.6663C13.6663 9.00949 12.3232 7.66634 10.6663 7.66634C9.00949 7.66634 7.66634 9.00949 7.66634 10.6663ZM7.66634 3.33301C7.66634 4.98986 9.00949 6.33301 10.6663 6.33301C12.3232 6.33301 13.6663 4.98986 13.6663 3.33301C13.6663 1.67615 12.3232 0.333008 10.6663 0.333008C9.00949 0.333008 7.66634 1.67615 7.66634 3.33301ZM10.6663 4.99967C11.5868 4.99967 12.333 4.25348 12.333 3.33301C12.333 2.41253 11.5868 1.66634 10.6663 1.66634C9.74587 1.66634 8.99967 2.41253 8.99967 3.33301C8.99967 4.25348 9.74587 4.99967 10.6663 4.99967ZM4.99967 3.33301C4.99967 4.25348 4.25348 4.99967 3.33301 4.99967C2.41253 4.99967 1.66634 4.25348 1.66634 3.33301C1.66634 2.41253 2.41253 1.66634 3.33301 1.66634C4.25348 1.66634 4.99967 2.41253 4.99967 3.33301ZM10.6663 12.333C11.5868 12.333 12.333 11.5868 12.333 10.6663C12.333 9.74587 11.5868 8.99967 10.6663 8.99967C9.74587 8.99967 8.99967 9.74587 8.99967 10.6663C8.99967 11.5868 9.74587 12.333 10.6663 12.333ZM4.99967 10.6663C4.99967 11.5868 4.25348 12.333 3.33301 12.333C2.41253 12.333 1.66634 11.5868 1.66634 10.6663C1.66634 9.74587 2.41253 8.99967 3.33301 8.99967C4.25348 8.99967 4.99967 9.74587 4.99967 10.6663Z"
        fill="currentColor"
      />
    </svg>
  );
};

const BrandIconComponent = (props) => <Icon component={brandIcon} {...props} />;

export default BrandIconComponent;
