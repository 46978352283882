import { useLoadScript } from '@react-google-maps/api';
import { Button, Form } from 'antd';
import React, { useEffect } from 'react';
import MarkerIcon from '../../../../../../assets/marker-icon.svg';
import GoogleMapComponent from '../../../previewComponents/globalComponents/GoogleMapComponent';
import InputComponent from '../../../previewComponents/globalComponents/InputComponent';
import './postalCode.less';

const PostalWidgetComponent = ({ widgetConfig = null }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process?.env?.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const updateHeight = () => {
    // eslint-disable-next-line no-undef
    const mapComponent = document?.getElementsByClassName(
      'common-map-cpq'
    )?.[0];
    if (mapComponent) {
      mapComponent.style.height = `${mapComponent?.clientWidth}px`;
    }
  };

  useEffect(() => {
    updateHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateHeight);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateHeight);
  });

  return (
    <div className="postal-code-page">
      {widgetConfig?.zipCodeWidget?.map && (
        <div className="map-section" id="map-area">
          {isLoaded && <GoogleMapComponent loadError={loadError} />}
        </div>
      )}
      <div className="zip-section">
        <Form layout="vertical" initialValues={{ zipCode: 'XXXXXX' }}>
          <Form.Item name="zipCode">
            <InputComponent
              readOnly
              allowClear
              placeholder="Enter The Postal Code"
              suffix={
                widgetConfig?.zipCodeWidget?.geolocation && (
                  <Button
                    className="marker-btn"
                    type="primary"
                    icon={
                      <img
                        src={MarkerIcon}
                        alt="import-icon"
                        width={11}
                        height={13}
                      />
                    }
                  />
                )
              }
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PostalWidgetComponent;
