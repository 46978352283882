import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLoadScript } from '@react-google-maps/api';
import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Slider,
  Tag,
  message
} from 'antd';
import { debounce, find, forEach, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import ExpandIconComponent from '../../../app/components/iconComponents/ExpandIconComponent';
import api from '../../../common/api';
import { ROUTES } from '../../../common/constants';
import {
  displayZipCodes,
  fetchStep,
  formValidatorRules
} from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { CREATE_TENANT_REGION } from '../graphql/Mutations';
import { GET_LOCATION_TYPE, GET_ZIP_CODES } from '../graphql/Queries';
import StepProcess from '../pages/StepProcess';
import GoogleAutoComplete from './GoogleAutoComplete';
import GoogleMapComponent from './GoogleMapComponent';
import RegionModal from './RegionModal';

let searchDebounce = null;
let scrollDebounce = null;

const { Option } = SelectComponent;

const { required } = formValidatorRules;

const libraries = ['places'];

const Region = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  const { dispatch, getOnboardingData } = useContext(AppContext);
  const onboardingData = getOnboardingData();
  const [form] = Form?.useForm();
  const [value, setValue] = useState(2);
  const [sliderValue, setSliderValue] = useState(30);
  const [markerObj, setMarkerObj] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [autoCompleteValueObj, setAutoCompleteValueObj] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  const [placesValue, setPlacesValue] = useState('');
  const [zip, setZip] = useState([]);
  const [isData, setIsData] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [zipValue, setZipValue] = useState('');
  const [zipMarkers, setZipMarkers] = useState([]);
  const [centerMap, setCenterMap] = useState(null);
  const [cityStateMarkerObj, setCityStateMarkerObj] = useState(null);
  const [cityStateZipMarker, setCityStateZipMarker] = useState([]);

  const [createRegion, { loading: regionLoading }] = useMutation(
    CREATE_TENANT_REGION,
    {
      onCompleted: () => {
        fetchStep({ dispatch, setLoading: false, changeRoute: false });
        history?.push(`${ROUTES?.ONBOARDING}/packages`);
      },
      onError: () => {}
    }
  );

  const [getLocationType] = useLazyQuery(GET_LOCATION_TYPE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...autoCompleteOptions];
        optionsCopy?.push(...res?.getLocationType?.data);
        setAutoCompleteOptions(optionsCopy);
        setScrollFlag(false);
      } else {
        setAutoCompleteOptions(res?.getLocationType?.data);
      }
    },
    onError() {}
  });

  const [getZipCodes, { data, loading: zipLoading }] = useLazyQuery(
    GET_ZIP_CODES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const zipsCopy = [];
        const zipMarkersCopy = [];
        forEach(res?.getZipCodes?.data, (item) => {
          zipsCopy?.push(item?.zipCode);
          zipMarkersCopy?.push({
            zipCode: item?.zipCode,
            lat: item?.longLat?.coordinates?.[1],
            lng: item?.longLat?.coordinates?.[0]
          });
        });
        setCenterMap({
          lat: res?.getZipCodes?.data?.[0]?.longLat?.coordinates?.[1],
          lng: res?.getZipCodes?.data?.[0]?.longLat?.coordinates?.[0]
        });
        setZip(zipsCopy);
        if (value === 1) {
          setZipMarkers(zipMarkersCopy);
        } else {
          setCityStateZipMarker(zipMarkersCopy);
        }
        setStateList(res?.getZipCodes?.states);
        const DefaultValue = map(res?.getZipCodes?.states, (item) => item);
        setCheckBoxValue(DefaultValue);
        if (res?.getZipCodes?.data?.length > 0) {
          setIsData(true);
        }
      },
      onError() {
        setZip([]);
        setStateList([]);
        setIsData(false);
        setCheckBoxValue([]);
      }
    }
  );

  const options = [
    { label: 'State, County or City', value: 2 },
    { label: 'Area Selection', value: 1 }
  ];

  const onChange = (e) => {
    setValue(e?.target?.value);
  };

  const onChangeCheckbox = (checkBoxValues) => {
    const zipCopy = [];
    const zipMarkersCopy = [];
    forEach(checkBoxValues, (item) => {
      forEach(data?.getZipCodes?.data, (zipObj) => {
        if (zipObj?.state === item) {
          zipCopy?.push(zipObj?.zipCode);
          zipMarkersCopy?.push({
            zipCode: zipObj?.zipCode,
            lat: zipObj?.longLat?.coordinates?.[1],
            lng: zipObj?.longLat?.coordinates?.[0]
          });
        }
      });
    });
    setCenterMap({
      lat: data?.getZipCodes?.data?.[0]?.longLat?.coordinates?.[1],
      lng: data?.getZipCodes?.data?.[0]?.longLat?.coordinates?.[0]
    });
    setCheckBoxValue(checkBoxValues);
    setZip(zipCopy);
    if (value === 1) {
      setZipMarkers(zipMarkersCopy);
    } else {
      setCityStateZipMarker(zipMarkersCopy);
    }
  };
  useEffect(() => {
    if (isLoaded) {
      if (onboardingData?.data?.region) {
        const {
          data: {
            region: {
              name = '',
              zipCodes = [],
              regionData: {
                radioValue = null,
                addressValue = '',
                radius = null,
                markerObj: markerObject = {},
                selectValue = null
              } = {}
            }
          }
        } = onboardingData;
        form?.setFieldsValue({ regionName: name });
        setZip(zipCodes);
        setValue(radioValue);
        setIsData(true);
        setBtnDisabled(false);
        setZipMarkers(zipCodes);
        if (radioValue === 1) {
          setSliderValue(radius);
          setPlacesValue(addressValue);
          setCenterMap({
            lat: markerObject?.lat,
            lng: markerObject?.lng
          });
        } else {
          setAutoCompleteOptions([selectValue]);
          setAutoCompleteValue(selectValue?.id);
          setAutoCompleteValueObj(selectValue);
        }
        setTimeout(() => {
          if (radioValue === 1) {
            setMarkerObj(markerObject);
          } else {
            setCityStateMarkerObj(markerObject);
          }
        }, 1000);
      }
      if (
        !onboardingData?.data?.region &&
        onboardingData?.data?.tenantAddress
      ) {
        setPlacesValue(onboardingData?.data?.tenantAddress);
        api
          ?.get('/map-api', {
            params: {
              address: onboardingData?.data?.tenantAddress
            }
          })
          .then((response) => {
            const { lat, lng } =
              response?.results?.length > 0 &&
              response?.results?.[0]?.geometry?.location;
            setTimeout(() => {
              setMarkerObj({ lat, lng });
            }, 1000);
          })
          .catch((error) => {
            message?.error(error?.response?.data?.message);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const onScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom) {
        setScrollFlag(true);
        getLocationType({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: autoCompleteOptions?.length,
              limit: 20,
              search: searchValue
            }
          }
        });
      }
    }, 500);
    scrollDebounce();
  };

  const handleCallSelectValue = () => {
    if (autoCompleteValue) {
      getLocationType({
        variables: {
          filter: {
            sortOn: 'name',
            sortBy: 'ASC',
            skip: 0,
            limit: 20,
            search: autoCompleteValueObj?.name
          }
        }
      });
    } else {
      setAutoCompleteOptions([]);
    }
  };

  const getData = (debounceValue) => {
    if (debounceValue) {
      getLocationType({
        variables: {
          filter: {
            sortOn: 'name',
            sortBy: 'ASC',
            skip: 0,
            limit: 20,
            search: debounceValue
          }
        }
      });
    }
  };

  const handleChange = (changeValue) => {
    setAutoCompleteValue(changeValue);
    if (changeValue) {
      const location = find(
        autoCompleteOptions,
        (item) => item?.id === changeValue
      );
      if (location) {
        setAutoCompleteValueObj(location);
        api
          ?.get('/map-api', {
            params: {
              address:
                location?.type === 'STATE'
                  ? location?.name
                  : `${location?.name} (${location?.state})`
            }
          })
          .then((response) => {
            const { lat, lng } =
              response?.results?.length > 0 &&
              response?.results?.[0]?.geometry?.location;
            setCityStateMarkerObj({ lat, lng });
          })
          .catch((error) => {
            message?.error(error?.response?.data?.message);
          });
      }
    }
    if (!changeValue) {
      setAutoCompleteValueObj(null);
      setIsData(false);
      setZip([]);
    }
  };

  const handleChangeAutoComplete = (inputValue) => {
    setScrollFlag(false);
    setSearchValue(inputValue);
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(getData, 500);
    searchDebounce(inputValue);
  };

  const handleAddZips = () => {
    if (value === 1) {
      setCityStateMarkerObj(null);
      setAutoCompleteValue(null);
      getZipCodes({
        variables: {
          filter: {
            latitude: markerObj?.lat?.toString(),
            longitude: markerObj?.lng?.toString(),
            radius: (sliderValue * 1609.34)?.toString(),
            addInZipCode: zipValue
          }
        }
      });
    } else {
      setMarkerObj(null);
      setPlacesValue(null);
      const location = find(
        autoCompleteOptions,
        (item) => item?.id === autoCompleteValue
      );
      if (location) {
        getZipCodes({
          variables: {
            filter: {
              placeName: location?.name,
              type: location?.type
            },
            where:
              autoCompleteValueObj?.type === 'STATE'
                ? undefined
                : {
                    state: autoCompleteValueObj?.state
                  }
          }
        });
      }
    }
  };

  const handleSubmitRegion = (formValue) => {
    let regionDataCopy = null;

    if (markerObj) {
      setValue(1);
      regionDataCopy = {
        radioValue: 1,
        addressValue: placesValue,
        radius: sliderValue,
        markerObj
      };
    } else {
      setValue(2);
      regionDataCopy = {
        radioValue: 2,
        selectValue: autoCompleteValueObj || null,
        markerObj: cityStateMarkerObj
      };
    }

    createRegion({
      variables: {
        data: {
          name: formValue?.regionName,
          zipCodes: zip,
          isAgain: !!onboardingData?.data?.region,
          regionData: regionDataCopy
        }
      }
    });
  };

  const handleClear = () => {
    setAutoCompleteOptions([]);
  };

  const regionTooltipContent = (
    <span className="line-height">
      In the Path.Pro the format <b>AA-##</b> is naturally used for sorting and
      is the recommended format for regions.&nbsp;
    </span>
  );

  return (
    <StepProcess>
      {!isLoaded ? (
        <LoaderComponent spinning={!isLoaded} />
      ) : (
        <div>
          <Form form={form} className="region" onFinish={handleSubmitRegion}>
            <RegionModal
              form={form}
              btnDisabled={btnDisabled}
              open={modalVisible}
              setModalVisible={setModalVisible}
              setZip={setZip}
              zip={zip}
              regionLoading={regionLoading}
              zipMarkers={zipMarkers}
              setZipMarkers={setZipMarkers}
            />
            <span className="steps-content-title custom-bottom-margin">
              Create A Region
            </span>
            <span className="steps-content-description">
              Add additional regions in the Admin portal.
            </span>
            <div className="map-component">
              {value === 1 ? (
                <GoogleMapComponent
                  radioValue={value}
                  loadError={loadError}
                  initialMarker={markerObj}
                  setMarkerObj={setMarkerObj}
                  setPlacesValue={setPlacesValue}
                  radius={sliderValue}
                  setZipValue={setZipValue}
                  zipMarkers={zipMarkers}
                  centerMap={centerMap}
                />
              ) : (
                <GoogleMapComponent
                  radioValue={value}
                  loadError={loadError}
                  cityStateInitialMarker={cityStateMarkerObj}
                  setCityStateMarkerObj={setCityStateMarkerObj}
                  setPlacesValue={setPlacesValue}
                  radius={sliderValue}
                  setZipValue={setZipValue}
                  cityStateZipMarkers={cityStateZipMarker}
                  centerMap={centerMap}
                />
              )}
            </div>

            <Radio.Group
              size="large"
              className="radio-button space-below"
              options={options}
              onChange={onChange}
              value={value}
              optionType="button"
              buttonStyle="solid"
            />
            <span className="steps-content-description">
              We're using the address of your headquarters you entered in the
              Account Setup.
            </span>
            <div className="d-flex flex-vertical align-left mt-8">
              <span className="region-label">
                Region Name &nbsp;
                <Popover
                  content={regionTooltipContent}
                  overlayClassName="region-name-tooltip"
                >
                  <InfoCircleOutlined />
                </Popover>
              </span>

              <Form.Item rules={[required]} name="regionName">
                <InputComponent
                  onChange={(e) => {
                    if (e?.target?.value?.length > 0) {
                      setBtnDisabled(false);
                    } else {
                      setBtnDisabled(true);
                    }
                  }}
                  className="field-width label-with-tooltip"
                  tooltip={{
                    title: regionTooltipContent,
                    icon: <InfoCircleOutlined />
                  }}
                  placeholder="For example: IL-00, IL-01, IL-03..."
                />
              </Form.Item>
            </div>
            {value === 1 && (
              <div>
                <div className="d-flex flex-vertical align-left">
                  <span className="region-label">Your Address</span>
                  <GoogleAutoComplete
                    setMarkerObj={setMarkerObj}
                    initialValue={placesValue}
                    setPlacesValue={setPlacesValue}
                    setZipValue={setZipValue}
                  />
                </div>
                <div className="slider-space d-flex align-left">
                  <Slider
                    className="slider-width"
                    min={1}
                    max={1000}
                    onChange={(slideValue) => setSliderValue(slideValue)}
                    value={sliderValue}
                  />
                  <InputNumber
                    type="number"
                    className="region-input-number region-slider-input"
                    min={1}
                    max={1000}
                    onChange={(slideValue) => setSliderValue(slideValue)}
                    value={sliderValue}
                  />
                  <span>Miles</span>
                </div>
                {stateList?.length > 5 && (
                  <span className="steps-content-description">
                    Your Region seems to be large. You can add additional
                    Regions in the Admin portal later.
                  </span>
                )}
              </div>
            )}
            {value === 2 && (
              <div className="region-step2">
                <div className="d-flex flex-vertical align-left">
                  <span className="region-label">State OR City OR County</span>
                </div>
                <SelectComponent
                  showSearch
                  className="select-width"
                  size="large"
                  value={autoCompleteValue}
                  placeholder="State OR City OR County"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleChangeAutoComplete}
                  onChange={handleChange}
                  onBlur={handleCallSelectValue}
                  onPopupScroll={onScroll}
                  onClear={handleClear}
                  notFoundContent={null}
                >
                  {map(autoCompleteOptions, (item) => (
                    <Option key={item?.id} value={item?.id}>
                      <span>
                        {item?.type === 'STATE'
                          ? item?.name
                          : `${item?.name} (${item?.state})`}
                        <Tag className="place-tag">{item?.type}</Tag>
                      </span>
                    </Option>
                  ))}
                </SelectComponent>
              </div>
            )}
            {zip?.length > 0 ? (
              <Popconfirm
                title="This will overwrite existing postal codes for this region. Do you still want to continue? "
                trigger="click"
                onConfirm={handleAddZips}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  className="submit-btn"
                  disabled={value === 1 ? !placesValue : !autoCompleteValue}
                  loading={zipLoading}
                >
                  Add
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type="primary"
                className="submit-btn"
                disabled={value === 1 ? !placesValue : !autoCompleteValue}
                loading={zipLoading}
                onClick={handleAddZips}
              >
                Add
              </Button>
            )}

            {isData && value === 1 && (
              <div className="field-width top-margin">
                {stateList?.length > 1 ? (
                  <span className="steps-content-description">
                    {zip?.length} default postal codes were found for your area
                    across {checkBoxValue?.length}
                    &nbsp;states - continue to accept these or click on the
                    <ExpandIconComponent className="icon-margin" />
                    to edit them.
                  </span>
                ) : (
                  <span className="steps-content-description">
                    {zip?.length} default postal codes were found for your area
                    - continue to accept these or click on the
                    <ExpandIconComponent className="icon-margin" />
                    to edit them.
                  </span>
                )}
                {stateList?.length > 1 && (
                  <Checkbox.Group
                    value={checkBoxValue}
                    onChange={onChangeCheckbox}
                  >
                    <Row className="text-left">
                      {map(stateList, (item) => {
                        if (item !== null && item !== '') {
                          return (
                            <Col
                              span={12}
                              key={item}
                              push={4}
                              className="checkbox-space"
                            >
                              <Checkbox
                                className="region-checkbox"
                                value={item}
                              >
                                {item}
                              </Checkbox>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Checkbox.Group>
                )}
              </div>
            )}
            {isData && value === 2 && (
              <div className="steps-content-description top-margin">
                {stateList?.length > 1 ? (
                  <span className="steps-content-description">
                    {zip?.length} default postal codes were found for your area
                    across {checkBoxValue?.length}
                    &nbsp;states - continue to accept these or click on the
                    <ExpandIconComponent className="icon-margin" />
                    to edit them.
                  </span>
                ) : (
                  <span className="steps-content-description">
                    {zip?.length} default postal codes were found for your area
                    - continue to accept these or click on the
                    <ExpandIconComponent className="icon-margin" />
                    to edit them.
                  </span>
                )}
              </div>
            )}
            <div className={`zip-section ${!isData ? 'justify-center' : ''}`}>
              {!isData && (
                <span className="no-zip">
                  You haven’t added any Postal codes yet.
                </span>
              )}
              {isData && (
                <div className="d-flex justify-between align-center fill-width">
                  <div>
                    {map(zip.slice(0, 7), (item) => (
                      <span key={item} className="zip-tags">
                        {displayZipCodes(item)}
                      </span>
                    ))}
                    {zip?.length > 7 && <span className="zip-dots">...</span>}
                  </div>
                  <div>
                    <span className="modal-icon">
                      <ExpandIconComponent
                        onClick={() => setModalVisible(true)}
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
            <Button
              disabled={!zip?.length || btnDisabled}
              type="primary"
              htmlType="submit"
              className="region-button submit-btn"
              loading={!modalVisible ? regionLoading : false}
            >
              Create & Continue to Package Addition
            </Button>
          </Form>
        </div>
      )}
    </StepProcess>
  );
};

export default Region;
