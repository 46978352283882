import { useLazyQuery } from '@apollo/client';
import { Button, Col, Divider, Empty, Form, Row } from 'antd';
import { debounce, map } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { REGEX, SKIP_RECORD } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { FETCH_STATE_CITY } from '../../Signup/graphql/Query';
import { CUSTOMER_TYPES, GET_USERS } from '../graphql/Queries';

let stateScrollDebounce;
let cityScrollDebounceJob;
let userScrollDebounceJob;
let CustomerTypesScrollDebounceJob;

const {
  required,
  email,
  requiredWhiteSpaceAllowed,
  zipCode,
  address,
  firstName,
  lastName,
  url: urlCheck
} = formValidatorRules;

const stateCityFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC'
};

const userFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'createdAt',
  sortBy: 'DESC',
  search: '',
  showCurrentUser: true
};

const CustomerTypesFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'order',
  sortBy: 'DESC',
  getDBField: []
};

const { Option } = SelectComponent;

const CustomerForm = (props) => {
  const { dispatch } = useContext(AppContext);
  const { customerData, handleSubmit, isSubmit } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(false);
  const [callAsync, setCallAsync] = useState(false);
  const [stateIsEnd, setStateIsEnd] = useState(false);
  const [cityIsEnd, setCityIsEnd] = useState(false);

  const [csmLoading, setCsmLoading] = useState(false);
  const [csmSearchFlag, setCsmSearchFlag] = useState(false);
  const [csmData, setCsmData] = useState([]);

  const [salesLoading, setSalesLoading] = useState(false);
  const [salesSearchFlag, setSalesSearchFlag] = useState(false);
  const [salesData, setSalesData] = useState([]);

  const [customerTypesLoading, setCustomerTypesLoading] = useState(false);
  const [customerTypesSearchFlag, setCustomerTypesSearchFlag] = useState(false);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);

  const [form] = Form?.useForm();

  const [getCustomerTypes] = useLazyQuery(CUSTOMER_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEnd(res?.customerTypes?.data?.length < SKIP_RECORD);
      if (customerTypesSearchFlag) {
        setCustomerTypes([...res?.customerTypes?.data]);
      } else {
        setCustomerTypes([...customerTypes, ...res?.customerTypes?.data]);
      }
      setCustomerTypesLoading(false);
    },
    onError() {
      setCustomerTypesLoading(false);
    }
  });

  const onCustomerTypesScroll = (event) => {
    setCustomerTypesSearchFlag(false);
    if (CustomerTypesScrollDebounceJob) {
      CustomerTypesScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    CustomerTypesScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setCustomerTypesLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getCustomerTypes({
          variables: {
            filter: {
              ...CustomerTypesFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
              search: searchValue
            },
            where: { isActive: true }
          }
        });
      }
    }, 500);

    CustomerTypesScrollDebounceJob();
  };

  const handleCustomerTypesChange = (value) => {
    setCustomerTypesSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCustomerTypesLoading(true);
      getCustomerTypes({
        variables: {
          filter: {
            ...CustomerTypesFilter,
            search: value
          }
        }
      });
    } else {
      setCustomerTypesLoading(true);
      getCustomerTypes({
        variables: {
          filter: {
            ...CustomerTypesFilter,
            search: value
          },
          where: { isActive: true }
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCustomerTypesHandler = useCallback(
    debounce(handleCustomerTypesChange, 500),
    []
  );

  const handleCustomerTypesClear = () => {
    form?.setFieldsValue({
      CustomerTypes: null
    });
    setCustomerTypes([]);
    getCustomerTypes({
      variables: { filter: CustomerTypesFilter, where: { isActive: true } }
    });
  };

  const handleCustomerTypesBlur = () => {
    setSearchValue('');
    setDebounceCall(0);
    setIsEnd(false);
  };

  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        setCityIsEnd(moreData?.length < SKIP_RECORD);
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
        setCityLoading(false);
      } else {
        setStateIsEnd(moreData?.length < SKIP_RECORD);
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setStateLoading(false);
        setCallAsync(false);
      }
    },
    onError() {
      setStateLoading(false);
      setCityLoading(false);
    }
  });

  const [csmUsers] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (csmSearchFlag) {
        setCsmData([...res?.users?.data]);
      } else {
        setCsmData([...csmData, ...res?.users?.data]);
      }
      setCsmLoading(false);
    },
    onError() {
      setCsmLoading(false);
    }
  });

  const [salesUsers] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (salesSearchFlag) {
        setSalesData([...res?.users?.data]);
      } else {
        setSalesData([...salesData, ...res?.users?.data]);
      }
      setSalesLoading(false);
    },
    onError() {
      setSalesLoading(false);
    }
  });

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: customerData?.state
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  useEffect(() => {
    csmUsers({
      variables: {
        filter: userFilter,
        where: {
          isActive: true
        }
      }
    });
    salesUsers({
      variables: {
        filter: userFilter,
        where: {
          isActive: true
        }
      }
    });
    getCustomerTypes({
      variables: {
        filter: CustomerTypesFilter,
        where: { isActive: true }
      }
    });
    if (!customerData?.state) {
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    if (customerData?.country) {
      setDisableState(false);
    }
    if (customerData?.state) {
      setDisableCity(false);
      setCitySearchFlag(true);
      setCallAsync(true);
      setSelectedState(customerData?.state);
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityBlur = () => {
    setSearchValue('');
    setCityIsEnd(false);
  };

  const handleStateBlur = () => {
    setStateIsEnd(false);
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState
        }
      }
    });
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !stateIsEnd) {
        setStateLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: states?.length,
              search: searchValue,
              type: 'STATE'
            }
          }
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (cityScrollDebounceJob) {
      cityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    cityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !cityIsEnd) {
        setCityLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: cities?.length,
              type: 'CITY',
              search: searchValue,
              state: selectedState
            }
          }
        });
      }
    }, 500);

    cityScrollDebounceJob();
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setStateLoading(true);
      setCities([]);
      setDisableCity(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value
          }
        }
      });
    } else {
      if (!state?.length) {
        setStateLoading(true);
        setDisableCity(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
      if (state?.length > 0) {
        setDisableCity(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter
          }
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        city: null
      });
      setSelectedState(value);
      setDisableCity(false);
      setCitySearchFlag(true);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value
          }
        }
      });
      setStateLoading(false);
    } else {
      setCitySearchFlag(true);
      setStateLoading(false);
      setDisableCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE'
          }
        }
      });
    }
  };

  const handleSelectCountry = (value) => {
    if (value) {
      setDisableState(false);
    } else {
      setDisableState(true);
    }
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCityLoading(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state')
          }
        }
      });
    } else {
      setCityLoading(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state')
          }
        }
      });
    }
  };

  const handleCountryClear = () => {
    form?.setFieldsValue({
      country: null,
      state: null,
      city: null
    });
    setDisableState(true);
    setDisableCity(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCityHandler = useCallback(debounce(handleCityChange, 500), []);

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null
    });
    setFetchCity(false);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter
      }
    });
    setSelectedState('');
    setCities([]);
    setDisableCity(true);
  };

  const onCsmScroll = (event) => {
    setCsmSearchFlag(false);
    if (userScrollDebounceJob) {
      userScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    userScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom) {
        setCsmLoading(true);
        csmUsers({
          variables: {
            filter: {
              ...userFilter,
              skip: csmData?.length,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    userScrollDebounceJob();
  };

  const handleCsmChange = (userValue) => {
    setCsmSearchFlag(true);
    setSearchValue(userValue);
    if (userValue) {
      setCsmLoading(true);
      csmUsers({
        variables: {
          filter: {
            ...userFilter,
            search: userValue
          }
        },
        where: {
          isActive: true
        }
      });
    } else {
      setCsmLoading(true);
      csmUsers({
        variables: {
          filter: {
            ...userFilter,
            search: userValue
          }
        },
        where: {
          isActive: true
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCsmHandler = useCallback(debounce(handleCsmChange, 500), []);

  const handleCsmClear = () => {
    form?.setFieldsValue({
      csmId: null
    });
    setCsmData([]);
    csmUsers({
      variables: {
        filter: userFilter,
        where: {
          isActive: true
        }
      }
    });
  };

  const handleCsmBlur = () => {
    setSearchValue('');
  };

  const onSalesScroll = (event) => {
    setSalesSearchFlag(false);
    if (userScrollDebounceJob) {
      userScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    userScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom) {
        setSalesLoading(true);
        salesUsers({
          variables: {
            filter: {
              ...userFilter,
              skip: salesData?.length,
              search: searchValue
            },
            where: {
              isActive: true
            }
          }
        });
      }
    }, 500);

    userScrollDebounceJob();
  };

  const handleSalesChange = (userValue) => {
    setSalesSearchFlag(true);
    setSearchValue(userValue);
    if (userValue) {
      setSalesLoading(true);
      salesUsers({
        variables: {
          filter: {
            ...userFilter,
            search: userValue
          }
        },
        where: {
          isActive: true
        }
      });
    } else {
      setSalesLoading(true);
      salesUsers({
        variables: {
          filter: {
            ...userFilter,
            search: userValue
          }
        },
        where: {
          isActive: true
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSalesHandler = useCallback(
    debounce(handleSalesChange, 500),
    []
  );

  const handleSalesClear = () => {
    form?.setFieldsValue({
      salesManagerId: null
    });
    setSalesData([]);
    salesUsers({
      variables: {
        filter: userFilter,
        where: {
          isActive: true
        }
      }
    });
  };

  const handleSalesBlur = () => {
    setSearchValue('');
  };

  return (
    <div>
      <Portal portalId="header-right-content">
        <Button
          className="common-button discard-button"
          icon={<DeleteIconComponent />}
          size="small"
          id="customer-table-discard-btn"
          onClick={() => history?.goBack()}
        >
          Discard Changes
        </Button>
        <Button
          className="common-button"
          icon={<img src={SaveIcon} alt="save-icon" width={12} />}
          size="small"
          htmlType="submit"
          id="customer-table-save-btn"
          loading={isSubmit}
          type="primary"
          onClick={form?.submit}
        >
          Save
        </Button>
      </Portal>
      <Form
        form={form}
        initialValues={customerData}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Row gutter={16} className="required-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[
                { ...required, message: 'Please Enter First Name' },
                firstName
              ]}
              name="firstName"
              label="First Name"
            >
              <InputComponent allowClear placeholder="Enter First name" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[
                { ...required, message: 'Please Enter Last Name' },
                lastName
              ]}
              name="lastName"
              label="Last Name"
            >
              <InputComponent allowClear placeholder="Enter Last Name" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              label="Contact"
              name="phoneNo"
              rules={[
                { ...required, message: 'Please Enter Contact' },
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'should be a valid phone number'
                        );
                      }
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="(___) ___-____"
                allowClear
              >
                {(inputProps) => <InputComponent {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              rules={[{ ...required, message: 'Please Enter Email' }, email]}
              name="email"
              label="Email"
            >
              <InputComponent allowClear placeholder="Enter Email" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="isActive"
              label="Status"
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Status'
                }
              ]}
            >
              <SelectComponent placeholder="Select Status" allowClear>
                <Option key="active" value>
                  Active
                </Option>
                <Option key="inactive" value={false}>
                  InActive
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="addressLine1"
              label="Address"
              rules={[
                { ...required, message: 'Please Enter Address' },
                address
              ]}
            >
              <InputComponent allowClear placeholder="Enter Address" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ ...required, message: 'Please Select Country' }]}
            >
              <SelectComponent
                placeholder="Select Country"
                onSelect={handleSelectCountry}
                onClear={handleCountryClear}
                allowClear
              >
                <Option key="USA" value="USA">
                  USA
                </Option>
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ ...required, message: 'Please Select State' }]}
            >
              <SelectComponent
                placeholder="Select State"
                disabled={disableState}
                allowClear
                notFoundContent={
                  stateLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSearch={debouncedStateHandler}
                onClear={handleStateClear}
                onSelect={handleStateSelect}
                onPopupScroll={onStateScroll}
                onBlur={handleStateBlur}
              >
                {map(states, (state) => (
                  <Option key={state?.id} value={state?.name}>
                    {state?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ ...required, message: 'Please Select City' }]}
            >
              <SelectComponent
                placeholder="Select City"
                disabled={disableCity}
                notFoundContent={
                  cityLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSearch={debouncedCityHandler}
                onPopupScroll={onCityScroll}
                onClear={handleCityClear}
                onBlur={handleCityBlur}
              >
                {cities?.map((city) => (
                  <Option key={city?.id} value={city?.name}>
                    {city?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="zipCode"
              rules={[
                zipCode,
                { ...required, message: 'Please Enter Postal Code' }
              ]}
              label="Postal Code"
            >
              <NumberComponent placeholder="Enter Postal code" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <span className="form-divider-text optional-divider">OPTIONAL</span>
          <Divider className="form-divider optional-divider" />
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="customerType" label="Type">
              <SelectComponent
                placeholder="Select Customer Type"
                allowClear
                notFoundContent={
                  customerTypesLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleCustomerTypesBlur}
                onSearch={debouncedCustomerTypesHandler}
                onClear={handleCustomerTypesClear}
                onPopupScroll={onCustomerTypesScroll}
              >
                {map(customerTypes, (item) => (
                  <Option key={item?.key} value={item?.key}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="fax"
              label="Fax"
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('should be a valid fax number');
                      }
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="(___) ___-____"
                allowClear
              >
                {(inputProps) => <InputComponent {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item
              name="tollFree"
              label="Toll Free"
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'should be a valid toll free number'
                        );
                      }
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="(___) ___-____"
                allowClear
              >
                {(inputProps) => <InputComponent {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="web" label="Web" rules={[urlCheck]}>
              <InputComponent allowClear placeholder="Enter Web URL" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="csmId" label="Customer Service Manager">
              <SelectComponent
                placeholder="Select Customer Service Manager"
                allowClear
                notFoundContent={
                  csmLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleCsmBlur}
                onSearch={debouncedCsmHandler}
                onClear={handleCsmClear}
                onPopupScroll={onCsmScroll}
              >
                {map(csmData, (item) => (
                  <Option key={item?.id} value={item?.id}>
                    {`${item?.firstName} ${item?.lastName}`}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="salesManagerId" label="Sales Manager">
              <SelectComponent
                placeholder="Select Sales Manager"
                allowClear
                notFoundContent={
                  salesLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleSalesBlur}
                onSearch={debouncedSalesHandler}
                onClear={handleSalesClear}
                onPopupScroll={onSalesScroll}
              >
                {map(csmData, (item) => (
                  <Option key={item?.id} value={item?.id}>
                    {`${item?.firstName} ${item?.lastName}`}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="technician" label="Technician">
              <InputComponent allowClear placeholder="Enter Technician" />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <Form.Item name="industry" label="Industry">
              <InputComponent allowClear placeholder="Enter Industry" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="descriptionBusiness"
              label="Description Of Business"
            >
              <EditorComponent placeholder="Enter Description of Business" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CustomerForm;
