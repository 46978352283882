import { CheckCircleTwoTone } from '@ant-design/icons';
import { Badge, Col, Divider, Row, Tabs } from 'antd';
import { isArray, isNull } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  DEFAULT_DATE_FORMAT,
  DYNAMIC_FIELDS_DATA_KEYS
} from '../../../common/constants';
import {
  formatDate,
  formatPhoneNumber,
  formatPrice
} from '../../../common/utils';
import RenderUploadPreview from '../../productItems/components/RenderUploadPreview';
import './QuoteViewerDetail.less';

const renderItems = (data) => {
  const { quotes = [], suggestedQuotes = {} } = data;

  return quotes?.map((item) => {
    return {
      key: item?.id,
      label: (
        <div>
          <span className="card-title">{item?.lineOfBusiness}</span>
        </div>
      ),
      children: (
        <>
          <Row className="content-section quote-viewer-detail">
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="field-detail">
                <span className="field-label">Selected Package</span>
                <span className="field-value">{item?.package || '-'}</span>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">SKU</span>
                <span className="field-value">{item?.sku || '-'}</span>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">
                  Manufacturer(Primary Product)
                </span>
                <span className="field-value">{item?.manufacturer || '-'}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Quote Amt.</span>
                <span className="field-value">
                  {formatPrice(item?.amount || 0) || '-'}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Package Options</span>
                <span className="field-value">{item?.quality || '-'}</span>
              </div>
            </Col>

            {isArray(item?.customFields) &&
              item?.customFields?.length > 0 &&
              item?.customFields
                ?.filter(
                  //
                  (items) =>
                    items?.fieldType !== DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD &&
                    !items?.value?.[0]?.file?.uid
                )
                ?.map((items) => {
                  if (items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN) {
                    return (
                      <Col
                        key={items?.id}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={6}
                        xxl={6}
                      >
                        <div className="field-detail">
                          <span className="field-label">{items?.label}</span>
                          <span className="field-value">
                            {items?.value?.[0]?.blockId
                              ? items?.value?.[0]?.label
                              : items?.valueLabel || '-'}
                          </span>
                        </div>
                      </Col>
                    );
                  }
                  if (
                    items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST
                  ) {
                    return (
                      <Col
                        key={items?.id}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={6}
                        xxl={6}
                      >
                        <div className="field-detail">
                          <span className="field-label">{items?.label}</span>
                          <span className="pick-list-field-value">
                            {items?.value
                              ?.map((pickListData) => {
                                return pickListData?.label;
                              })
                              .join(' , ')}
                          </span>
                        </div>
                      </Col>
                    );
                  }
                  return (
                    <Col
                      key={items?.id}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={6}
                      xxl={6}
                    >
                      <div className="field-detail">
                        <span className="field-label">{items?.label}</span>
                        <span className="field-value">
                          {items?.value?.[0]?.uuid
                            ? items?.value?.[0]?.label
                            : items?.value?.[0] || '-'}
                        </span>
                      </div>
                    </Col>
                  );
                })}
            {item?.customFields
              ?.filter(
                (items) => items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD
              )
              ?.map((items) => {
                return (
                  <Col
                    key={items?.id}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={6}
                    xxl={6}
                  >
                    <div key={items?.label} className="field-detail">
                      <span className="field-label">{items?.label}</span>
                      <RenderUploadPreview item={items} isQuoteDetail />
                    </div>
                  </Col>
                );
              })}
            {isArray(item?.systemFields) &&
              item?.systemFields?.length > 0 &&
              item?.systemFields?.map((items) => {
                if (items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN) {
                  return (
                    <Col
                      key={items?.id}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={6}
                      xxl={6}
                    >
                      <div className="field-detail">
                        <span className="field-label">{items?.label}</span>
                        <span className="field-value">
                          {items?.value?.[0]?.blockId
                            ? items?.value?.[0]?.label
                            : items?.valueLabel || '-'}
                        </span>
                      </div>
                    </Col>
                  );
                }
                if (items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST) {
                  return (
                    <Col
                      key={items?.id}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={6}
                      xxl={6}
                    >
                      <div className="field-detail">
                        <span className="field-label">{items?.label}</span>
                        <span className="pick-list-field-value">
                          {items?.value
                            ?.map((pickListData) => {
                              return pickListData?.label;
                            })
                            .join(' , ')}
                        </span>
                      </div>
                    </Col>
                  );
                }
                return (
                  <Col
                    key={items?.id}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={6}
                    xxl={6}
                  >
                    <div className="field-detail">
                      <span className="field-label">{items?.label}</span>
                      <span className="field-value">
                        {items?.value?.[0]?.blockId
                          ? items?.value?.[0]?.label
                          : items?.value?.[0] || '-'}
                      </span>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <Row className="required-row">
            <span className="form-divider-text">Suggested Package</span>
            <Divider className="form-divider" />
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">
                  {`Good ${
                    item?.package === suggestedQuotes?.good?.title
                      ? '(Selected)'
                      : ''
                  }`}
                </span>
                <span className="field-value">
                  {suggestedQuotes?.good?.title || '-'}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">
                  {`Better ${
                    item?.package === suggestedQuotes?.better?.title
                      ? '(Selected)'
                      : ''
                  }`}
                </span>
                <span className="field-value">
                  {suggestedQuotes?.better?.title || '-'}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">
                  {`Best ${
                    item?.package === suggestedQuotes?.best?.title
                      ? '(Selected)'
                      : ''
                  }`}
                </span>
                <span className="field-value">
                  {suggestedQuotes?.best?.title || '-'}
                </span>
              </div>
            </Col>
          </Row>
        </>
      )
    };
  });
};

function QuoteViewerDetail({ data = [] }) {
  const [scheduleTime, setScheduleTime] = useState('-');
  const [address, setAddress] = useState('-');

  useEffect(() => {
    if (!isNull(data?.scheduleTime)) {
      if (data?.scheduleTime?.startTime) {
        setScheduleTime(
          `${moment(data?.scheduleTime?.date)?.format(
            DEFAULT_DATE_FORMAT
          )} ${`${
            data?.scheduleTime?.startTime
              ? moment(data?.scheduleTime?.startTime)?.format('h:mm a')
              : ''
          } - ${
            data?.scheduleTime?.endTime
              ? moment(data?.scheduleTime?.endTime)?.format('h:mm a')
              : ''
          } `}`
        );
      } else {
        setScheduleTime(
          `${moment(data?.scheduleTime?.date)?.format(DEFAULT_DATE_FORMAT)}`
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.scheduleTime]);

  useEffect(() => {
    if (
      !isNull(data?.location?.addressLine1) ||
      !isNull(data?.location?.city) ||
      !isNull(data?.location?.state) ||
      !isNull(data?.location?.zipCode)
    ) {
      setAddress(
        `${data?.location?.addressLine1 ? data?.location?.addressLine1 : ''} ${
          data?.location?.city ? `${data?.location?.city},` : ''
        } ${data?.location?.state ? data?.location?.state : ''} ${
          data?.location?.country ? data?.location?.country : ''
        } ${data?.location?.zipCode ? data?.location?.zipCode : ''}`
      );
    } else {
      setAddress('-');
    }
  }, [data?.location]);

  return (
    <div className="quote-viewer">
      <div className="content-section  quote-viewer-detail">
        <Row className="required-row">
          <span className="form-divider-text">Customer</span>
          <Divider className="form-divider" />

          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Name</span>
              <span className="field-value">{data?.customerName || '-'}</span>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={18}>
            <div className="field-detail">
              <span className="field-label">Address</span>
              <span className="field-value">{address}</span>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Email</span>
              <span className="field-value">{data?.email || '-'}</span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Phone number</span>
              <span className="field-value">
                {formatPhoneNumber(data?.phoneNumber) || '-'}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="required-row">
          <span className="form-divider-text">Order</span>
          <Divider className="form-divider" />
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Date Time</span>
              <span className="field-value">
                {formatDate(data?.createdAt) || '-'}
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Retailers</span>
              <span className="field-value">{data?.brand?.name || '-'}</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Region</span>
              <span className="field-value">{data?.region?.name || '-'}</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Industry</span>
              <span className="field-value">
                {data?.industry?.label || '-'}
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Appointment Request</span>
              <span className="field-value">{scheduleTime || '-'}</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Email Confirmation</span>
              <span className="field-value">
                {data?.emailMeInfo?.emailConfirmation ? 'Yes' : 'No' || '-'}
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Urgent</span>
              <span className="field-value">
                {data?.emailMeInfo?.urgent ? 'Yes' : 'No' || '-'}
              </span>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Optional Comments</span>
              <span className="field-value">{data?.comments || '-'}</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Export Status</span>
              <span className="field-value">
                {data?.exportStatus === 'SUCCESS' ? (
                  <div>
                    <CheckCircleTwoTone twoToneColor="#52C41A" />
                    <span className="success-color">Success</span>
                  </div>
                ) : (
                  <Badge
                    color="#FF8845"
                    text="Pending"
                    className="pending-color"
                  />
                )}
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="card-container">
        {data?.quotes && (
          <Tabs
            type="card"
            className="quote-viewer-tabs"
            items={renderItems(data || {})}
          />
        )}
      </div>
    </div>
  );
}

export default QuoteViewerDetail;
