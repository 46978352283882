import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Drawer, Form, Popover, Switch, message } from 'antd';
import { findIndex, isEmpty, kebabCase, map, omit, snakeCase } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ReactComponent as ManageConditionIcon } from '../../../assets/manage-condition.svg';
import SaveIcon from '../../../assets/save.svg';
import { ReactComponent as WidgetIcon } from '../../../assets/widget.svg';
import {
  DYNAMIC_FIELDS_DATA_KEYS,
  QUESTIONNAIRE_TYPE,
  ROUTES,
  WIDGET_TYPE
} from '../../../common/constants';
import {
  checkPermissions,
  formValidatorRules,
  uploadFile,
  uploadImageNormalize
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import { CREATE_PAGE_CONFIG, UPDATE_PAGE_CONFIG } from '../graphql/Mutations';
import RenderWidget from './RenderWidget';
import SkipPageModal from './SkipPageModal';

const { required } = formValidatorRules;

const BuilderFormComponent = ({
  match: { params: { id = null, pageKey = null } = {} } = {},
  location: { pathname },
  pageConfigProp,
  widgetData = null,
  widgetLoading = false,
  refetchPageConfig,
  refetchPageSequence,
  setFormValues,
  formValues = null,
  setSelectedLob,
  selectedLob = null,
  setSelectedTab,
  selectedTab = '',
  selectedAppointmentKey = '',
  setSelectedAppointmentKey,
  fieldObject = null,
  setFieldObject,
  systemFieldData = null,
  setSystemFieldData,
  checkedList = [],
  setCheckedList,
  pageSequencesData = [],
  refetchSystemFields = false,
  setRefetchSystemFields,
  setSelectedDimensionTab,
  selectedDimensionTab = null,
  setSelectedDimensionProject,
  selectedDimensionProject = null,
  fetchTenantData,
  type = QUESTIONNAIRE_TYPE?.ROQ_BUILDER,
  formLoading = false,
  setFormLoading,
  location = null
}) => {
  const {
    state: { permissions, currentUser, cpqQuestionObject },
    dispatch
  } = useContext(AppContext);
  const findPageIndex = findIndex(
    pageSequencesData,
    (data) => data?.pageKey === snakeCase(pageKey)?.toUpperCase()
  );

  const [form] = Form?.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [widgetInfo, setWidgetInfo] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [titleChanged, setTitleChanged] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [checkedSkipPages, setCheckedSkipPages] = useState([]);
  const [skipPageConditions, setSkipPageConditions] = useState([]);
  const [isAlwaysSkipEnabled, setIsAlwaysSkipEnabled] = useState(false);
  const [skipPageList, setSkipPageList] = useState([]);

  const setPageConfig = () => {
    const selectedWidget = pageConfigProp?.widgetType;
    if (selectedWidget) {
      setWidgetInfo({
        widgetType: pageConfigProp?.widgetType,
        widgetId: pageConfigProp?.widgetId,
        deletable: pageConfigProp?.allowDrag
      });
    }
  };

  useEffect(() => {
    if (findPageIndex !== -1) {
      const tempArray = [];
      map(skipPageConditions, (item) => {
        if (item?.check) {
          tempArray?.push(
            pageSequencesData?.find((items) => items?.id === item?.identifier)
              ?.pageConfiguration?.title
          );
        }
      });
      setSkipPageList(tempArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findPageIndex, skipPageConditions]);

  useEffect(() => {
    setPageConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (formLoading) {
      setSystemFieldData(pageConfigProp?.widgetConfiguration);
      setFieldObject({
        selectedSystemFieldType:
          pageConfigProp?.widgetConfiguration?.selectedSystemFieldBlock
            ?.selectedSystemFieldType,
        selectedSystemFieldLabel:
          pageConfigProp?.widgetConfiguration?.selectedSystemFieldBlock
            ?.selectedSystemFieldLabel,
        selectedSystemFieldId:
          pageConfigProp?.widgetConfiguration?.selectedSystemFieldBlock
            ?.selectedSystemFieldId
      });

      if (pageConfigProp?.widgetConfiguration?.project === 'STAIRS') {
        setSelectedDimensionTab('STEPS');
      } else {
        setSelectedDimensionTab('MANUAL_ENTRY');
      }

      const initialValuesCopy = {
        ...pageConfigProp,
        headerImage: pageConfigProp?.headerImage?.url
          ? [pageConfigProp?.headerImage]
          : [],
        ...(pageConfigProp?.widgetConfiguration?.config?.listItems?.length >
          0 && {
          widgetConfiguration: {
            ...pageConfigProp?.widgetConfiguration,
            config: {
              ...pageConfigProp?.widgetConfiguration?.config,
              listItems: map(
                pageConfigProp?.widgetConfiguration?.config?.listItems,
                (item) => {
                  if (!item?.image?.url) {
                    return omit(item, ['image']);
                  }
                  return { ...item, image: [item?.image] };
                }
              )
            }
          }
        }),
        ...(pageConfigProp?.widgetConfiguration?.lowCostOption && {
          widgetConfiguration: {
            ...pageConfigProp?.widgetConfiguration,
            lowCostOption: {
              ...pageConfigProp?.widgetConfiguration?.lowCostOption,
              quoteVariantIcon: pageConfigProp?.widgetConfiguration
                ?.lowCostOption?.quoteVariantIcon?.url
                ? [
                    pageConfigProp?.widgetConfiguration?.lowCostOption
                      ?.quoteVariantIcon
                  ]
                : [],
              marketingBlock: {
                ...pageConfigProp?.widgetConfiguration?.lowCostOption
                  ?.marketingBlock,
                icon: pageConfigProp?.widgetConfiguration?.lowCostOption
                  ?.marketingBlock?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.lowCostOption
                        ?.marketingBlock?.icon
                    ]
                  : []
              },
              price: {
                ...pageConfigProp?.widgetConfiguration?.lowCostOption?.price,
                icon: pageConfigProp?.widgetConfiguration?.lowCostOption?.price
                  ?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.lowCostOption?.price
                        ?.icon
                    ]
                  : []
              }
            },
            premiumOption: {
              ...pageConfigProp?.widgetConfiguration?.premiumOption,
              quoteVariantIcon: pageConfigProp?.widgetConfiguration
                ?.premiumOption?.quoteVariantIcon?.url
                ? [
                    pageConfigProp?.widgetConfiguration?.premiumOption
                      ?.quoteVariantIcon
                  ]
                : [],
              marketingBlock: {
                ...pageConfigProp?.widgetConfiguration?.premiumOption
                  ?.marketingBlock,
                icon: pageConfigProp?.widgetConfiguration?.premiumOption
                  ?.marketingBlock?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.premiumOption
                        ?.marketingBlock?.icon
                    ]
                  : []
              },
              price: {
                ...pageConfigProp?.widgetConfiguration?.premiumOption?.price,
                icon: pageConfigProp?.widgetConfiguration?.premiumOption?.price
                  ?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.premiumOption?.price
                        ?.icon
                    ]
                  : []
              }
            },
            recommendedOption: {
              ...pageConfigProp?.widgetConfiguration?.recommendedOption,
              quoteVariantIcon: pageConfigProp?.widgetConfiguration
                ?.recommendedOption?.quoteVariantIcon?.url
                ? [
                    pageConfigProp?.widgetConfiguration?.recommendedOption
                      ?.quoteVariantIcon
                  ]
                : [],
              marketingBlock: {
                ...pageConfigProp?.widgetConfiguration?.recommendedOption
                  ?.marketingBlock,
                icon: pageConfigProp?.widgetConfiguration?.recommendedOption
                  ?.marketingBlock?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.recommendedOption
                        ?.marketingBlock?.icon
                    ]
                  : []
              },
              price: {
                ...pageConfigProp?.widgetConfiguration?.recommendedOption
                  ?.price,
                icon: pageConfigProp?.widgetConfiguration?.recommendedOption
                  ?.price?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.recommendedOption
                        ?.price?.icon
                    ]
                  : []
              }
            }
          }
        }),
        ...(pageConfigProp?.widgetConfiguration?.otherConfig && {
          widgetConfiguration: {
            ...pageConfigProp?.widgetConfiguration,
            ...(pageConfigProp?.widgetConfiguration?.otherConfig?.length >
              0 && {
              otherConfig: map(
                pageConfigProp?.widgetConfiguration?.otherConfig,
                (item) => {
                  const itemClone = {
                    ...item,
                    blockDescription:
                      item?.description || item?.blockDescription
                  };
                  if (itemClone?.logo) {
                    if (!itemClone?.logo?.url) {
                      return omit(itemClone, ['logo']);
                    }
                    return { ...itemClone, logo: [itemClone?.logo] };
                  }
                  if (!itemClone?.image?.url) {
                    return omit(itemClone, ['image']);
                  }
                  return {
                    ...itemClone,
                    image: [itemClone?.image]
                  };
                }
              )
            })
          }
        }),
        ...(pageConfigProp?.widgetConfiguration?.common && {
          widgetConfiguration: {
            ...pageConfigProp?.widgetConfiguration,
            common: {
              ...pageConfigProp?.widgetConfiguration?.common,
              highlightYourArea: {
                ...pageConfigProp?.widgetConfiguration?.common
                  ?.highlightYourArea,
                icon: pageConfigProp?.widgetConfiguration?.common
                  ?.highlightYourArea?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.common
                        ?.highlightYourArea?.icon
                    ]
                  : []
              }
            },
            stairs: {
              ...pageConfigProp?.widgetConfiguration?.stairs,
              steps: {
                ...pageConfigProp?.widgetConfiguration?.stairs?.steps,
                picture: pageConfigProp?.widgetConfiguration?.stairs?.steps
                  ?.picture?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.stairs?.steps
                        ?.picture
                    ]
                  : []
              },
              totalRise: {
                ...pageConfigProp?.widgetConfiguration?.stairs?.totalRise,
                image: pageConfigProp?.widgetConfiguration?.stairs?.totalRise
                  ?.image?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.stairs?.totalRise
                        ?.image
                    ]
                  : []
              }
            }
          }
        }),
        ...(pageConfigProp?.widgetConfiguration?.address && {
          widgetConfiguration: {
            ...pageConfigProp?.widgetConfiguration,
            address: {
              ...pageConfigProp?.widgetConfiguration?.address,
              icon: pageConfigProp?.widgetConfiguration?.address?.icon?.url
                ? [pageConfigProp?.widgetConfiguration?.address?.icon]
                : []
            },
            availability: {
              ...pageConfigProp?.widgetConfiguration?.availability,
              icon: pageConfigProp?.widgetConfiguration?.availability?.icon?.url
                ? [pageConfigProp?.widgetConfiguration?.availability?.icon]
                : []
            },
            reserveAppointment: {
              ...pageConfigProp?.widgetConfiguration?.reserveAppointment,
              icon: pageConfigProp?.widgetConfiguration?.reserveAppointment
                ?.icon?.url
                ? [
                    pageConfigProp?.widgetConfiguration?.reserveAppointment
                      ?.icon
                  ]
                : [],
              creditCard: {
                ...pageConfigProp?.widgetConfiguration?.reserveAppointment
                  ?.creditCard,
                icon: pageConfigProp?.widgetConfiguration?.reserveAppointment
                  ?.creditCard?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.reserveAppointment
                        ?.creditCard?.icon
                    ]
                  : []
              },
              contactInfo: {
                ...pageConfigProp?.widgetConfiguration?.reserveAppointment
                  ?.contactInfo,
                icon: pageConfigProp?.widgetConfiguration?.reserveAppointment
                  ?.contactInfo?.icon?.url
                  ? [
                      pageConfigProp?.widgetConfiguration?.reserveAppointment
                        ?.contactInfo?.icon
                    ]
                  : []
              }
            }
          }
        }),
        ...(pageConfigProp?.widgetConfiguration?.config?.fileType && {
          widgetConfiguration: {
            ...pageConfigProp?.widgetConfiguration,
            config: {
              ...pageConfigProp?.widgetConfiguration?.config,
              fileType:
                pageConfigProp?.widgetConfiguration?.config?.fileType?.key
            }
          }
        }),
        ...(pageConfigProp?.widgetConfiguration?.fieldType ===
          DYNAMIC_FIELDS_DATA_KEYS?.DATE_TIME && {
          widgetConfiguration: {
            ...pageConfigProp?.widgetConfiguration,
            config: {
              ...pageConfigProp?.widgetConfiguration?.config,
              dateTimeType: {
                DATE: pageConfigProp?.widgetConfiguration?.config?.dateTimeType?.includes(
                  'DATE'
                ),
                TIME: pageConfigProp?.widgetConfiguration?.config?.dateTimeType?.includes(
                  'TIME'
                )
              },
              allowDateTime: {
                ALLOW_PAST: pageConfigProp?.widgetConfiguration?.config?.allowDateTime?.includes(
                  'ALLOW_PAST'
                ),
                ALLOW_FUTURE: pageConfigProp?.widgetConfiguration?.config?.allowDateTime?.includes(
                  'ALLOW_FUTURE'
                )
              },
              dateConfig: pageConfigProp?.widgetConfiguration?.config
                ?.dateConfig?.exactDate
                ? 'EXACT'
                : 'RANGE',
              ...(pageConfigProp?.widgetConfiguration?.config
                ?.timeDefaultValue && {
                timeDefaultValue: [
                  moment(
                    pageConfigProp?.widgetConfiguration?.config
                      ?.timeDefaultValue?.[0]
                  ),
                  moment(
                    pageConfigProp?.widgetConfiguration?.config
                      ?.timeDefaultValue?.[1]
                  )
                ]
              })
            }
          }
        })
      };
      if (pageConfigProp?.widgetConfiguration?.config?.fileType) {
        setCheckedList([
          ...pageConfigProp?.widgetConfiguration?.config?.fileType?.value
        ]);
      }
      setSkipPageConditions(
        pageConfigProp?.skipPageConditions?.data ||
          pageConfigProp?.skipPageConditions
      );
      setIsAlwaysSkipEnabled(
        pageConfigProp?.skipPageConditions?.alwaysSkip || false
      );
      setInitialValues(initialValuesCopy);
      setFormLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formLoading]);

  const [createPageConfig] = useMutation(CREATE_PAGE_CONFIG, {
    onCompleted() {
      setBtnLoading(false);
    },
    onError() {
      setPageConfig();
      setBtnLoading(false);
    }
  });

  const [updatePageConfig] = useMutation(UPDATE_PAGE_CONFIG, {
    onCompleted() {
      setBtnLoading(false);
    },
    onError() {
      setPageConfig();
      setBtnLoading(false);
    }
  });

  const onFinishFailed = (values) => {
    if (values?.errorFields?.length > 0) {
      setShowDrawer(
        values?.errorFields?.[0]?.name?.includes('widgetConfiguration')
      );
    }
    setValidationTriggered(true);
  };

  const onFinish = async (values) => {
    if (!widgetInfo?.widgetType) {
      message?.error('Please select widget!');
      return;
    }
    if (values?.widgetConfiguration?.config?.fileType && isEmpty(checkedList)) {
      setValidationTriggered(true);
      return;
    }

    const questionnaireRoute =
      type === QUESTIONNAIRE_TYPE?.ROQ_BUILDER
        ? ROUTES?.QUESTIONNAIRES_PRIMARY
        : ROUTES?.QUESTIONNAIRES_SECONDARY;

    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setDisableBtn(true);
    setBtnLoading(true);
    let headerImage = null;
    let finalConfig = [];
    let pickListFinalConfig = [];
    let lowQuoteIcon = null;
    let lowPriceIcon = null;
    let lowMarketingIcon = null;
    let recommendedQuoteIcon = null;
    let recommendedPriceIcon = null;
    let recommendedMarketingIcon = null;
    let premiumQuoteIcon = null;
    let premiumPriceIcon = null;
    let premiumMarketingIcon = null;
    let addressIcon = null;
    let availabilityIcon = null;
    let reserveAppointmentIcon = null;
    let creditCardIcon = null;
    let contactInfoIcon = null;
    let stepsImage = null;
    let totalRiseImage = null;
    let highLightAreaImage = null;
    let isSystemTextWidget = false;
    const dateTimeType = [];
    const allowDateTime = [];

    if (
      values?.widgetConfiguration?.selectedSystemFieldBlock &&
      systemFieldData?.fieldConfig
    ) {
      // checking if widget id systemTextWidget or not as we have to send text type(singleLine,multiline,richText) to BE
      isSystemTextWidget = Object?.prototype?.hasOwnProperty?.call(
        systemFieldData?.fieldConfig,
        'TEXT'
      );
    }
    if (values?.headerImage?.length > 0 && !values?.headerImage?.[0]?.url) {
      headerImage = await uploadFile({
        logoObject: values?.headerImage?.[0],
        pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}`
      });
    }

    if (values?.widgetConfiguration?.address) {
      if (
        !isEmpty(values?.widgetConfiguration?.address?.icon) &&
        !values?.widgetConfiguration?.address?.icon?.[0]?.url
      ) {
        addressIcon = await uploadFile({
          logoObject: values?.widgetConfiguration?.address?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
    }

    if (values?.widgetConfiguration?.availability) {
      if (
        !isEmpty(values?.widgetConfiguration?.availability?.icon) &&
        !values?.widgetConfiguration?.availability?.icon?.[0]?.url
      ) {
        availabilityIcon = await uploadFile({
          logoObject: values?.widgetConfiguration?.availability?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
    }

    if (values?.widgetConfiguration?.reserveAppointment) {
      if (
        !isEmpty(values?.widgetConfiguration?.reserveAppointment?.icon) &&
        !values?.widgetConfiguration?.reserveAppointment?.icon?.[0]?.url
      ) {
        reserveAppointmentIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.reserveAppointment?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
      if (
        !isEmpty(
          values?.widgetConfiguration?.reserveAppointment?.creditCard?.icon
        ) &&
        !values?.widgetConfiguration?.reserveAppointment?.creditCard?.icon?.[0]
          ?.url
      ) {
        creditCardIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.reserveAppointment?.creditCard
              ?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
      if (
        !isEmpty(
          values?.widgetConfiguration?.reserveAppointment?.contactInfo?.icon
        ) &&
        !values?.widgetConfiguration?.reserveAppointment?.contactInfo?.icon?.[0]
          ?.url
      ) {
        contactInfoIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.reserveAppointment?.contactInfo
              ?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
    }

    if (values?.widgetConfiguration?.lowCostOption) {
      if (
        !isEmpty(
          values?.widgetConfiguration?.lowCostOption?.quoteVariantIcon
        ) &&
        !values?.widgetConfiguration?.lowCostOption?.quoteVariantIcon?.[0]?.url
      ) {
        lowQuoteIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.lowCostOption?.quoteVariantIcon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
      if (
        !isEmpty(values?.widgetConfiguration?.lowCostOption?.price?.icon) &&
        !values?.widgetConfiguration?.lowCostOption?.price?.icon?.[0]?.url
      ) {
        lowPriceIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.lowCostOption?.price?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}`
        });
      }
      if (
        !isEmpty(
          values?.widgetConfiguration?.lowCostOption?.marketingBlock?.icon
        ) &&
        !values?.widgetConfiguration?.lowCostOption?.marketingBlock?.icon?.[0]
          ?.url
      ) {
        lowMarketingIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.lowCostOption?.marketingBlock
              ?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}`
        });
      }
    }

    if (values?.widgetConfiguration?.recommendedOption) {
      if (
        !isEmpty(
          values?.widgetConfiguration?.recommendedOption?.quoteVariantIcon
        ) &&
        !values?.widgetConfiguration?.recommendedOption?.quoteVariantIcon?.[0]
          ?.url
      ) {
        recommendedQuoteIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.recommendedOption
              ?.quoteVariantIcon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
      if (
        !isEmpty(values?.widgetConfiguration?.recommendedOption?.price?.icon) &&
        !values?.widgetConfiguration?.recommendedOption?.price?.icon?.[0]?.url
      ) {
        recommendedPriceIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.recommendedOption?.price?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}`
        });
      }
      if (
        !isEmpty(
          values?.widgetConfiguration?.recommendedOption?.marketingBlock?.icon
        ) &&
        !values?.widgetConfiguration?.recommendedOption?.marketingBlock
          ?.icon?.[0]?.url
      ) {
        recommendedMarketingIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.recommendedOption?.marketingBlock
              ?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}`
        });
      }
    }

    if (values?.widgetConfiguration?.premiumOption) {
      if (
        !isEmpty(
          values?.widgetConfiguration?.premiumOption?.quoteVariantIcon
        ) &&
        !values?.widgetConfiguration?.premiumOption?.quoteVariantIcon?.[0]?.url
      ) {
        premiumQuoteIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.premiumOption?.quoteVariantIcon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
      if (
        !isEmpty(values?.widgetConfiguration?.premiumOption?.price?.icon) &&
        !values?.widgetConfiguration?.premiumOption?.price?.icon?.[0]?.url
      ) {
        premiumPriceIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.premiumOption?.price?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}`
        });
      }
      if (
        !isEmpty(
          values?.widgetConfiguration?.premiumOption?.marketingBlock?.icon
        ) &&
        !values?.widgetConfiguration?.premiumOption?.marketingBlock?.icon?.[0]
          ?.url
      ) {
        premiumMarketingIcon = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.premiumOption?.marketingBlock
              ?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}`
        });
      }
    }

    if (values?.widgetConfiguration?.stairs?.steps) {
      if (
        !isEmpty(values?.widgetConfiguration?.stairs?.steps?.picture) &&
        !values?.widgetConfiguration?.stairs?.steps?.picture?.[0]?.url
      ) {
        stepsImage = await uploadFile({
          logoObject: values?.widgetConfiguration?.stairs?.steps?.picture?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
    }

    if (values?.widgetConfiguration?.common?.highlightYourArea) {
      if (
        !isEmpty(
          values?.widgetConfiguration?.common?.highlightYourArea?.icon
        ) &&
        !values?.widgetConfiguration?.common?.highlightYourArea?.icon?.[0]?.url
      ) {
        highLightAreaImage = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.common?.highlightYourArea?.icon?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
    }
    if (values?.widgetConfiguration?.stairs?.totalRise) {
      if (
        !isEmpty(values?.widgetConfiguration?.stairs?.totalRise?.image) &&
        !values?.widgetConfiguration?.stairs?.totalRise?.image?.[0]?.url
      ) {
        totalRiseImage = await uploadFile({
          logoObject:
            values?.widgetConfiguration?.stairs?.totalRise?.image?.[0],
          pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${values?.id}`
        });
      }
    }
    if (values?.widgetConfiguration?.otherConfig?.length > 0) {
      finalConfig = await Promise?.all(
        map(values?.widgetConfiguration?.otherConfig, async (item) => {
          if (item?.logo?.length > 0 && !item?.logo?.[0]?.url) {
            const logoObject = await uploadFile({
              logoObject: item?.logo?.[0],
              pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}/my-project/${item?.blockTitle}`
            });
            return {
              ...item,
              logo: logoObject
            };
          }
          return { ...item, logo: item?.logo?.[0] };
        })
      );
    }

    // for pick list
    if (values?.widgetConfiguration?.otherConfig?.length > 0) {
      pickListFinalConfig = await Promise?.all(
        map(values?.widgetConfiguration?.otherConfig, async (item) => {
          if (item?.image?.length > 0 && !item?.image?.[0]?.url) {
            const logoObject = await uploadFile({
              logoObject: item?.image?.[0],
              pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}/my-project/${item?.blockTitle}`
            });
            return {
              ...item,
              image: logoObject
            };
          }
          return { ...item, image: item?.image?.[0] };
        })
      );
    }

    if (values?.widgetConfiguration?.config?.listItems?.length > 0) {
      pickListFinalConfig = await Promise?.all(
        map(values?.widgetConfiguration?.config?.listItems, async (item) => {
          const uuid = uuidv4();
          if (item?.image?.length > 0 && !item?.image?.[0]?.url) {
            const logoObject = await uploadFile({
              logoObject: item?.image?.[0],
              pathUpload: `organization/${currentUser?.tenantId}/cpq-builder/${cpqQuestionObject?.uuid}/page-id/${pageConfigProp?.id}/my-project/${item?.label}`
            });
            return {
              ...item,
              uuid,
              image: logoObject
            };
          }
          return { ...item, uuid, image: item?.image?.[0] };
        })
      );
    }

    if (values?.widgetConfiguration?.config?.dateTimeType) {
      if (values?.widgetConfiguration?.config?.dateTimeType?.DATE) {
        dateTimeType?.push('DATE');
      }
      if (values?.widgetConfiguration?.config?.dateTimeType?.TIME) {
        dateTimeType?.push('TIME');
      }
      if (values?.widgetConfiguration?.config?.allowDateTime?.ALLOW_PAST) {
        allowDateTime?.push('ALLOW_PAST');
      }
      if (values?.widgetConfiguration?.config?.allowDateTime?.ALLOW_FUTURE) {
        allowDateTime?.push('ALLOW_FUTURE');
      }
    }

    const finalValues = {
      ...values,
      questionnaireId: id,
      headerImage: values?.headerImage?.[0]?.url
        ? omit(pageConfigProp?.headerImage, ['__typename', 'uid'])
        : headerImage,
      ...(values?.widgetConfiguration?.otherConfig?.length > 0 && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          otherConfig: finalConfig
        }
      }),
      skipPageConditions: {
        alwaysSkip: isAlwaysSkipEnabled,
        data: skipPageConditions
      },
      ...(values?.widgetConfiguration?.lowCostOption && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          lowCostOption: {
            ...values?.widgetConfiguration?.lowCostOption,
            quoteVariantIcon: values?.widgetConfiguration?.lowCostOption
              ?.quoteVariantIcon?.[0]?.url
              ? omit(
                  pageConfigProp?.widgetConfiguration?.lowCostOption
                    ?.quoteVariantIcon,
                  ['__typename']
                )
              : lowQuoteIcon,
            price: {
              ...values?.widgetConfiguration?.lowCostOption?.price,
              icon: values?.widgetConfiguration?.lowCostOption?.price?.icon?.[0]
                ?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.lowCostOption?.price
                      ?.icon,
                    ['__typename']
                  )
                : lowPriceIcon
            },
            marketingBlock: {
              ...values?.widgetConfiguration?.lowCostOption?.marketingBlock,
              icon: values?.widgetConfiguration?.lowCostOption?.marketingBlock
                ?.icon?.[0]?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.lowCostOption
                      ?.marketingBlock?.icon,
                    ['__typename']
                  )
                : lowMarketingIcon
            }
          },
          recommendedOption: {
            ...values?.widgetConfiguration?.recommendedOption,
            quoteVariantIcon: values?.widgetConfiguration?.recommendedOption
              ?.quoteVariantIcon?.[0]?.url
              ? omit(
                  pageConfigProp?.widgetConfiguration?.recommendedOption
                    ?.quoteVariantIcon,
                  ['__typename']
                )
              : recommendedQuoteIcon,
            price: {
              ...values?.widgetConfiguration?.recommendedOption?.price,
              icon: values?.widgetConfiguration?.recommendedOption?.price
                ?.icon?.[0]?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.recommendedOption
                      ?.price?.icon,
                    ['__typename']
                  )
                : recommendedPriceIcon
            },
            marketingBlock: {
              ...values?.widgetConfiguration?.recommendedOption?.marketingBlock,
              icon: values?.widgetConfiguration?.recommendedOption
                ?.marketingBlock?.icon?.[0]?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.recommendedOption
                      ?.marketingBlock?.icon,
                    ['__typename']
                  )
                : recommendedMarketingIcon
            }
          },
          premiumOption: {
            ...values?.widgetConfiguration?.premiumOption,
            quoteVariantIcon: values?.widgetConfiguration?.premiumOption
              ?.quoteVariantIcon?.[0]?.url
              ? omit(
                  pageConfigProp?.widgetConfiguration?.premiumOption
                    ?.quoteVariantIcon,
                  ['__typename']
                )
              : premiumQuoteIcon,
            price: {
              ...values?.widgetConfiguration?.premiumOption?.price,
              icon: values?.widgetConfiguration?.premiumOption?.price?.icon?.[0]
                ?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.premiumOption?.price
                      ?.icon,
                    ['__typename']
                  )
                : premiumPriceIcon
            },
            marketingBlock: {
              ...values?.widgetConfiguration?.premiumOption?.marketingBlock,
              icon: values?.widgetConfiguration?.premiumOption?.marketingBlock
                ?.icon?.[0]?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.premiumOption
                      ?.marketingBlock?.icon,
                    ['__typename']
                  )
                : premiumMarketingIcon
            }
          }
        }
      }),
      ...(values?.widgetConfiguration?.address && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          address: {
            ...values?.widgetConfiguration?.address,
            icon: values?.widgetConfiguration?.address?.icon?.[0]?.url
              ? omit(pageConfigProp?.widgetConfiguration?.address?.icon, [
                  '__typename'
                ])
              : addressIcon
          },
          availability: {
            ...values?.widgetConfiguration?.availability,
            icon: values?.widgetConfiguration?.availability?.icon?.[0]?.url
              ? omit(pageConfigProp?.widgetConfiguration?.availability?.icon, [
                  '__typename'
                ])
              : availabilityIcon
          },
          reserveAppointment: {
            ...values?.widgetConfiguration?.reserveAppointment,
            icon: values?.widgetConfiguration?.reserveAppointment?.icon?.[0]
              ?.url
              ? omit(
                  pageConfigProp?.widgetConfiguration?.reserveAppointment?.icon,
                  ['__typename']
                )
              : reserveAppointmentIcon,
            creditCard: {
              ...values?.widgetConfiguration?.reserveAppointment?.creditCard,
              icon: values?.widgetConfiguration?.reserveAppointment?.creditCard
                ?.icon?.[0]?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.reserveAppointment
                      ?.creditCard?.icon,
                    ['__typename']
                  )
                : creditCardIcon
            },
            contactInfo: {
              ...values?.widgetConfiguration?.reserveAppointment?.contactInfo,
              icon: values?.widgetConfiguration?.reserveAppointment?.contactInfo
                ?.icon?.[0]?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.reserveAppointment
                      ?.contactInfo?.icon,
                    ['__typename']
                  )
                : contactInfoIcon
            }
          }
        }
      }),
      ...(values?.widgetConfiguration?.selectedSystemFieldBlock && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          selectedSystemFieldBlock: {
            ...values?.widgetConfiguration?.selectedSystemFieldBlock,
            selectedSystemFieldType: fieldObject?.selectedSystemFieldType,
            selectedSystemFieldLabel: fieldObject?.selectedSystemFieldLabel
          },
          ...(isSystemTextWidget && {
            fieldConfig: systemFieldData?.fieldConfig
          }),
          ...(values?.widgetConfiguration?.otherConfig?.length > 0 && {
            otherConfig: pickListFinalConfig
          })
        }
      }),
      ...(values?.widgetConfiguration?.config?.dateTimeType && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          config: {
            ...values?.widgetConfiguration?.config,
            dateTimeType,
            dateConfig: {
              range:
                values?.widgetConfiguration?.config?.dateConfig === 'RANGE',
              exactDate:
                values?.widgetConfiguration?.config?.dateConfig === 'EXACT'
            },
            allowDateTime
          }
        }
      }),
      ...(values?.widgetConfiguration?.fieldType ===
        DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          config: {
            ...values?.widgetConfiguration?.config,
            listItems: pickListFinalConfig
          }
        }
      }),
      ...(values?.widgetConfiguration?.config?.fileType && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          config: {
            ...values?.widgetConfiguration?.config,
            fileType: {
              key: values?.widgetConfiguration?.config?.fileType,
              value: checkedList
            }
          }
        }
      }),
      ...(values?.widgetConfiguration?.common && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          common: {
            ...values?.widgetConfiguration?.common,
            highlightYourArea: {
              ...values?.widgetConfiguration?.common?.highlightYourArea,
              icon: values?.widgetConfiguration?.common?.highlightYourArea
                ?.icon?.[0]?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.common
                      ?.highlightYourArea?.icon,
                    ['__typename']
                  )
                : highLightAreaImage
            }
          }
        }
      }),
      ...(values?.widgetConfiguration?.stairs && {
        widgetConfiguration: {
          ...values?.widgetConfiguration,
          stairs: {
            ...values?.widgetConfiguration?.stairs,
            steps: {
              ...values?.widgetConfiguration?.stairs?.steps,
              picture: values?.widgetConfiguration?.stairs?.steps?.picture?.[0]
                ?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.stairs?.steps?.picture,
                    ['__typename']
                  )
                : stepsImage
            },
            totalRise: {
              ...values?.widgetConfiguration?.stairs?.totalRise,
              image: values?.widgetConfiguration?.stairs?.totalRise?.image?.[0]
                ?.url
                ? omit(
                    pageConfigProp?.widgetConfiguration?.stairs?.totalRise
                      ?.image,
                    ['__typename']
                  )
                : totalRiseImage
            }
          }
        }
      }),
      ...omit(widgetInfo, ['deletable'])
    };
    try {
      if (pageKey) {
        const res = await updatePageConfig({
          variables: {
            data: finalValues,
            where: {
              id: pageConfigProp?.id,
              pageKey: snakeCase(pageKey)?.toUpperCase()
            }
          }
        });
        if (res?.data && refetchPageConfig && refetchPageSequence) {
          if (titleChanged) {
            await refetchPageSequence();
            const pageKeyPage = res?.data?.updatePageConfig?.data?.pageKey;
            setTitleChanged(false);
            history?.push(
              `${questionnaireRoute}/${id}${ROUTES?.PAGE}/${kebabCase(
                pageKeyPage
              )}?sp=false`,
              {
                ...location?.state
              }
            );
            return;
          }
          fetchTenantData();
          await refetchPageConfig();
        }
      } else {
        const res = await createPageConfig({
          variables: {
            data: finalValues
          }
        });
        const pageKeyPage = res?.data?.createPageConfig?.data?.pageKey;
        if (res?.data?.createPageConfig?.data?.pageKey && refetchPageSequence) {
          fetchTenantData();
          await refetchPageSequence();
          // need to add location state here
          history?.push(
            `${questionnaireRoute}/${id}${ROUTES?.PAGE}/${kebabCase(
              pageKeyPage
            )}?sp=false`,
            {
              ...location?.state
            }
          );
        }
      }
    } catch (error) {
      return error;
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.title) {
      setTitleChanged(true);
    }
    setDisableBtn(false);
    setSystemFieldData({
      ...allValues?.widgetConfiguration,
      ...(systemFieldData && {
        fieldConfig: systemFieldData?.fieldConfig
      })
    });

    setFormValues({ ...allValues, ...widgetInfo });
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };

  const handleDeleteWidget = (e) => {
    e?.stopPropagation();
    setDisableBtn(false);
    setWidgetInfo(null);
    setFieldObject(null);
    setSystemFieldData(null);

    setFormValues({
      ...formValues,
      widgetConfiguration: undefined,
      widgetType: undefined,
      widgetId: undefined,
      deletable: undefined
    });
    form?.setFieldsValue({
      widgetConfiguration: undefined
    });
  };

  const handleDrawer = (visible = false, widgetConfig = null) => {
    setShowDrawer(visible);
    if (widgetConfig) {
      setDisableBtn(false);
      setWidgetInfo({
        widgetType: widgetConfig?.type,
        widgetId: widgetConfig?.id,
        deletable: true
      });
      setFormValues({
        ...formValues,
        widgetConfiguration: {
          ...widgetConfig?.config,
          selectedSystemFieldBlock: {
            ...widgetConfig?.selectedSystemFieldBlock,
            selectedSystemFieldId: null
          }
        },
        widgetType: widgetConfig?.type,
        widgetId: widgetConfig?.id,
        deletable: true
      });

      form?.setFieldsValue({
        widgetConfiguration: {
          ...widgetConfig?.config,
          selectedSystemFieldBlock: {
            ...widgetConfig?.selectedSystemFieldBlock,
            selectedSystemFieldId: null
          }
        }
      });
    }
  };

  const showHeaderImage = Form?.useWatch('showHeaderImage', form);
  const showTitle = Form?.useWatch('showTitle', form);
  const showTaglineAbove = Form?.useWatch('showTaglineAbove', form);
  const showTaglineBelow = Form?.useWatch('showTaglineBelow', form);
  const showCtaButton = Form?.useWatch('showCtaButton', form);
  const showFootNote = Form?.useWatch('showFootNote', form);
  const showContactMe = Form?.useWatch('showContactMe', form);

  useEffect(() => {
    if (refetchSystemFields) {
      setWidgetInfo(null);
      setFieldObject(null);
      setSystemFieldData(null);
      setFormValues({
        ...formValues,
        widgetConfiguration: undefined,
        widgetType: undefined,
        widgetId: undefined,
        deletable: undefined
      });
      form?.setFieldsValue({
        widgetConfiguration: undefined
      });
      handleDrawer(false);
      setDisableBtn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchSystemFields]);

  if (widgetLoading) {
    return <LoaderComponent />;
  }

  const headerTooltipContent = (
    <span className="line-height">
      Use this option to update the header for the specific page you're
      currently editing. For a header that applies to all pages, go to &nbsp;
      <b>Settings</b> -&gt; <b>Branding</b>.
    </span>
  );

  const sameDatInstallationContent = (
    <span className="line-height">
      To enable this feature, choose 'Cut-Off Times.' Access it in the ROQ
      Builder under <b>Settings (top right)</b> -&gt; <b>Business Hours</b>{' '}
      -&gt; <b> Cut Off Times.</b>
    </span>
  );
  return (
    <div className="form-section">
      {showModal && (
        <SkipPageModal
          showModal={showModal}
          builderForm={form}
          setShowModal={setShowModal}
          pagesData={pageSequencesData}
          pageKey={pageKey}
          setCheckedSkipPages={setCheckedSkipPages}
          checkedSkipPages={checkedSkipPages}
          skipPageConditions={skipPageConditions}
          setSkipPageConditions={setSkipPageConditions}
          setDisableBtn={setDisableBtn}
          isAlwaysSkipEnabled={isAlwaysSkipEnabled}
          setIsAlwaysSkipEnabled={setIsAlwaysSkipEnabled}
          editData={pageConfigProp?.skipPageConditions}
        />
      )}
      <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button save-btn"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="postal-save-btn"
            loading={btnLoading}
            disabled={disableBtn}
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      {formLoading ? (
        <LoaderComponent />
      ) : (
        <Form
          name="dynamic-builder-form"
          form={form}
          initialValues={initialValues}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          className="builder-form"
          scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
        >
          <fieldset
            disabled={
              !checkPermissions(permissions, ['FET_QUESTIONNAIRE_UPDATE'])
            }
          >
            <Drawer
              width="100%"
              forceRender
              placement="right"
              className="widget-drawer"
              closeIcon={
                <span className="common-button discard-button drawer-back">
                  <LeftOutlined className="left-btn" />
                  General Edits
                </span>
              }
              onClose={() => handleDrawer(false)}
              open={showDrawer}
              getContainer={false}
              maskClosable={false}
              mask={false}
            >
              <RenderWidget
                widgetType={widgetInfo?.widgetType}
                form={form}
                setSelectedLob={setSelectedLob}
                selectedLob={selectedLob}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                selectedAppointmentKey={selectedAppointmentKey}
                setSelectedAppointmentKey={setSelectedAppointmentKey}
                fieldObject={fieldObject}
                setFieldObject={setFieldObject}
                pageKey={pageKey}
                systemFieldData={systemFieldData}
                setSystemFieldData={setSystemFieldData}
                setFormValues={setFormValues}
                formValues={formValues}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
                validationTriggered={validationTriggered}
                setDisableBtn={setDisableBtn}
                refetchSystemFields={refetchSystemFields}
                setRefetchSystemFields={setRefetchSystemFields}
                setSelectedDimensionTab={setSelectedDimensionTab}
                selectedDimensionTab={selectedDimensionTab}
                setSelectedDimensionProject={setSelectedDimensionProject}
                selectedDimensionProject={selectedDimensionProject}
                showCtaButton={showCtaButton}
              />
            </Drawer>
            <div className="section-header">
              <span className="section-title">Header</span>
            </div>
            <div className={`${showDrawer ? 'form-hide' : ''}`}>
              {/* Header Image */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">
                  Header Image{' '}
                  <Popover
                    overlayClassName="pointer common-tooltip"
                    content={headerTooltipContent}
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
                <Form.Item
                  name="showHeaderImage"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="headerImage"
                className="mb-8"
                hidden={!showHeaderImage}
                normalize={uploadImageNormalize}
                valuePropName="fileList"
              >
                <DraggerUploadComponent
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                    showRemoveIcon: checkPermissions(permissions, [
                      'FET_QUESTIONNAIRE_UPDATE'
                    ])
                  }}
                >
                  <p className="icon-header">
                    <DownloadOutlined />
                  </p>
                  <p className="upload-title">
                    Click or drag file to this area to upload
                  </p>
                </DraggerUploadComponent>
              </Form.Item>
              {showHeaderImage && (
                <span className="font-size-12">
                  Please upload image with dimensions of 768 x 250 pixels for
                  the best visual experience.
                </span>
              )}

              {/* Logos */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Logos</span>
                <Form.Item
                  name="showLogo"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>

              {/* Title */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo required-mark">
                  Title &nbsp;
                  <Popover content="This is the name displayed on the questionnaire that your customer will see">
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
                <Form.Item
                  name="showTitle"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="title"
                hidden={!showTitle}
                rules={[
                  {
                    ...required,
                    message: 'Please Enter Title'
                  }
                ]}
              >
                <InputComponent placeholder="Enter Title" />
              </Form.Item>

              {/* Tagline: Above */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Tagline: Above</span>
                <Form.Item
                  name="showTaglineAbove"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="taglineAbove"
                hidden={!showTaglineAbove}
                rules={[
                  {
                    required: showTaglineAbove,
                    message: 'Please Enter Tag Line Above'
                  }
                ]}
              >
                <InputComponent placeholder="Enter Tag Line Above" />
              </Form.Item>

              {/* Tagline: Below */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Tagline: Below</span>
                <Form.Item
                  name="showTaglineBelow"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="taglineBelow"
                hidden={!showTaglineBelow}
                rules={[
                  {
                    required: showTaglineBelow,
                    message: 'Please Enter Tag Line Below'
                  }
                ]}
              >
                <InputComponent placeholder="Enter Tag Line Below" />
              </Form.Item>

              {/* widget section */}
              {!widgetInfo?.widgetType ? (
                <Card>
                  {map(widgetData?.widgets?.data, (item) => {
                    return (
                      <Card.Grid
                        onClick={() => handleDrawer(true, item)}
                        key={item?.id}
                        className="pointer"
                        hoverable
                      >
                        <Popover
                          placement="top"
                          content={
                            <span>
                              {WIDGET_TYPE?.[item?.type] ||
                                'this is sample tooltip'}
                            </span>
                          }
                          overlayClassName="widget-type-tooltip"
                        >
                          <div className="d-flex flex-vertical align-center">
                            <WidgetIcon />
                            <span className="card-title" title={item?.type}>
                              {item?.type}
                            </span>
                          </div>
                        </Popover>
                      </Card.Grid>
                    );
                  })}
                </Card>
              ) : (
                <Card
                  hoverable
                  onClick={() => handleDrawer(true)}
                  extra={
                    widgetInfo?.deletable && (
                      <div>
                        <DeleteOutlined
                          className="pointer danger"
                          onClick={handleDeleteWidget}
                        />
                      </div>
                    )
                  }
                  className="fill-width editable-card"
                >
                  <Popover
                    placement="top"
                    content={
                      <span>
                        {WIDGET_TYPE?.[
                          pageConfigProp?.widgetType || widgetInfo?.widgetType
                        ] || 'this is sample tooltip'}
                      </span>
                    }
                  >
                    <div className="d-flex flex-vertical align-center mx-16">
                      <WidgetIcon />
                      <span
                        className="card-title"
                        title={
                          pageConfigProp?.widgetType || widgetInfo?.widgetType
                        }
                      >
                        {pageConfigProp?.widgetType || widgetInfo?.widgetType}
                      </span>
                    </div>
                  </Popover>
                </Card>
              )}

              {skipPageList?.length > 0 && (
                <div className="skip-page-list">
                  <div className="mt-16">Skip Page Conditions</div>
                  {skipPageList?.map((item) => (
                    <div className="form-divider-text mt-8" key={item}>
                      {item}
                    </div>
                  ))}
                </div>
              )}

              {pageKey &&
                type === QUESTIONNAIRE_TYPE?.ROQ_PATH &&
                pageSequencesData?.[0]?.pageKey !==
                  snakeCase(pageKey)?.toUpperCase() && (
                  <Button
                    className={`common-button  fill-width d-flex justify-center align-center mt-16 ${
                      skipPageList?.length > 0 || isAlwaysSkipEnabled
                        ? 'py-12 manage-condition-button'
                        : ''
                    }`}
                    icon={
                      skipPageList?.length > 0 || isAlwaysSkipEnabled ? (
                        <ManageConditionIcon />
                      ) : (
                        <PlusCircleOutlined />
                      )
                    }
                    type={
                      skipPageList?.length > 0 || isAlwaysSkipEnabled
                        ? ''
                        : 'primary'
                    }
                    onClick={async () => {
                      form
                        ?.validateFields()
                        ?.then(() => {
                          setShowModal(true);
                        })
                        ?.catch((value) => {
                          if (value?.errorFields?.length > 0) {
                            message?.info(
                              'You need to resolve the errors to Add/Manage Skip Page Conditions'
                            );
                            setShowDrawer(
                              value?.errorFields?.[0]?.name?.includes(
                                'widgetConfiguration'
                              )
                            );
                            form?.scrollToField(value?.errorFields?.[0]?.name);
                          }
                          setValidationTriggered(true);
                        });
                    }}
                  >
                    {skipPageList?.length > 0 || isAlwaysSkipEnabled
                      ? 'Manage Skip Page Conditions'
                      : 'Add Skip Page Conditions'}
                  </Button>
                )}

              <div className="section-header mt-24">
                <span className="section-title">Footer</span>
              </div>

              {/* Same Day Installation Timer */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">
                  Same Day Installation Timer{' '}
                  <Popover
                    overlayClassName="pointer common-tooltip"
                    content={sameDatInstallationContent}
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
                <Form.Item
                  name="sameDayInstallationTimer"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>

              {/* Continue Button */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Continue Button</span>
                <Form.Item
                  name="showCtaButton"
                  className="mb-0"
                  valuePropName="checked"
                  validateStatus="warning"
                >
                  <Switch
                    disabled={type === QUESTIONNAIRE_TYPE?.ROQ_BUILDER}
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="ctaButtonLabel"
                hidden={!showCtaButton}
                rules={[
                  {
                    required: showCtaButton,
                    message: 'Please Enter Continue Button Text'
                  }
                ]}
              >
                <InputComponent placeholder="Enter Continue Button Text" />
              </Form.Item>

              {/* My Cart */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">My Cart</span>
                <Form.Item
                  name="showMyCart"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>

              {/* Back Button */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Back Button</span>
                <Form.Item
                  name="showBack"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>

              {/* Footnote */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">
                  Footnote{' '}
                  <Popover
                    overlayClassName="pointer"
                    content="Add your unique message to the page's footer."
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
                <Form.Item
                  name="showFootNote"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="footNote"
                hidden={!showFootNote}
                rules={[
                  {
                    required: showFootNote,
                    message: 'Please Enter Footnote'
                  }
                ]}
              >
                <InputComponent placeholder="Enter Footnote" />
              </Form.Item>

              {/* Contact Me Button */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">
                  Contact Me Button{' '}
                  <Popover
                    overlayClassName="pointer"
                    content="When enabled, customers can request a call or email without completing the questionnaire."
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
                <Form.Item
                  name="showContactMe"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="contactMe"
                hidden={!showContactMe}
                rules={[
                  {
                    required: showContactMe,
                    message: 'Please Enter Contact Me Button Text'
                  }
                ]}
              >
                <InputComponent placeholder="Enter Contact Me Button Text" />
              </Form.Item>

              {/* Contact Info */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Contact Info</span>
                <Form.Item
                  name="showContactInfo"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>

              {/* Customer Location */}
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">
                  Customer Location{' '}
                  <Popover
                    overlayClassName="pointer"
                    content="Displays the location based on the postal code entered by the customer."
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
                <Form.Item
                  name="showLocation"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
            </div>
          </fieldset>
        </Form>
      )}
    </div>
  );
};

export default withRouter(BuilderFormComponent);
