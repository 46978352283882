import { CloseOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Empty,
  Radio,
  Row,
  Tag,
  message
} from 'antd';
import { filter, findIndex, forEach, map, set } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import {
  BIG_SCREEN,
  MODAL_WIDTH,
  SMALL_TABLET,
  TABLET_BREAKPOINT
} from '../../../common/constants';
import { displayZipCodes } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import SearchComponent from '../../../components/SearchComponent';
import SelectComponent from '../../../components/SelectComponent';
import {
  GET_BRANDS,
  GET_INDUSTRIES,
  GET_MANUFACTURERS,
  GET_PRICING_MODIFIER_REGIONS
} from '../graphql/Queries';

const { Panel } = Collapse;

const { Option } = SelectComponent;

const PricingConditionModal = ({
  showModal = false,
  setShowModal,
  selectedCondition = null,
  setConditionDataCopy,
  conditionDataCopy = {},
  setCollapseActiveKeys,
  attributeLoading = false,
  setAttributeLoading,
  attributesData = [],
  collapseActiveKeys = []
}) => {
  const [regionSearchValue, setRegionSearchValue] = useState('');
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [conditionData, setConditionData] = useState({});
  const [regionsData, setRegionsData] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState([]);

  const {
    state: { windowWidth }
  } = useContext(AppContext);

  let showZip;
  if (windowWidth < SMALL_TABLET) {
    showZip = 8;
  } else if (windowWidth < TABLET_BREAKPOINT) {
    showZip = 75;
  } else if (windowWidth < BIG_SCREEN) {
    showZip = 12;
  } else {
    showZip = 15;
  }

  const [brands, { data: brandsData, loading: brandsLoading }] = useLazyQuery(
    GET_BRANDS,
    {
      fetchPolicy: 'network-only',
      onError: () => {}
    }
  );

  const [regionPricingModifier, { data: regionPricingData }] = useLazyQuery(
    GET_PRICING_MODIFIER_REGIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const regionsCopy = [];
        forEach(res?.regionPricingModifier?.data, (item) => {
          regionsCopy?.push(item);
          if (item?.subRegions?.length > 0) {
            forEach(item?.subRegions, (data) => regionsCopy?.push(data));
          }
        });
        setRegionsData(regionsCopy);
        setRegionsLoading(false);
      },
      onError: () => {
        setRegionsLoading(false);
      }
    }
  );

  const [
    manufacturers,
    { data: manufacturersData, loading: manufacturersLoading }
  ] = useLazyQuery(GET_MANUFACTURERS, {
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  const [
    industries,
    { data: industryData, loading: industryLoading }
  ] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onError: () => {}
  });

  const handleAttributeSelect = (value) => {
    if (value) {
      setSelectedAttribute(value);
      setAttributeLoading(false);
      if (!conditionData?.industry?.[0]?.lineOfBusiness?.length) {
        setConditionData({ ...conditionData, industry: [] });
      }
      switch (value) {
        case 'PMC_BRAND':
          brands({
            variables: {
              filter: {
                sortOn: 'createdAt',
                sortBy: 'DESC',
                defaultBrand: true
              },
              where: { isActive: true }
            }
          });
          break;

        case 'PMC_REGION':
          setRegionsLoading(true);
          regionPricingModifier({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'DESC'
              },
              where: { isActive: true }
            }
          });
          break;

        case 'PMC_LOB':
          industries({
            variables: {
              filter: {
                sortOn: 'id',
                sortBy: 'ASC'
              },
              where: { isActive: true }
            }
          });
          break;

        case 'PMC_MANUFACTURER':
          manufacturers({
            variables: {
              filter: {
                sortOn: 'createdAt',
                sortBy: 'DESC'
              },
              where: { isActive: true }
            }
          });
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (conditionDataCopy) {
      setConditionData(conditionDataCopy);
    }
    setSelectedAttribute(selectedCondition || 'PMC_BRAND');
    switch (selectedCondition) {
      case 'PMC_BRAND':
        brands({
          variables: {
            filter: {
              sortOn: 'createdAt',
              sortBy: 'DESC',
              defaultBrand: true
            },
            where: { isActive: true }
          }
        });
        break;

      case 'PMC_REGION':
        setRegionsLoading(true);
        regionPricingModifier({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'DESC'
            },
            where: { isActive: true }
          }
        });
        break;

      case 'PMC_LOB':
        industries({
          variables: {
            filter: {
              sortOn: 'id',
              sortBy: 'ASC'
            },
            where: { isActive: true }
          }
        });
        break;

      case 'PMC_MANUFACTURER':
        manufacturers({
          variables: {
            filter: {
              sortOn: 'createdAt',
              sortBy: 'DESC'
            },
            where: { isActive: true }
          }
        });
        break;

      default:
        brands({
          variables: {
            filter: {
              sortOn: 'createdAt',
              sortBy: 'DESC',
              defaultBrand: true
            },
            where: { isActive: true }
          }
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCheckboxBrands = (e, record) => {
    let brandsCopy = [...conditionData?.brands];
    if (e?.target?.checked) {
      brandsCopy?.push({ id: record?.id, label: record?.name });
    } else {
      brandsCopy = filter(brandsCopy, (item) => item?.id !== record?.id);
    }
    setConditionData({ ...conditionData, brands: brandsCopy });
  };

  const onCheckAllChangeBrands = (e) => {
    if (e?.target.checked) {
      const brandsCopy = [];
      forEach(brandsData?.brands?.data, (item) =>
        brandsCopy?.push({
          id: item?.id,
          label: item?.name
        })
      );
      setConditionData({ ...conditionData, brands: brandsCopy });
    } else {
      setConditionData({ ...conditionData, brands: [] });
    }
  };

  const handleChangeCheckboxManufacturers = (e, record) => {
    let manufacturersCopy = [...conditionData?.manufacturers];
    if (e?.target?.checked) {
      manufacturersCopy?.push({ id: record?.id, label: record?.name });
    } else {
      manufacturersCopy = filter(
        manufacturersCopy,
        (item) => item?.id !== record?.id
      );
    }
    setConditionData({ ...conditionData, manufacturers: manufacturersCopy });
  };

  const onCheckAllChangeManufacturers = (e) => {
    if (e?.target.checked) {
      const manufacturersCopy = [];
      forEach(manufacturersData?.manufacturers?.data, (item) =>
        manufacturersCopy?.push({
          id: item?.id,
          label: item?.name
        })
      );
      setConditionData({ ...conditionData, manufacturers: manufacturersCopy });
    } else {
      setConditionData({ ...conditionData, manufacturers: [] });
    }
  };

  const onChangeIndustry = (e, label = '') => {
    const industryCopy = [{ id: e?.target?.value, label, lineOfBusiness: [] }];
    setConditionData({ ...conditionData, industry: industryCopy });
  };

  const onChangeLob = (e, lob) => {
    const industryCopy = [...conditionData?.industry];
    const lobIndex = findIndex(
      industryCopy?.[0]?.lineOfBusiness,
      (item) => item?.id === lob?.id
    );
    if (e?.target?.checked) {
      const selectedSubareas = [];
      forEach(lob?.subAreas, (item) =>
        selectedSubareas?.push({
          id: item?.id,
          label: item?.label
        })
      );
      if (lobIndex > -1) {
        industryCopy[0].lineOfBusiness[lobIndex].subArea = selectedSubareas;
        set(industryCopy?.[0]?.lineOfBusiness?.[lobIndex], 'all', true);
      } else {
        industryCopy?.[0]?.lineOfBusiness?.push({
          id: lob?.id,
          label: lob?.label,
          all: true,
          subArea: selectedSubareas
        });
      }
    } else {
      industryCopy?.[0].lineOfBusiness?.splice(lobIndex, 1);
    }
    setConditionData({ ...conditionData, industry: industryCopy });
  };

  const onChangeSubArea = (e, subArea, lob) => {
    const industryCopy = [...conditionData?.industry];
    const lobIndex = findIndex(
      industryCopy?.[0]?.lineOfBusiness,
      (item) => item?.id === lob?.id
    );
    if (e?.target?.checked) {
      if (lobIndex > -1) {
        industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea?.push({
          id: subArea?.id,
          label: subArea?.label
        });
        if (
          industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea?.length ===
          lob?.subAreas?.length
        ) {
          set(industryCopy?.[0]?.lineOfBusiness?.[lobIndex], 'all', true);
        }
      } else {
        industryCopy?.[0]?.lineOfBusiness?.push({
          id: lob?.id,
          label: lob?.label,
          subArea: [{ id: subArea?.id, label: subArea?.label }],
          all: lob?.subAreas?.length?.length === 1
        });
      }
    } else {
      const subAreaIndex = findIndex(
        industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea,
        (item) => item?.id === subArea?.id
      );
      industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea?.splice(
        subAreaIndex,
        1
      );
      set(industryCopy?.[0]?.lineOfBusiness?.[lobIndex], 'all', false);
      if (!industryCopy?.[0]?.lineOfBusiness?.[lobIndex]?.subArea?.length) {
        industryCopy?.[0]?.lineOfBusiness?.splice(lobIndex, 1);
      }
    }
    setConditionData({ ...conditionData, industry: industryCopy });
  };

  const onSearchChange = (value) => {
    setRegionSearchValue(value);
    setRegionsLoading(true);
    regionPricingModifier({
      variables: {
        filter: {
          sortOn: 'name',
          sortBy: 'DESC',
          search: value
        },
        where: { isActive: true }
      }
    });
  };

  const handleClickZip = (e, zipCode, region) => {
    e?.stopPropagation();
    const regionCopy = [...conditionData?.regions];
    const regionIndex = findIndex(
      regionCopy,
      (item) => item?.id === region?.id
    );
    if (regionIndex > -1) {
      const zipCodeIndex = findIndex(
        regionCopy?.[regionIndex]?.zipCodes,
        (item) => item === zipCode
      );
      if (zipCodeIndex > -1) {
        regionCopy?.[regionIndex]?.zipCodes?.splice(zipCodeIndex, 1);
        if (!regionCopy?.[regionIndex]?.zipCodes?.length) {
          regionCopy?.splice(regionIndex, 1);
        }
        set(regionCopy?.[regionIndex], 'all', false);
      } else {
        regionCopy?.[regionIndex]?.zipCodes?.push(zipCode);
        if (
          regionCopy?.[regionIndex]?.zipCodes?.length ===
          region?.zipCodes?.length
        ) {
          set(regionCopy?.[regionIndex], 'all', true);
        }
      }
    } else {
      regionCopy?.push({
        id: region?.id,
        label: region?.name,
        zipCodes: [zipCode],
        all: region?.zipCodes?.length === 1
      });
    }
    setConditionData({ ...conditionData, regions: regionCopy });
  };

  const onCheckAllChangeRegions = (e, item) => {
    e?.stopPropagation();
    const regionCopy = [...conditionData?.regions];
    const regionIndex = findIndex(
      regionCopy,
      (region) => region?.id === item?.id
    );
    if (e?.target?.checked) {
      const zipCodes = [];
      forEach(item?.zipCodes, (zipCode) => zipCodes?.push(zipCode));
      if (regionIndex > -1) {
        regionCopy[regionIndex].zipCodes = zipCodes;
        set(regionCopy?.[regionIndex], 'all', true);
      } else {
        regionCopy?.push({
          id: item?.id,
          label: item?.name,
          zipCodes,
          all: true
        });
      }
    } else {
      regionCopy?.splice(regionIndex, 1);
      set(regionCopy?.[regionIndex], 'all', false);
    }
    setConditionData({ ...conditionData, regions: regionCopy });
  };

  const handleDeleteTag = (regionIndex, zipIndex) => {
    const regionCopy = [...conditionData?.regions];
    if (zipIndex?.toString()?.length > 0) {
      regionCopy?.[regionIndex]?.zipCodes?.splice(zipIndex, 1);
      if (!regionCopy?.[regionIndex]?.zipCodes?.length) {
        regionCopy?.splice(regionIndex, 1);
      }
    } else {
      regionCopy?.splice(regionIndex, 1);
    }
    setConditionData({ ...conditionData, regions: regionCopy });
  };

  const renderRegionHeader = (item = null, regionCopy, regionIndex) => {
    return (
      <div className="panel-title">
        <div className="width-percent-30">
          <Checkbox
            className={`common-checkbox collapse-checkbox ${
              !item?.subRegions ? 'left-space' : ''
            }`}
            indeterminate={
              regionCopy?.[regionIndex]?.zipCodes?.length > 0 &&
              regionCopy?.[regionIndex]?.zipCodes?.length !==
                item?.zipCodes?.length
            }
            checked={
              regionCopy?.[regionIndex]?.zipCodes?.length > 0 &&
              regionCopy?.[regionIndex]?.zipCodes?.length ===
                item?.zipCodes?.length
            }
            onClick={(e) => e?.stopPropagation()}
            onChange={(e) => onCheckAllChangeRegions(e, item)}
          >
            <span onClick={(e) => e?.stopPropagation()}>{item?.name}</span>
          </Checkbox>
        </div>
        <div className="width-percent-70">
          {map(item?.zipCodes?.slice(0, showZip), (zip) => (
            <Tag
              key={zip}
              title={zip}
              className={`single-item ${
                regionCopy?.[regionIndex]?.zipCodes?.includes(zip)
                  ? 'zip-highlight'
                  : ''
              }`}
              onClick={(e) => handleClickZip(e, zip, item)}
            >
              <span className="content-text">{displayZipCodes(zip)}</span>
            </Tag>
          ))}
        </div>
      </div>
    );
  };

  const addSingleZipCode = () => {
    const regionCopy = [...conditionData?.regions];
    let isAlready = false;
    forEach(regionCopy, (item) => {
      if (!item?.id && !item?.label) {
        if (item?.zipCodes?.[0] === regionSearchValue) {
          isAlready = true;
        }
      }
    });
    if (!isAlready) {
      regionCopy?.push({
        id: null,
        label: null,
        zipCodes: [regionSearchValue]
      });
      setConditionData({ ...conditionData, regions: regionCopy });
    } else {
      message?.destroy();
      message?.error('Already Postal code is there.');
    }
  };

  const handleAddConditions = () => {
    setConditionDataCopy(conditionData);
    if (!collapseActiveKeys?.includes('PMC_REGION')) {
      setCollapseActiveKeys([...collapseActiveKeys, 'PMC_REGION']);
    }
    setShowModal(false);
  };

  const selectedLength = () => {
    switch (selectedAttribute) {
      case 'PMC_BRAND':
        return conditionData?.brands?.length;
      case 'PMC_REGION':
        return conditionData?.regions?.length;
      case 'PMC_LOB':
        return conditionData?.industry?.[0]?.lineOfBusiness?.length || 0;
      case 'PMC_MANUFACTURER':
        return conditionData?.manufacturers?.length;

      default:
        break;
    }
  };

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      forceRender
      title="ADD CONDITION"
      open={showModal}
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="pricing-modifier-modal"
      footer={
        <div
          className={`d-flex ${
            selectedAttribute === 'PMC_REGION'
              ? 'fill-width justify-between align-end'
              : 'justify-end'
          }`}
        >
          {selectedAttribute === 'PMC_REGION' && (
            <div className="d-flex flex-vertical">
              <span className="no-results">
                Adding the following Regions & POSTAL Codes
              </span>
              <div className="d-flex flex-wrap">
                {map(conditionData?.regions, (singleData, tagIndex) => {
                  if (singleData?.all) {
                    return (
                      <Tag
                        className="single-item region-tags"
                        title={singleData?.label}
                        key={singleData?.id}
                      >
                        <span className="content-text">
                          <span className="region-only">
                            {singleData?.label}
                          </span>
                          <CloseOutlined
                            className="close-img"
                            onClick={() => handleDeleteTag(tagIndex)}
                          />
                        </span>
                      </Tag>
                    );
                  }
                  return map(singleData?.zipCodes, (item, index) => {
                    return (
                      <Tag
                        className="single-item region-tags"
                        title={`${
                          singleData?.label ? `${singleData?.label} - ` : ''
                        }${item}`}
                        key={`${item}${tagIndex}`}
                      >
                        <span className="content-text">
                          <span>{`${
                            singleData?.label ? `${singleData?.label} - ` : ''
                          }${item}`}</span>
                          <CloseOutlined
                            className="close-img"
                            onClick={() => handleDeleteTag(tagIndex, index)}
                          />
                        </span>
                      </Tag>
                    );
                  });
                })}
              </div>
            </div>
          )}
          <Button
            type="primary"
            disabled={selectedLength() < 1}
            className="common-button add-condition-button"
            onClick={handleAddConditions}
          >
            Add
          </Button>
        </div>
      }
    >
      <Row>
        <Col span={24}>
          <SelectComponent
            allowClear={false}
            className="fill-width"
            placeholder="Select Attribute"
            notFoundContent={
              attributeLoading ? (
                <LoaderComponent size="small" setHeight={10} />
              ) : (
                <Empty
                  className="no-data-image"
                  image={Empty?.PRESENTED_IMAGE_SIMPLE}
                />
              )
            }
            value={selectedAttribute}
            onChange={handleAttributeSelect}
          >
            {map(attributesData, (item) => (
              <Option key={item?.id} value={item?.key}>
                {item?.key === 'PMC_BRAND' ? 'Retailer' : item?.label}
              </Option>
            ))}
          </SelectComponent>
        </Col>
      </Row>
      {selectedAttribute === 'PMC_BRAND' && (
        <>
          {brandsLoading ? (
            <LoaderComponent setHeight={75} />
          ) : (
            <>
              {brandsData?.brands?.data?.length > 0 ? (
                <div>
                  <Checkbox
                    className="common-checkbox check-all"
                    indeterminate={
                      !!(
                        conditionData?.brands?.length > 0 &&
                        conditionData?.brands?.length !==
                          brandsData?.brands?.data?.length
                      )
                    }
                    checked={
                      conditionData?.brands?.length ===
                      brandsData?.brands?.data?.length
                    }
                    onChange={onCheckAllChangeBrands}
                  >
                    ALL RETAILERS
                  </Checkbox>
                  <div className="center-content">
                    <Checkbox.Group
                      value={map(conditionData?.brands, (item) => item?.id)}
                      className="fill-width"
                    >
                      <Row gutter={[0, 16]} align="middle">
                        {map(brandsData?.brands?.data, (item) => (
                          <Col span={8} key={item?.id}>
                            <Checkbox
                              className="common-checkbox"
                              value={item?.id}
                              onChange={(e) =>
                                handleChangeCheckboxBrands(e, item)
                              }
                            >
                              <div>
                                <img
                                  src={item?.logo?.url || placeholderImage}
                                  alt="retailer-img"
                                  onError={(e) => {
                                    e.target.src = placeholderImage;
                                  }}
                                  className="list-img"
                                  width={24}
                                  height={24}
                                />
                                <span>{item?.name}</span>
                              </div>
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </div>
                </div>
              ) : (
                <Empty
                  className="no-data-image"
                  image={Empty?.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </>
          )}
        </>
      )}
      {selectedAttribute === 'PMC_REGION' && (
        <>
          <div className="check-all">
            <div className="fill-width search-checkbox">
              <SearchComponent
                className="list-search-box"
                id="search-container-id"
                placeholder="Region, Sub Region, or ZIP Code"
                name="Regions"
                getData={onSearchChange}
              />
            </div>
            {regionsLoading ? (
              <LoaderComponent setHeight={75} />
            ) : (
              <>
                {regionsData?.length > 0 ? (
                  <div className="region-margin center-content">
                    <Collapse
                      bordered={false}
                      expandIconPosition="end"
                      expandIcon={({ isActive }) => (
                        <UpOutlined rotate={isActive ? 0 : 180} />
                      )}
                      className="common-collapse collapse-text"
                    >
                      {map(regionsData, (item) => {
                        const regionCopy = [...conditionData?.regions];
                        const regionIndex = findIndex(
                          regionCopy,
                          (region) => region?.id === item?.id
                        );
                        return (
                          <Panel
                            className={
                              item?.subRegions
                                ? 'panel-height parent-region'
                                : 'panel-height sub-region'
                            }
                            header={renderRegionHeader(
                              item,
                              regionCopy,
                              regionIndex
                            )}
                            collapsible={
                              item?.zipCodes?.length < showZip && 'disabled'
                            }
                            showArrow={!(item?.zipCodes?.length < showZip)}
                            key={item?.id}
                          >
                            {map(item?.zipCodes?.slice(showZip), (zip) => (
                              <Tag
                                key={zip}
                                title={zip}
                                className={`single-item ${
                                  regionCopy?.[regionIndex]?.zipCodes?.includes(
                                    zip
                                  )
                                    ? 'zip-highlight'
                                    : ''
                                }`}
                                onClick={(e) => handleClickZip(e, zip, item)}
                              >
                                <span className="content-text">
                                  {displayZipCodes(zip)}
                                </span>
                              </Tag>
                            ))}
                          </Panel>
                        );
                      })}
                    </Collapse>
                  </div>
                ) : (
                  <>
                    {!regionPricingData?.regionPricingModifier
                      ?.isValidZipCode ? (
                      <Empty
                        className="no-data-image"
                        image={Empty?.PRESENTED_IMAGE_SIMPLE}
                      />
                    ) : (
                      <div className="d-flex flex-vertical">
                        <span className="no-results">No results</span>
                        <Tag className="tag-plus" onClick={addSingleZipCode}>
                          <span className="content-text">
                            <PlusOutlined /> Add {regionSearchValue}
                          </span>
                        </Tag>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
      {selectedAttribute === 'PMC_LOB' && (
        <>
          {industryLoading ? (
            <LoaderComponent setHeight={75} />
          ) : (
            <>
              <Row gutter={16}>
                <Radio.Group
                  value={conditionData?.industry?.[0]?.id}
                  className="fill-width"
                >
                  {industryData?.industries?.data?.length > 0 ? (
                    <div className="d-flex flex-wrap block-parent">
                      {map(industryData?.industries?.data, (industry) => {
                        const industryCopy = [...conditionData?.industry];
                        return (
                          <div className="lob-block" key={industry?.id}>
                            <Radio
                              className="common-radio lob-section"
                              value={industry?.id}
                              onChange={(e) =>
                                onChangeIndustry(e, industry?.label)
                              }
                            >
                              <span className="bold-label">
                                {industry?.label}
                              </span>
                            </Radio>
                            <Divider />
                            <div className="d-flex">
                              {map(industry?.lobSaData, (lob) => {
                                const lobIndex = findIndex(
                                  industryCopy?.[0]?.lineOfBusiness,
                                  (item) => item?.id === lob?.id
                                );
                                return (
                                  <div key={lob?.id} className="fill-width">
                                    <Checkbox
                                      disabled={
                                        industryCopy?.[0]?.id !== industry?.id
                                      }
                                      className="common-checkbox lob-section"
                                      indeterminate={
                                        industryCopy?.[0]?.lineOfBusiness?.[
                                          lobIndex
                                        ]?.subArea?.length > 0 &&
                                        industryCopy?.[0]?.lineOfBusiness?.[
                                          lobIndex
                                        ]?.subArea?.length !==
                                          lob?.subAreas?.length
                                      }
                                      onChange={(e) => onChangeLob(e, lob)}
                                      checked={
                                        industryCopy?.[0]?.lineOfBusiness?.[
                                          lobIndex
                                        ]?.subArea?.length > 0 &&
                                        industryCopy?.[0]?.lineOfBusiness?.[
                                          lobIndex
                                        ]?.subArea?.length ===
                                          lob?.subAreas?.length
                                      }
                                    >
                                      <span className="bold-label">
                                        {lob?.label}
                                      </span>
                                    </Checkbox>
                                    <Divider />
                                    <Checkbox.Group
                                      className="fill-width"
                                      disabled={
                                        industryCopy?.[0]?.id !== industry?.id
                                      }
                                      value={map(
                                        industryCopy?.[0]?.lineOfBusiness?.[
                                          lobIndex
                                        ]?.subArea,
                                        (item) => item?.id
                                      )}
                                    >
                                      {map(lob?.subAreas, (subArea) => (
                                        <div key={subArea?.id}>
                                          <Checkbox
                                            className="common-checkbox subarea-section"
                                            value={subArea?.id}
                                            onChange={(e) =>
                                              onChangeSubArea(
                                                e,
                                                subArea,
                                                lob,
                                                industry
                                              )
                                            }
                                          >
                                            <span className="line-clamp">
                                              {subArea?.label}
                                            </span>
                                          </Checkbox>
                                        </div>
                                      ))}
                                    </Checkbox.Group>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <Empty
                      className="no-data-image"
                      image={Empty?.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                </Radio.Group>
              </Row>
            </>
          )}
        </>
      )}
      {selectedAttribute === 'PMC_MANUFACTURER' && (
        <>
          {manufacturersLoading ? (
            <LoaderComponent setHeight={75} />
          ) : (
            <>
              {manufacturersData?.manufacturers?.data?.length > 0 ? (
                <div>
                  <Checkbox
                    className="common-checkbox check-all"
                    indeterminate={
                      !!(
                        conditionData?.manufacturers?.length > 0 &&
                        conditionData?.manufacturers?.length !==
                          manufacturersData?.manufacturers?.data?.length
                      )
                    }
                    checked={
                      conditionData?.manufacturers?.length ===
                      manufacturersData?.manufacturers?.data?.length
                    }
                    onChange={onCheckAllChangeManufacturers}
                  >
                    ALL MANUFACTURERS
                  </Checkbox>
                  <div className="center-content">
                    <Checkbox.Group
                      className="fill-width"
                      value={map(
                        conditionData?.manufacturers,
                        (item) => item?.id
                      )}
                    >
                      <Row gutter={[0, 16]}>
                        {map(manufacturersData?.manufacturers?.data, (item) => (
                          <Col span={8} key={item?.id}>
                            <Checkbox
                              className="common-checkbox"
                              value={item?.id}
                              onChange={(e) =>
                                handleChangeCheckboxManufacturers(e, item)
                              }
                            >
                              <div>
                                <img
                                  src={item?.logo?.url || placeholderImage}
                                  alt="manufacturer-img"
                                  onError={(e) => {
                                    e.target.src = placeholderImage;
                                  }}
                                  className="list-img"
                                  width={24}
                                  height={24}
                                />
                                <span>{item?.name}</span>
                              </div>
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </div>
                </div>
              ) : (
                <Empty
                  className="no-data-image"
                  image={Empty?.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </>
          )}
        </>
      )}
    </ModalComponent>
  );
};

export default PricingConditionModal;
