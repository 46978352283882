import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Row, message } from 'antd';
import { omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { ALLOW_IMAGE_TYPES, ROUTES } from '../../../common/constants';
import {
  checkPermissions,
  formValidatorRules,
  objectWithoutKey,
  uploadFile
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import EditorComponent from '../../../components/EditorComponent';
import GoBackButton from '../../../components/GoBackButton';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { UPDATE_INDUSTRY } from '../graphql/Mutation';
import { GET_INDUSTRY } from '../graphql/Queries';

const { requiredWhiteSpaceAllowed, required } = formValidatorRules;

const { Option } = SelectComponent;

const IndustryForm = ({ industryId, isEdit = false }) => {
  const {
    dispatch,
    state: { currentUser, permissions }
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [fileList, setFileList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const [getIndustry, { data }] = useLazyQuery(GET_INDUSTRY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.industry?.logo?.url) {
        setLogoUrl({
          ...res?.industry?.logo
        });
      }
      setLoading(false);
    },
    onError() {}
  });

  const [updateIndustry] = useMutation(UPDATE_INDUSTRY, {
    onError() {}
  });

  useEffect(() => {
    getIndustry({
      variables: {
        where: {
          id: industryId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url }
    } = info;
    if (url) {
      setFileList([]);
      form?.setFieldsValue({
        logo: null
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          logo: null
        });
      } else {
        form?.setFieldsValue({
          logo: info?.file
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      form?.setFieldsValue({
        logo: null
      });
      message?.destroy();
      message?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const onFinish = async (values) => {
    setSubmitLoading(true);
    try {
      const uuid = data?.industry?.uuid || uuidv4();

      const logo = await uploadFile({
        logoObject: values?.logo,
        currentUser,
        uuid,
        folder: 'industry'
      });

      const variables = {
        ...values,
        description: values?.description || '',
        logo: values?.logo?.url
          ? {
              contentType: data?.industry?.logo?.contentType,
              extension: data?.industry?.logo?.extension,
              key: data?.industry?.logo?.key,
              name: data?.industry?.logo?.name,
              url: data?.industry?.logo?.url
            }
          : logo
      };
      const dataToSend = omit(variables, ['referenceId']);
      const response = await updateIndustry({
        variables: {
          data: dataToSend,
          where: {
            id: data?.industry?.id
          }
        }
      });
      if (response?.data?.updateIndustry) {
        history?.push(ROUTES?.INDUSTRIES);
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  useEffect(() => {
    if (data?.industry) {
      if (data?.industry?.logo?.url && !fileList?.length) {
        setFileList([data?.industry?.logo]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="content-section">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.INDUSTRIES} />
          <span className="portal-header">Industries</span>
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_INDUSTRY_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="region-table-discard-btn"
            onClick={() => history?.push(ROUTES?.INDUSTRIES)}
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="region-table-save-btn"
            loading={submitLoading}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      {loading ? (
        <LoaderComponent />
      ) : (
        <Form
          layout="vertical"
          initialValues={{
            ...(data?.industry && {
              ...objectWithoutKey(data?.industry, 'logo')
            }),
            ...(data?.industry?.logo?.url && {
              logo: { ...data?.industry?.logo, ...logoUrl }
            })
          }}
          form={form}
          onFinish={(values) => {
            dispatch({ type: 'SET_SHOW_PROMPT', data: false });
            onFinish(values);
          }}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
          onFinishFailed={onFinishFailed}
          scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
          onValuesChange={() =>
            dispatch({ type: 'SET_SHOW_PROMPT', data: true })
          }
        >
          <fieldset
            disabled={
              !checkPermissions(
                permissions,
                isEdit ? ['FET_INDUSTRY_UPDATE'] : ['FET_INDUSTRY_CREATE']
              )
            }
          >
            <Row gutter={20}>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="label"
                  label="Industry"
                  rules={[{ ...required, message: 'Please Enter Industry' }]}
                >
                  <InputComponent name="label" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="alias"
                  label="Alias"
                  className="tooltip-label"
                  tooltip={{
                    title: (
                      <span className="fill-width">
                        This will be alias for label.
                      </span>
                    ),
                    overlayClassName: 'alias-popover',
                    icon: <QuestionCircleOutlined />
                  }}
                >
                  <InputComponent name="alias" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="isActive"
                  label="Status"
                  rules={[
                    {
                      ...requiredWhiteSpaceAllowed,
                      message: 'Please Select Status'
                    }
                  ]}
                >
                  <SelectComponent placeholder="Select Status" allowClear>
                    <Option key="active" value>
                      Active
                    </Option>
                    <Option key="inactive" value={false}>
                      InActive
                    </Option>
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="referenceId"
                  label="Reference ID"
                  className="text-form-item"
                >
                  <p>{data?.industry?.referenceId}</p>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="logo"
                  label="Icon"
                  rules={[
                    { required, message: 'Please Upload Icon', type: 'object' }
                  ]}
                >
                  <DraggerUploadComponent
                    showUploadList={{
                      showDownloadIcon: false,
                      showPreviewIcon: true
                    }}
                    fileList={fileList}
                    onChange={onChangeUpload}
                  >
                    <p className="icon-header">
                      <DownloadOutlined />
                    </p>
                    <span className="upload-title">
                      Drag image or browse your files
                    </span>{' '}
                    <br />
                    <span className="upload-description">
                      JPG, PNG, SVG, JPEG
                    </span>
                  </DraggerUploadComponent>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                <Form.Item name="description" label="Description">
                  <EditorComponent />
                </Form.Item>
              </Col>
            </Row>
          </fieldset>
        </Form>
      )}
    </div>
  );
};

export default IndustryForm;
