import Icon from '@ant-design/icons';
import React from 'react';

const documentIcon = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.91667 0.583008H7.99162L11.25 3.84138V12.2497C11.25 12.894 10.7277 13.4163 10.0833 13.4163H1.91667C1.27233 13.4163 0.75 12.894 0.75 12.2497V1.74967C0.75 1.10534 1.27233 0.583008 1.91667 0.583008ZM6.58333 1.74967H1.91667V12.2497H10.0833V5.24967H7.75C7.10567 5.24967 6.58333 4.72734 6.58333 4.08301V1.74967ZM7.75 1.9913V4.08301H9.84171L7.75 1.9913ZM3.66667 9.91634V8.74967H7.16667V9.91634H3.66667ZM3.66667 6.41634V7.58301H8.33333V6.41634H3.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};

const DocumentIconComponent = (props) => (
  <Icon component={documentIcon} {...props} />
);

export default DocumentIconComponent;
