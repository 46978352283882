import { gql } from '@apollo/client';

export const GET_QUESTIONNAIRES = gql`
  query questionnaires(
    $filter: QuestionnaireFilter!
    $where: QuestionnaireWhereFilter!
  ) {
    questionnaires(filter: $filter, where: $where) {
      count
      data {
        id
        title
        uuid
        link
        slug
        brandId
        default
        isScheduleEnabled
        tenantSubDomain
        brand {
          name
          logo {
            url
            key
            name
            extension
            contentType
          }
        }
        startDate
        endDate
        quoteLabels {
          lowCost
          recommended
          highEnd
        }
        closeRate
        isActive
        cutOffTime {
          monday {
            checked
            time
          }
          tuesday {
            checked
            time
          }
          wednesday {
            checked
            time
          }
          thursday {
            checked
            time
          }
          friday {
            checked
            time
          }
          saturday {
            checked
            time
          }
          sunday {
            checked
            time
          }
        }
        showPricingInEmailQuote
        useRetailerLogoAndColor
        pageKey
        industryId
      }
    }
  }
`;
export const GET_QUESTIONNAIRE = gql`
  query questionnaire($id: ID!) {
    questionnaire(where: { id: $id }) {
      title
      uuid
      link
      slug
      brandId
      isActive
      startDate
      endDate
      tenantSubDomain
      default
      isScheduleEnabled
      quoteLabels {
        lowCost
        recommended
        highEnd
      }
      cutOffTime {
        monday {
          checked
          time
        }
        tuesday {
          checked
          time
        }
        wednesday {
          checked
          time
        }
        thursday {
          checked
          time
        }
        friday {
          checked
          time
        }
        saturday {
          checked
          time
        }
        sunday {
          checked
          time
        }
      }
      showPricingInEmailQuote
      useRetailerLogoAndColor
      globalCondition
      description
      industryId
      lineOfBusinessId
      subAreaId
      brandConfig
      compareConfig {
        id
        enable
        label
        title
        staticKeys
        dynamicFields {
          id
          label
        }
      }
      appointmentWindow
      daysOff
      industry {
        id
        label
      }
      lineOfBusinesses {
        id
        label
      }
      emailNotification {
        email
        name
        default
      }
      quoteSortOn
      quoteSortBy
    }
  }
`;

export const QUESTIONNAIRE_FILTER = gql`
  query questionnaireFilters($filter: QuestionnaireFilter!) {
    questionnaireFilters(filter: $filter) {
      count
      data {
        title
        link
        brandId
        quoteLabels {
          lowCost
          recommended
          highEnd
        }
        closeRate
        startDate
        endDate
        cutOffTime {
          monday {
            checked
            time
          }
          tuesday {
            checked
            time
          }
          wednesday {
            checked
            time
          }
          thursday {
            checked
            time
          }
          friday {
            checked
            time
          }
          saturday {
            checked
            time
          }
          sunday {
            checked
            time
          }
        }
        isActive
        tenantId
      }
    }
  }
`;

export const BRAND_FILTER = gql`
  query brandFilters($filter: BrandFilter!) {
    brandFilters(filter: $filter) {
      count
      data {
        name
        refData
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
        secondaryColors
        tertiaryColors
        colorPallete
        description
        waiverText
        priceGuarantee
        offerText
        productDetails
        isActive
        tenantId
        createdAt
      }
    }
  }
`;

export const GET_TENANT = gql`
  query tenant($where: TenantWhereInput!) {
    tenant(where: $where) {
      subDomain
      email
      name
    }
  }
`;

export const GET_LOB_LIST = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lobCode
        description
        subAreas {
          id
          label
          key
          saCode
          description
          estimation
          isActive
          waiverText
        }
        createdAt
      }
    }
  }
`;

export const GET_WIDGETS = gql`
  query widgets($where: WidgetsWhereFilter!) {
    widgets(where: $where) {
      count
      data {
        questionnaireId
        config
        type
        useOnce
        referenceId
        tenantId
        id
      }
    }
  }
`;

export const GET_PAGE_CONFIG = gql`
  query pageConfig($where: PageConfigWhereInput!) {
    pageConfig(where: $where) {
      id
      questionnaireId
      title
      showTitle
      taglineAbove
      showTaglineAbove
      pageKey
      taglineBelow
      showTaglineBelow
      skipPageConditions
      pageConfiguration
      showHeaderImage
      headerImage {
        url
        key
        name
        extension
        contentType
      }
      widgetConfiguration
      widgetId
      widgetType
      showLogo
      showContactInfo
      sameDayInstallationTimer
      showTagLine
      showCtaButton
      ctaButtonLabel
      showLocation
      showBack
      pageTitleAsBackButton
      tenantId
      showMyCart
      showFootNote
      footNote
      showContactMe
      contactMe
      allowDrag
      pageKey
    }
  }
`;

export const GET_PAGE_SEQUENCES = gql`
  query pageSequences($where: PageConfigWhereInput!) {
    pageSequences(where: $where) {
      count
      data {
        id
        allowDrag
        questionnaireId
        order
        pageKey
        title
        order
        primaryColor
        tenantId
        phoneNo
        email
        liveChat
        helpPage
        brandConfig
        logo {
          url
          key
          name
          extension
          contentType
        }
        appointmentWindow
        daysOff
        tenantLogo {
          url
          key
          name
          extension
          contentType
        }
        tenantName
        holidayList {
          title
          date
          message
        }
        cutOffTime {
          monday {
            checked
            time
          }
          tuesday {
            checked
            time
          }
          wednesday {
            checked
            time
          }
          thursday {
            checked
            time
          }
          friday {
            checked
            time
          }
          saturday {
            checked
            time
          }
          sunday {
            checked
            time
          }
        }
        pageConfiguration {
          id
          questionnaireId
          title
          showTitle
          taglineAbove
          showTaglineAbove
          pageKey
          taglineBelow
          showTaglineBelow
          skipPageConditions
          showHeaderImage
          headerImage {
            url
            key
            name
            extension
            contentType
          }
          widgetConfiguration
          widgetId
          widgetType
          showLogo
          showContactInfo
          sameDayInstallationTimer
          showTagLine
          showCtaButton
          ctaButtonLabel
          showLocation
          showBack
          pageTitleAsBackButton
          tenantId
          showMyCart
          showFootNote
          footNote
          showContactMe
          contactMe
          allowDrag
          pageKey
        }
        pageAnalytics {
          id
          questionnaireId
          title
          key
          clickRate
          averageTimeSpent
          skip
        }
      }
    }
  }
`;

export const CPQ_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      data {
        label
        id
        lobSaData {
          id
          label
          subAreas {
            id
            label
            key
          }
        }
      }
    }
  }
`;

export const CPQ_MANUFACTURERS = gql`
  query manufacturers(
    $filter: ManufacturerFilter!
    $where: ManufacturersWhereFilter
  ) {
    manufacturers(filter: $filter, where: $where) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const GET_SYSTEM_FIELD_CONFIG = gql`
  query getSystemFieldConfiguration($where: SystemFieldWhereInput!) {
    getSystemFieldConfiguration(where: $where) {
      message
      data {
        id
        config
        label
        fieldConfig
      }
    }
  }
`;

export const FETCH_CPQ_CATEGORIES = gql`
  query productCategories(
    $filter: ProductCategoryFilter!
    $where: ProductCategoriesWhereFilter
  ) {
    productCategories(filter: $filter, where: $where) {
      count
      data {
        id
        title
      }
    }
  }
`;

export const MY_PROJECT_ROQ_PATH_LIST = gql`
  query myProjectROQPathList($where: MyProjectROQInput!) {
    myProjectROQPathList(where: $where) {
      count
      data {
        id
        title
      }
    }
  }
`;

export const CPQ_DYNAMIC_FIELDS = gql`
  query dynamicFieldsWithListItems($where: DynamicFieldWhereFilterData!) {
    dynamicFieldsWithListItems(where: $where) {
      id
      fieldType
      key
      label
      config
      dynamicFieldListItems {
        id
        fieldId
        label
        value
      }
    }
  }
`;

export const LINE_OF_BUSINESS = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const GET_DIMENSION_CONFIG = gql`
  query getDimensionConfig($where: DimensionConfigWhereInput!) {
    getDimensionConfig(where: $where)
  }
`;
export const GET_USED_SYSTEM_FIELDS = gql`
  query usedSystemField($where: useSystemFieldWhere) {
    usedSystemField(where: $where) {
      systemField
    }
  }
`;

export const GET_TENANT_DATA = gql`
  query getData {
    getProfile {
      phoneNo
      email
    }
    fetchTenantBrand {
      logo {
        url
        key
        name
        extension
        contentType
      }
      name
    }
  }
`;
