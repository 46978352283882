import { Checkbox, Form, Tabs } from 'antd';
import React, { useEffect } from 'react';
import defaultStairImage from '../../../../../../assets/images/stairs-default-image.png';
import NumberComponent from '../../../previewComponents/globalComponents/NumberComponent';

const Steps = ({ stepsConfig = null }) => {
  let imageUrl;
  if (stepsConfig?.picture?.length > 0) {
    if (stepsConfig?.picture?.[0]?.url) {
      imageUrl = stepsConfig?.picture?.[0]?.url;
    } else {
      imageUrl = URL?.createObjectURL(stepsConfig?.picture?.[0]?.originFileObj);
    }
  } else {
    imageUrl = stepsConfig?.picture?.url;
  }

  return (
    <>
      {stepsConfig?.pictureCheck && (
        <div className="stairs-image-section">
          <img src={imageUrl || defaultStairImage} alt="stairs-preview" />
        </div>
      )}
      <Form.Item name="width">
        <NumberComponent
          readOnly
          placeholder={stepsConfig?.width}
          suffix="ft"
        />
      </Form.Item>
      <Form.Item name="depth">
        <NumberComponent
          readOnly
          placeholder={stepsConfig?.depth}
          suffix="ft"
        />
      </Form.Item>
      <Form.Item name="height">
        <NumberComponent
          readOnly
          placeholder={stepsConfig?.height}
          suffix="ft"
        />
      </Form.Item>
    </>
  );
};

const TotalRise = ({ totalRiseConfig = null }) => {
  let imageUrl;
  if (totalRiseConfig?.image?.length > 0) {
    if (totalRiseConfig?.image?.[0]?.url) {
      imageUrl = totalRiseConfig?.image?.[0]?.url;
    } else {
      imageUrl = URL?.createObjectURL(
        totalRiseConfig?.image?.[0]?.originFileObj
      );
    }
  } else {
    imageUrl = totalRiseConfig?.image?.url;
  }
  return (
    <>
      {totalRiseConfig?.imageCheck && (
        <div className="stairs-image-section">
          <img src={imageUrl || defaultStairImage} alt="stairs-preview" />
        </div>
      )}
      {totalRiseConfig?.additionalOptionCheck && (
        <div className="fill-width d-flex justify-center">
          <Form.Item name="additional" valuePropName="checked">
            <Checkbox readOnly className="common-checkbox">
              {totalRiseConfig?.additionalOptionText}
            </Checkbox>
          </Form.Item>
        </div>
      )}
      <Form.Item name="totalHeight">
        <NumberComponent
          readOnly
          placeholder={totalRiseConfig?.totalHeight}
          suffix="ft"
        />
      </Form.Item>
      <Form.Item name="totalSteps">
        <NumberComponent readOnly placeholder={totalRiseConfig?.totalSteps} />
      </Form.Item>
      <Form.Item name="volume">
        <NumberComponent
          readOnly
          placeholder={totalRiseConfig?.volume}
          suffix="cu/ft"
        />
      </Form.Item>
    </>
  );
};

const StairsForm = ({ widgetConfig = null, selectedDimensionTab = null }) => {
  const [form] = Form?.useForm();

  useEffect(() => {
    form?.setFieldsValue({
      additional: widgetConfig?.totalRise?.selectedCheck
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig]);

  const items = [
    {
      key: 'STEPS',
      label: widgetConfig?.steps?.title,
      children: <Steps stepsConfig={widgetConfig?.steps} />
    },
    {
      key: 'TOTAL_RISE',
      label: widgetConfig?.totalRise?.title,
      children: <TotalRise totalRiseConfig={widgetConfig?.totalRise} />
    }
  ];

  return (
    <Form form={form}>
      <Tabs activeKey={selectedDimensionTab} items={items} centered />
    </Form>
  );
};

export default StairsForm;
