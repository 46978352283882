import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, Form, Switch } from 'antd';
import React from 'react';
import InputComponent from '../../../../../../components/InputComponent';

const { Panel } = Collapse;

const ContactWidgetForm = ({ form }) => {
  const urgencyCheckboxLabelCheck = Form?.useWatch(
    ['widgetConfiguration', 'contactFromSettings', 'urgencyCheckboxLabelCheck'],
    form
  );
  const sendConfirmationEmailCheckboxCheck = Form?.useWatch(
    [
      'widgetConfiguration',
      'contactFromSettings',
      'sendConfirmationEmailCheckboxCheck'
    ],
    form
  );

  return (
    <div>
      <div className="section-header mt-16">
        <span className="section-title">Order Settings</span>
      </div>

      {/* Card Summary */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Cart Summary</span>
        <Form.Item
          name={['widgetConfiguration', 'orderSettings', 'cartSummary']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      <div className="section-header mt-16">
        <span className="section-title">Contact form Settings</span>
      </div>
      {/* Urgency Checkbox Label */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Urgency Checkbox Label</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'contactFromSettings',
            'urgencyCheckboxLabelCheck'
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'urgencyCheckboxLabelText'
        ]}
        hidden={!urgencyCheckboxLabelCheck}
        rules={[
          {
            required: urgencyCheckboxLabelCheck,
            message: 'Please Enter Urgency Checkbox Label'
          }
        ]}
        className="mb-0"
      >
        <InputComponent placeholder="Enter Urgency Checkbox Label" />
      </Form.Item>
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'urgencyCheckboxLabelSelected'
        ]}
        hidden={!urgencyCheckboxLabelCheck}
        className="mb-0"
        valuePropName="checked"
      >
        <Checkbox className="common-checkbox">Selected</Checkbox>
      </Form.Item>

      {/* Send Confirmation Email Checkbox */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Send Confirmation Email Checkbox</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'contactFromSettings',
            'sendConfirmationEmailCheckboxCheck'
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'sendConfirmationEmailCheckboxText'
        ]}
        hidden={!sendConfirmationEmailCheckboxCheck}
        rules={[
          {
            required: sendConfirmationEmailCheckboxCheck,
            message: 'Please Enter Send Confirmation Email Checkbox'
          }
        ]}
        className="mb-0"
      >
        <InputComponent placeholder="Enter Send Confirmation Email Checkbox" />
      </Form.Item>
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'sendConfirmationEmailCheckboxSelected'
        ]}
        hidden={!sendConfirmationEmailCheckboxCheck}
        className="mb-0"
        valuePropName="checked"
      >
        <Checkbox className="common-checkbox">Selected</Checkbox>
      </Form.Item>

      <Collapse
        className="common-collapse questionnaire-collapse"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        defaultActiveKey={['1', '2', '3']}
        ghost
      >
        <Panel forceRender header="Name" key="1">
          {/* Name */}
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Label</span>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'contactFromSettings',
              'name',
              'label'
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter Label'
              }
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter Label" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'contactFromSettings',
              'name',
              'required'
            ]}
            className="mb-0"
            valuePropName="checked"
          >
            <Checkbox disabled className="common-checkbox">
              Required
            </Checkbox>
          </Form.Item>
        </Panel>
        <Panel forceRender header="Email" key="2">
          {/* Email */}
          <Form.Item
            name={[
              'widgetConfiguration',
              'contactFromSettings',
              'email',
              'labelText'
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter Label'
              }
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter Label" />
          </Form.Item>
        </Panel>
        <Panel forceRender header="Phone" key="3">
          {/* Phone */}

          <Form.Item
            name={[
              'widgetConfiguration',
              'contactFromSettings',
              'phone',
              'labelText'
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter Label'
              }
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter Label" />
          </Form.Item>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ContactWidgetForm;
