import { Button, Card, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import client from '../../../apollo';
import SettingsIcon from '../../../assets/settings.svg';
import TryBrowserIcon from '../../../assets/try-browser.svg';
import { QUESTIONNAIRE_TYPE, ROUTES } from '../../../common/constants';
import { cpqRetailUrl } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import { GET_QUESTIONNAIRE } from '../graphql/Queries';
import SecondarySettingModal from './SecondarySettingModal';
import SettingModal from './SettingModal';

const CpqTemplate = ({
  centerContent,
  rightSideContent,
  type,
  refetchPageSequence,
  setRefetchSystemFields
}) => {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [questionnaireData, setQuestionnaireData] = useState(null);
  const { dispatch } = useContext(AppContext);

  const getQuestionnaire = async () => {
    try {
      const res = await client?.query({
        query: GET_QUESTIONNAIRE,
        variables: {
          id: params?.id
        },
        fetchPolicy: 'network-only'
      });
      const questionObj = {
        title: res?.data?.questionnaire?.title,
        uuid: res?.data?.questionnaire?.uuid,
        industry: res?.data?.questionnaire?.industry?.label,
        industryId: res?.data?.questionnaire?.industryId,
        lineOfBusinesses: res?.data?.questionnaire?.lineOfBusinesses?.label
      };
      setQuestionnaireData(res?.data);
      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT', data: questionObj });
      return res;
    } catch (error) {
      dispatch({ type: 'SET_CPQ_QUESTION_OBJECT_LOADING', data: false });
      return error;
    }
  };

  useEffect(() => {
    dispatch({ type: 'SET_CPQ_QUESTION_OBJECT_LOADING', data: true });

    getQuestionnaire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTryBrowser = () => {
    // adding isSoftLaunch flag as it is very helpful at the time of production. we have to test our entire product on dummy url.remember to remove this flag after testing

    // eslint-disable-next-line no-undef
    window
      .open(
        `https://${cpqRetailUrl(
          questionnaireData?.questionnaire?.tenantSubDomain,
          true
        )}/${questionnaireData?.questionnaire?.slug}`,
        '_blank'
      )
      ?.focus();
  };

  return (
    <Card className="full-height-card card-body-padding cpq-padding">
      {showModal && (
        <SettingModal
          isEdit
          showModal={showModal}
          setShowModal={setShowModal}
          refetchData={refetchPageSequence}
          refetchQuestionnaire={getQuestionnaire}
        />
      )}
      {showSecondaryModal && (
        <SecondarySettingModal
          isEdit
          showModal={showSecondaryModal}
          setShowModal={setShowSecondaryModal}
          refetchQuestionnaire={getQuestionnaire}
          setRefetchSystemFields={setRefetchSystemFields}
        />
      )}
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={
              type === QUESTIONNAIRE_TYPE?.ROQ_PATH
                ? ROUTES?.QUESTIONNAIRES_SECONDARY
                : ROUTES?.QUESTIONNAIRES_PRIMARY
            }
          />
          <span className="portal-header">
            {questionnaireData?.questionnaire?.title}
          </span>
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        {type === QUESTIONNAIRE_TYPE?.ROQ_BUILDER && (
          <Button
            className="common-button try-button"
            icon={
              <img src={TryBrowserIcon} alt="try-browser-icon" width={12} />
            }
            size="small"
            type="primary"
            id="try-in-browser-btn"
            onClick={handleTryBrowser}
          >
            Try in Browser
          </Button>
        )}
        <AccessControl allowedPermissions={['FET_QUESTIONNAIRE_UPDATE']}>
          <Button
            className="common-button settings-button"
            icon={<img src={SettingsIcon} alt="settings-icon" width={12} />}
            size="small"
            id="settings-btn"
            type="primary"
            onClick={() => {
              if (type === QUESTIONNAIRE_TYPE?.ROQ_PATH) {
                setShowSecondaryModal(true);
              } else {
                setShowModal(true);
              }
            }}
          >
            Settings
          </Button>
        </AccessControl>
      </Portal>
      <Row>
        <Col md={14} lg={16} className="center-col">
          <div className="center-content" id="centerContent">
            {centerContent}
          </div>
        </Col>
        <Col md={10} lg={8}>
          <div className="right-content">{rightSideContent}</div>
        </Col>
      </Row>
    </Card>
  );
};

export default CpqTemplate;
