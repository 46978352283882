import {
  CheckOutlined,
  CloseOutlined,
  CreditCardOutlined,
  PhoneOutlined,
  UpOutlined
} from '@ant-design/icons';
import { Collapse, Form, Radio, Switch } from 'antd';
import React from 'react';
import CalendarDates from '../../../../../../assets/calendar-dates.svg';
import LocationSvg from '../../../../../../assets/location-secondary.svg';
import { DAYS_OF_WEEK } from '../../../../../../common/constants';
import {
  formValidatorRules,
  uploadImageNormalize
} from '../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../components/InputComponent';
import SelectComponent from '../../../../../../components/SelectComponent';
import PanelHeader from '../../../previewComponents/globalComponents/PanelHeader';

const { Panel } = Collapse;
const { required } = formValidatorRules;

const AppointmentWidgetForm = ({
  form,
  selectedAppointmentKey = '',
  setSelectedAppointmentKey
}) => {
  const showAddressDescription = Form?.useWatch(
    ['widgetConfiguration', 'address', 'descriptionCheck'],
    form
  );

  const showAvailabilityDescription = Form?.useWatch(
    ['widgetConfiguration', 'availability', 'descriptionCheck'],
    form
  );

  const showReserveAppointmentDescription = Form?.useWatch(
    ['widgetConfiguration', 'reserveAppointment', 'descriptionCheck'],
    form
  );

  const showCreditCardSettingEnable = Form?.useWatch(
    [
      'widgetConfiguration',
      'reserveAppointment',
      'creditCard',
      'creditCardSetting',
      'enable'
    ],
    form
  );

  const showContactSettingEnable = Form?.useWatch(
    [
      'widgetConfiguration',
      'reserveAppointment',
      'contactInfo',
      'contactSetting',
      'enable'
    ],
    form
  );

  const showCreditCardSettingDescription = Form?.useWatch(
    [
      'widgetConfiguration',
      'reserveAppointment',
      'creditCard',
      'creditCardSetting',
      'descriptionCheck'
    ],
    form
  );

  const showContactDescription = Form?.useWatch(
    [
      'widgetConfiguration',
      'reserveAppointment',
      'contactInfo',
      'contactSetting',
      'descriptionCheck'
    ],
    form
  );

  return (
    <>
      <Collapse
        className="common-collapse questionnaire-collapse"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        defaultActiveKey={['ADDRESS', 'AVAILABILITY', 'RESERVE']}
        ghost
      >
        <Panel
          key="ADDRESS"
          forceRender
          header={
            <PanelHeader
              title="Address"
              formSelectedKey="ADDRESS"
              previewSelectedKey={selectedAppointmentKey}
              setPreviewSelectedKey={setSelectedAppointmentKey}
            />
          }
        >
          <Form.Item
            name={['widgetConfiguration', 'address', 'title']}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Description</span>
            <Form.Item
              name={['widgetConfiguration', 'address', 'descriptionCheck']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={['widgetConfiguration', 'address', 'descriptionText']}
            hidden={!showAddressDescription}
            rules={[
              {
                required: showAddressDescription,
                message: 'Please Enter Description'
              }
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'address', 'icon']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <img
                  alt="location"
                  src={LocationSvg}
                  width={18}
                  className="mb-8"
                />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
        </Panel>
        <Panel
          key="AVAILABILITY"
          forceRender
          header={
            <PanelHeader
              title="Availability"
              formSelectedKey="AVAILABILITY"
              previewSelectedKey={selectedAppointmentKey}
              setPreviewSelectedKey={setSelectedAppointmentKey}
            />
          }
        >
          <Form.Item
            name={['widgetConfiguration', 'availability', 'title']}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Description</span>
            <Form.Item
              name={['widgetConfiguration', 'availability', 'descriptionCheck']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={['widgetConfiguration', 'availability', 'descriptionText']}
            hidden={!showAvailabilityDescription}
            rules={[
              {
                required: showAvailabilityDescription,
                message: 'Please Enter Description'
              }
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'availability', 'icon']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <img
                  alt="location"
                  src={CalendarDates}
                  width={20}
                  className="mb-8"
                />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <div className="section-header mt-16">
            <span className="section-title">Display Type</span>
          </div>
          <Form.Item
            className="mb-0"
            name={[
              'widgetConfiguration',
              'availability',
              'displayType',
              'displayStyle'
            ]}
          >
            <Radio.Group className="common-radio fill-width d-flex">
              <Radio value="WEEKDAYS">Weekdays</Radio>
              <Radio value="CALENDAR">Calendar</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'availability',
              'displayType',
              'startOfTheWeek'
            ]}
          >
            <SelectComponent
              options={DAYS_OF_WEEK}
              placeholder="Select Start of the Week"
              showArrow
              showSearch={false}
            />
          </Form.Item>
          <p className="ml-12">
            Edit your availability in Questionnaire Settings
          </p>
        </Panel>
        <Panel
          key="RESERVE"
          forceRender
          header={
            <PanelHeader
              title="Reserve Appointment"
              formSelectedKey="RESERVE"
              previewSelectedKey={selectedAppointmentKey}
              setPreviewSelectedKey={setSelectedAppointmentKey}
            />
          }
        >
          <Form.Item
            name={['widgetConfiguration', 'reserveAppointment', 'title']}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Description</span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'reserveAppointment',
                'descriptionCheck'
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'reserveAppointment',
              'descriptionText'
            ]}
            hidden={!showReserveAppointmentDescription}
            rules={[
              {
                required: showReserveAppointmentDescription,
                message: 'Please Enter Description'
              }
            ]}
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'reserveAppointment', 'icon']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <CheckOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <div className="section-header mt-16">
            <span className="section-title">Credit card</span>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'reserveAppointment',
              'creditCard',
              'icon'
            ]}
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <CreditCardOutlined className="mb-8" />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'reserveAppointment',
              'creditCard',
              'title'
            ]}
            rules={[{ ...required, message: 'Please Enter Title' }]}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'reserveAppointment',
              'creditCard',
              'actionLabel'
            ]}
            rules={[{ ...required, message: 'Please Enter Action Label' }]}
            label="Action Label"
          >
            <InputComponent placeholder="Enter Action Label" />
          </Form.Item>
          <Collapse
            className="common-collapse questionnaire-collapse sub-collapse"
            expandIconPosition="end"
            defaultActiveKey={['CREDIT']}
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            ghost
          >
            <Panel
              key="CREDIT"
              forceRender
              header={
                <PanelHeader
                  title="Credit Card Setting"
                  formSelectedKey="CREDIT"
                  previewSelectedKey={selectedAppointmentKey}
                  setPreviewSelectedKey={setSelectedAppointmentKey}
                />
              }
            >
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Enable</span>
                <Form.Item
                  name={[
                    'widgetConfiguration',
                    'reserveAppointment',
                    'creditCard',
                    'creditCardSetting',
                    'enable'
                  ]}
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    disabled={
                      showCreditCardSettingEnable && !showContactSettingEnable
                    }
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Description</span>
                <Form.Item
                  name={[
                    'widgetConfiguration',
                    'reserveAppointment',
                    'creditCard',
                    'creditCardSetting',
                    'descriptionCheck'
                  ]}
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={[
                  'widgetConfiguration',
                  'reserveAppointment',
                  'creditCard',
                  'creditCardSetting',
                  'descriptionText'
                ]}
                hidden={!showCreditCardSettingDescription}
                rules={[
                  {
                    required: showCreditCardSettingDescription,
                    message: 'Please Enter Description'
                  }
                ]}
              >
                <InputComponent.TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
          </Collapse>
          <div className="section-header mt-16">
            <span className="section-title">Contact Information</span>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'reserveAppointment',
              'contactInfo',
              'icon'
            ]}
            normalize={uploadImageNormalize}
            valuePropName="fileList"
            label="Icon"
          >
            <DraggerUploadComponent className="common-upload">
              {' '}
              <p className="icon-header">
                <PhoneOutlined rotate={90} />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>{' '}
              <br />
              <span className="upload-description">JPG, PNG, SVG, JPEG</span>
            </DraggerUploadComponent>
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'reserveAppointment',
              'contactInfo',
              'title'
            ]}
            rules={[{ ...required, message: 'Please Enter Title' }]}
            label="Title"
          >
            <InputComponent placeholder="Enter Title" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'reserveAppointment',
              'contactInfo',
              'actionLabel'
            ]}
            rules={[{ ...required, message: 'Please Enter Action Label' }]}
            label="Action Label"
          >
            <InputComponent placeholder="Enter Action Label" />
          </Form.Item>
          <Collapse
            className="common-collapse questionnaire-collapse sub-collapse"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            defaultActiveKey={['CONTACT_SETTING']}
            ghost
          >
            <Panel
              key="CONTACT_SETTING"
              forceRender
              header={
                <PanelHeader
                  title="Contact Setting"
                  formSelectedKey="CONTACT_SETTING"
                  previewSelectedKey={selectedAppointmentKey}
                  setPreviewSelectedKey={setSelectedAppointmentKey}
                />
              }
            >
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Enable</span>
                <Form.Item
                  name={[
                    'widgetConfiguration',
                    'reserveAppointment',
                    'contactInfo',
                    'contactSetting',
                    'enable'
                  ]}
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    disabled={
                      showContactSettingEnable && !showCreditCardSettingEnable
                    }
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Description</span>
                <Form.Item
                  name={[
                    'widgetConfiguration',
                    'reserveAppointment',
                    'contactInfo',
                    'contactSetting',
                    'descriptionCheck'
                  ]}
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={[
                  'widgetConfiguration',
                  'reserveAppointment',
                  'contactInfo',
                  'contactSetting',
                  'descriptionText'
                ]}
                hidden={!showContactDescription}
                rules={[
                  {
                    required: showContactDescription,
                    message: 'Please Enter Description'
                  }
                ]}
              >
                <InputComponent.TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 2, maxRows: 4 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    </>
  );
};

export default AppointmentWidgetForm;
