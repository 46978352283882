import { DeleteOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Empty, Modal, Popconfirm, message } from 'antd';
import { isEmpty, kebabCase, map, split } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import clickRateImg from '../../../assets/click-rate.svg';
import timeSpendImg from '../../../assets/time.svg';
import { QUESTIONNAIRE_TYPE, ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import history from '../../../historyData';
import { CHANGE_PAGE_ORDER, DELETE_PAGE_CONFIG } from '../graphql/Mutations';

const { confirm } = Modal;
const LeftSideContent = ({
  pageSequencesData = [],
  refetchPageSequence,
  match: { params: { id = null, pageKey: pageKeyParam } = {} },
  history: { location = {} },
  type = QUESTIONNAIRE_TYPE?.ROQ_BUILDER
}) => {
  const questionnaireRoute =
    type === QUESTIONNAIRE_TYPE?.ROQ_BUILDER
      ? ROUTES?.QUESTIONNAIRES_PRIMARY
      : ROUTES?.QUESTIONNAIRES_SECONDARY;

  const [currentTemplate, setCurrentTemplate] = useState('0');
  const [dragLoading, setDragLoading] = useState(false);

  const [changePageOrder] = useMutation(CHANGE_PAGE_ORDER, {
    onCompleted() {},
    onError() {}
  });

  const [deletePageConfig, { loading }] = useMutation(DELETE_PAGE_CONFIG, {
    onCompleted() {},
    onError() {}
  });

  useEffect(() => {
    let pageKeyValue = pageKeyParam;
    if (pageKeyParam?.includes('?sp')) {
      pageKeyValue = split(pageKeyParam, '?sp')?.[0];
    } else {
      pageKeyValue = pageKeyParam;
    }
    setCurrentTemplate(pageKeyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      if (type === QUESTIONNAIRE_TYPE?.ROQ_PATH) {
        confirm({
          centered: true,
          wrapClassName: 'confirm-modal',
          okButtonProps: { className: 'common-button', type: 'primary' },
          cancelButtonProps: { className: 'common-button discard-button' },
          content:
            'There are Skip Page Conditions added on some pages. Are you sure want to reorder this page, they will be deleted ?',
          onOk: async () => {
            setDragLoading(true);
            try {
              const response = await changePageOrder({
                variables: {
                  data: {
                    oldIndex: pageSequencesData?.[oldIndex - 1]?.order,
                    newIndex: pageSequencesData?.[newIndex - 1]?.order
                  },
                  where: {
                    questionnaireId: id,
                    type
                  }
                }
              });
              if (response) {
                await refetchPageSequence();
                setDragLoading(false);
              }
            } catch (error) {
              message?.error(error?.response || 'got some problem');
              setDragLoading(false);
            }
          },
          onCancel: () => {}
        });
      } else {
        setDragLoading(true);
        try {
          const response = await changePageOrder({
            variables: {
              data: {
                oldIndex: pageSequencesData?.[oldIndex - 1]?.order,
                newIndex: pageSequencesData?.[newIndex - 1]?.order
              },
              where: {
                questionnaireId: id,
                type
              }
            }
          });
          if (response) {
            await refetchPageSequence();
            setDragLoading(false);
          }
        } catch (error) {
          message?.error(error?.response || 'got some problem');
          setDragLoading(false);
        }
      }
    }
  };

  const handleDeletePage = async (e, page) => {
    setDragLoading(true);
    e?.stopPropagation();
    await deletePageConfig({
      variables: {
        where: {
          id: page?.pageConfiguration?.id
        }
      }
    });
    const res = await refetchPageSequence();
    if (res?.data) {
      const data = res?.data?.pageSequences?.data;
      if (kebabCase(page?.pageKey) === kebabCase(currentTemplate)) {
        setDragLoading(false);
        history?.push(
          `${questionnaireRoute}/${id}${ROUTES?.PAGE}/${
            isEmpty(data)
              ? 'create'
              : kebabCase(res?.data?.pageSequences?.data?.[0]?.pageKey)
          }?sp=false`,
          {
            ...location?.state
          }
        );
      }
    }
    setDragLoading(false);
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined className="drag-row" />
  ));

  const SortableContainer = sortableContainer(({ children }) => (
    <div className="body-scroll">{children}</div>
  ));

  const SortableItem = sortableElement(({ item = null, index = null }) => {
    return (
      <div
        id="questionnaire-sortable-item"
        className="d-flex align-center justify-center"
      >
        {item?.allowDrag && <DragHandle />}
        <Link
          to={{
            pathname: `${questionnaireRoute}/${id}${ROUTES?.PAGE}/${kebabCase(
              item?.pageKey
            )}`,
            search: '?sp=false'
          }}
        >
          <Card
            bordered={false}
            className={`template-card ${
              currentTemplate === kebabCase(item?.pageKey)
                ? 'selected-card'
                : ''
            } ${item?.allowDrag ? 'draggable-card' : ''}`}
            key={index}
          >
            <div className="card-title">{item?.title}</div>
            <div className="click-rate">
              <img
                className="template-img"
                alt="click-rate-img"
                src={clickRateImg}
              />
              {item?.pageAnalytics?.clickRate || 0}%
              <span className="click-rate-text">Click-Through Rate</span>
            </div>
            <div className="time-spend">
              <div className="time-row">
                <img
                  className="template-img"
                  alt="time-img"
                  src={timeSpendImg}
                />
                <span>{item?.pageAnalytics?.averageTimeSpent || 0}s</span>
                <div className="time-details">
                  <span>Average spent</span>
                </div>
              </div>
            </div>
            {item?.allowDrag && (
              <Popconfirm
                title={
                  <p className="m-0 p-0">
                    {type === QUESTIONNAIRE_TYPE?.ROQ_PATH ? (
                      <span>
                        All your Skip page conditions related to this page will
                        be removed.
                        <br />
                        Are you sure to delete?
                      </span>
                    ) : (
                      'Are you sure to delete?'
                    )}
                  </p>
                }
                // commented for now will use this in future
                // getPopupContainer={() =>
                //   // eslint-disable-next-line no-undef
                //   document?.querySelector('.left-content')
                // }
                onConfirm={(e) => handleDeletePage(e, item)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="small"
                  type="primary"
                  icon={<DeleteOutlined />}
                  loading={loading}
                  danger
                  ghost
                  className="delete-btn pointer"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Card>
        </Link>
      </div>
    );
  });

  return (
    <div className="left-content">
      <div className="header-text">Pages</div>
      <LoaderComponent spinning={dragLoading} setHeight={84}>
        <SortableContainer
          onSortEnd={onSortEnd}
          helperClass="row-dragging"
          axis="y"
          lockAxis="y"
          lockOffset={['0%', '100%']}
          useDragHandle
        >
          {pageSequencesData?.length > 0 ? (
            map(pageSequencesData, (item, index) => (
              <SortableItem
                key={`${item}-${index}`}
                index={index + 1}
                item={item}
              />
            ))
          ) : (
            <div className="template-no-data">
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </SortableContainer>
      </LoaderComponent>
      <Link
        to={{
          pathname: `${questionnaireRoute}/${id}${ROUTES?.PAGE}/create`,
          search: '?sp=false'
        }}
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="common-button add-page-btn"
          size="small"
        >
          Add Page
        </Button>
      </Link>
    </div>
  );
};

export default withRouter(LeftSideContent);
