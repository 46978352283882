import { gql } from '@apollo/client';

export const GET_MANUFACTURERS = gql`
  query manufacturers(
    $filter: ManufacturerFilter!
    $where: ManufacturersWhereFilter
  ) {
    manufacturers(filter: $filter, where: $where) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_LINE_OF_BUSINESSES = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
      }
    }
  }
`;

export const GET_SUB_AREAS = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lineOfBusinessId
      }
    }
  }
`;

export const GET_PRICING_MODIFIER_REGIONS = gql`
  query regionPricingModifier($filter: RegionFilter!) {
    regionPricingModifier(filter: $filter) {
      count
      isValidZipCode
      data {
        id
        name
        isActive
        subRegions {
          id
          name
          isActive
        }
      }
    }
  }
`;

export const GET_PRODUCT_PRICING_LIST = gql`
  query productPricingList(
    $filter: ProductItemFilter!
    $where: ProductPricingWhereInput
  ) {
    productPricingList(filter: $filter, where: $where) {
      count
      data {
        id
        name
        sku
        manufacturer
        baseCost
        defaultCost
        profit
        profitType
        regions {
          regionId
          name
          available
          baseCost
          defaultCost
          profit
          subRegions {
            regionId
            name
            available
            baseCost
            defaultCost
            profit
          }
        }
      }
    }
  }
`;
